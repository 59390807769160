// Router.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './Components/App';
import Home from './Components/Home';
import Contact from './Components/Contact';
import RecuperarContraseña from './Components/RecuperarContraseña';
import Registro from './Components/Registro';
import Contraseña from './Components/Contraseña'
import Information from './Components/UserInformation/information';
import Notificacion from './Components/Notificacion';
import Alarmas from './Components/Alarmas';
import Datos from './Components/Datos';
import Help from './Components/Help';
import AboutUs from './Components/AboutUs';
import Reloj from './Components/Reloj.js';
//import Pagina404 from './Pagina404/Pagina404';
import { LanguageProvider } from './Components/LanguajeContext.js';

const AppRoutes = () => (
  <LanguageProvider>
  <Router>
    <Routes>
      <Route exact path="/" element={<App/>}>
      </Route>
      <Route path="/Home" element={<Home/>}>
      </Route>
      <Route path="/Contact" element={<Contact/>}>
      </Route>
      <Route path="/Registro" element={<Registro/>}>
      </Route>
      <Route path="/RecuperarContraseña" element={<RecuperarContraseña/>}>
      </Route>
      <Route path="/Contraseña/:token" element={<Contraseña/>}>
      </Route>
      <Route path="/Information" element={<Information/>}>
      </Route>
      <Route path="/Notificacion" element={<Notificacion/>}>
      </Route>
      <Route path="/Alarmas" element={<Alarmas/>}>
      </Route>
      <Route path="/Datos" element={<Datos/>}>
      </Route>
      <Route path="/Help" element={<Help/>}>
      </Route>
      <Route path="/AboutUs" element={<AboutUs/>}>
      </Route>
    </Routes>
  </Router>
  </LanguageProvider>
);

export default AppRoutes;
