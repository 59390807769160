import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Importa BrowserRouter
import logo from '../assets/LOGODOSALLARG_NOU.png';
import '../styles/App.css';
import Login from './Login';
import Home from './Home';
import Registro from './Registro';
import Recuperar from './RecuperarContraseña';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="login">
          <Login/>
        </div>
      </div>
  );
}

export default App;
