import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom'; 
import NavbarExample from '../Navbar.js';
import '../../styles/UserInformation/user.css';
import { infoUser } from '../../api/loginApi.js';
import { AiFillCaretDown } from 'react-icons/ai';

function Information() {
    const [isOpen, setIsOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [passwordContainerHeight, setPasswordContainerHeight] = useState(0);
    const [caretDirection, setCaretDirection] = useState('down');
    
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
        setCaretDirection(isPasswordVisible ? 'down' : 'up');
        measurePasswordContainer();
    };

    const measurePasswordContainer = () => {
        const contentElement = document.getElementById('password-content');
        if (contentElement) {
            const height = isPasswordVisible ? contentElement.scrollHeight : 0;
            setPasswordContainerHeight(Math.max(height, 0));
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        navigate('/');
    };
    
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);

            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token no encontrado en el localStorage');
                setLoading(false);
                return;
            }

            infoUser(storedUsername, token)
                .then((response) => {
                    if (response) {
                        setInfo(response);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error al obtener información del usuario:', error);
                    setLoading(false);
                });
        }
    }, []);

    const userInitial = username ? username.charAt(0).toUpperCase() : '';

    return (
        <div>
            <NavbarExample toggleMenu={toggleMenu} />
            <div className='informacionUser'>
                <div className='cajon-info'>
                    <div className='userInfo'>
                        <button className='alias'>{userInitial}</button>
                    </div>
                    <div className='userInfouser'>
                        <p className='letras'>{username}</p>
                        {info && (
                            <p className='letras'>{info.ROL}</p>
                        )}
                    </div>
                </div>
                <div className='cajon-mas-info'>
                    <div className='userInfouserInfo'>
                        <input className='inputsInfo' value={info ? info.NOMBRE : ''} placeholder='Name' /> 
                        <input className='inputsInfo' value={info ? info.APELLIDOS : ''} placeholder='Surname' />
                        <input className='inputsInfo' value={info ? info.EMAIL : ''} placeholder='Email' />
                    </div>
                </div>
            </div>
            <div className='pass'>
                <div className='cajon-password'>
                    <div className='password-header' onClick={togglePasswordVisibility}>
                        <h3>Modified Password</h3>
                        <AiFillCaretDown style={{ transform: `rotate(${caretDirection === 'down' ? '0deg' : '180deg'})` }} />
                    </div>
                    <div
                        className='password-content'
                        id='password-content'
                        style={{
                            maxHeight: `${passwordContainerHeight}px`,
                            minHeight: `${isPasswordVisible ? 0 : 0}px`, // Establece una altura mínima cuando visible
                            transition: 'max-height 0.3s ease-in-out, min-height 0.3s ease-in-out', // Agrega transición para suavizar el cambio de altura
                            overflow: 'hidden',
                        }}
                    >
                        <div className='cajon-pass '>
                            <input type='password' placeholder='Password' className='inputsInfo'/>
                            <input type='password' placeholder='Repeat Password' className='inputsInfo'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bot'>
                <div className='botones_info'>
                    <button className='botonpassword'>Save</button>
                    <button className='botonpassword' onClick={handleLogout}>Log out</button>
                </div>
            </div>
        </div>
    );
}

export default Information;
