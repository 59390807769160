import React, { useState, useEffect, useContext } from 'react';
import NavbarExample from './Navbar';
import '../styles/Help.css';
import { BiArrowBack } from "react-icons/bi";
import controladores from '../assets/imagenesmanual/controladores.png';
import sondas from '../assets/imagenesmanual/sondas.png';
import amperimetrias from '../assets/imagenesmanual/sondasAmperometricas.png';
import turbidez from '../assets/imagenesmanual/sondasturbidez.png';
import dosapp from '../assets/imagenesmanual/Dosaapp.png';
import DCW120 from '../assets/imagenesmanual/DCW120.png';
import DCW130 from '../assets/imagenesmanual/DCW130.png';
import DCW400 from '../assets/imagenesmanual/DCW400.png';
import DCW220 from '../assets/imagenesmanual/DCW220.png';
import tu910 from '../assets/imagenesmanual/910-920.png';
import tu8325 from '../assets/imagenesmanual/8325-8525.png';
import tu8355 from '../assets/imagenesmanual/8355-8555.png';
import AS2AS3 from '../assets/imagenesmanual/AS2AS3.png';
import CS4 from '../assets/imagenesmanual/CS4.png';
import WP7 from '../assets/imagenesmanual/WP7.png';
import WP10 from '../assets/imagenesmanual/WP10.png';
import CL4 from '../assets/imagenesmanual/CL4.png';
import ILS2 from '../assets/imagenesmanual/ILS2.png';
import P9 from '../assets/imagenesmanual/P9.2.png';
import DCW120manual from '../assets/manuales/controladores/DCW120EN.pdf';
import DCW130manual from '../assets/manuales/controladores/DCW130_mA_ES.pdf';
import DCW220manual from '../assets/manuales/controladores/DOSAControl_DCW_220_Part_1_2_3_Installation_and_starting_instructions_EN.pdf';
import DCW400manual from '../assets/manuales/controladores/DCW400ipES.pdf';
import TUmanual from '../assets/manuales/sondasTurbidez/TU910-920 - Rev A_171220_ES.pdf';
import tu8325manual from '../assets/manuales/sondasTurbidez/TU8325 - TU8525 - RevB_171220_ES.pdf';
import tu8355manual from '../assets/manuales/sondasTurbidez/TU8355 - TU8555 - Rev.3_171220_EN.pdf';
import sondaoxigeno from '../assets/manuales/sondasOxigeno/OD8325_OD8525_Rev B_180404_EN.pdf';
import AS from '../assets/manuales/sondasAmperometricas/DOSASens_AS2_AS3_1702_V10_ES.pdf';
import cs4es from '../assets/manuales/sondasAmperometricas/DOSASens_BR1_CS4_CC1_CP2_CP3_CP4_CN1_MST1_1703_V1_ES.pdf';
import cs4pt from '../assets/manuales/sondasAmperometricas/DOSASens_BR1_CS4_CC1_CP2_CP3_CP4_CN1_MST1_1703_V1_PT.pdf';
import cs4in from '../assets/manuales/sondasAmperometricas/DOSASens_BR1_CS4_CC1_CP2_CP3_CP4_CN1_MST1_V2_EN.pdf';
import cd7 from '../assets/manuales/sondasAmperometricas/DOSASens_CD7_PES7_OZ7_WP7_1705_V10_ES.pdf';
import cd10 from '../assets/manuales/sondasAmperometricas/DOSASens_CD10_PES10_OZ10_WP10_EN.pdf';
import cl4 from '../assets/manuales/sondasAmperometricas/DOSASens_CL4_CD4_OZ1_EN.pdf';
import ils2in from '../assets/manuales/sondasAmperometricas/DOSASens_ILS2_ILS3_1702_V10_EN.pdf';
import ils2es from '../assets/manuales/sondasAmperometricas/DOSASens_ILS2_ILS3_1702_V10_ES.pdf';
import p92 from '../assets/manuales/sondasAmperometricas/DOSASens_P9.2_EN.pdf';
import { saveAs } from 'file-saver';
import {getTiposProd} from '../api/loginApi.js';
import { LanguageContext } from '../Components/LanguajeContext.js';
import Footer from './Footer';

const translations = {
  es: {
    manualesDispositivos: "Manuales dispositivos",
    manualAplicacion: "Manual Aplicación",
    Controladores: "Controladores",
    CondaOxigeno: "Sonda Oxígeno",
    Amperometricas: "Sondas Amperométricas",
    Turbidez: "Sondas Turbidez",
    DosaCloud: "DosaCloud",
    Back: "Atrás"
  },
  de: {
    manualesDispositivos: "Gerätehandbücher",
    manualAplicacion: "Anwendungsanleitung",
    Controladores: "Regler",
    SondaOxigeno: "Sauerstoffsonde",
    Amperometricas: "Amperometrische Sonden",
    Turbidez: "Trübungssonden",
    DosaCloud: "DosaCloud",
    Back: "Zurück"
  },
  pt: {
    manualesDispositivos: "Manuais de dispositivos",
    manualAplicacion: "Manual de Aplicação",
    Controladores: "Controladores",
    SondaOxigeno: "Sonda de Oxigênio",
    Amperometricas: "Sondas Amperométricas",
    Turbidez: "Sondas de Turbidez",
    DosaCloud: "DosaCloud",
    Back: "Voltar"
  },
  en: {
    manualesDispositivos: "Device Manuals",
    manualAplicacion: "Application Manual",
    Controladores: "Controllers",
    SondaOxigeno: "Oxygen Probe",
    Amperometricas: "Amperometric Probes",
    Turbidez: "Turbidity Probes",
    DosaCloud: "DosaCloud",
    Back: "Back"
  }
};

function Help() {
  const [selectedManual, setSelectedManual] = useState('');
  const [tipos, setTipos] = useState('');
  const { language } = useContext(LanguageContext);
  const { manualesDispositivos, manualAplicacion, Controladores, SondaOxigeno, Amperometricas, Turbidez, DosaCloud, Back} = translations[language]; // Usar el hook useTranslation para obtener la función de traducción


  const handleManualClick = (manualType) => {
    setSelectedManual(manualType);
  };

  const handleClick = (pdfurl, manualName) => {
    const fileName = `${manualName}.pdf`;
    saveAs(pdfurl, fileName);
  };

  useEffect(() => {
    const fetchTipos = async () => {
      try {
        const username = localStorage.getItem('username');
        const tiposProd = await getTiposProd(username); // Llama a la función para obtener tipos
        setTipos(tiposProd); // Actualiza el estado 'tipos' con los tipos obtenidos
      } catch (error) {
        console.error('Error al obtener tipos de productos:', error);
      }
    };

    fetchTipos();
  }, []);

  const handleRemove = () => {
    setSelectedManual('');
  };

  useEffect(() => {
    // Obtener la selección previamente almacenada del almacenamiento local
    const storedManual = localStorage.getItem('selectedManual');
    if (storedManual) {
      setSelectedManual(storedManual);
    }
  }, []);
  
  useEffect(() => {
    return () => {
      // Limpiar la selección al salir del componente
      localStorage.removeItem('selectedManual');
    };
  }, []);
  


  return (
    <div>
      <NavbarExample />
      {selectedManual === '' && (
        <div className='div-h2-help'>
          <div className='div-manuales'>
            <h2 className='h2-help'>{manualesDispositivos}</h2>
            <div className='manuales-dispositivos'>
              <div className='img-name' onClick={() => handleManualClick('controladores')}>
                <img src={controladores} className='img-manuales' alt='Controladores' />
                <p className='p-help'>{Controladores}</p>
              </div>
              <div className='img-name' onClick={() => handleClick(sondaoxigeno,'Sonda oxigeno')}>
                <img src={sondas} className='img-manuales' alt='Sondas Oxígeno' />
                <p className='p-help'>{SondaOxigeno}</p>
              </div>
              <div className='img-name' onClick={() => handleManualClick('amperometricas')}>
                <img src={CS4} className='img-manuales' alt='Sondas Amperométricas' />
                <p className='p-help'>{Amperometricas}</p>
              </div>
              <div className='img-name' onClick={() => handleManualClick('turbidez')}>
                <img src={turbidez} className='img-manuales' alt='Sondas Turbidez' />
                <p className='p-help'>{Turbidez}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedManual === '' && (
        <div className='div-h2-help'>
          <div className='div-manuales'>
            <h2 className='h2-help'>{manualAplicacion}</h2>
            <div className='manuales-dispositivos'>
              <div className='img-name' onClick={() => handleManualClick('controladores')}>
                <img src={dosapp} className='img-manuales' alt='Controladores' />
                <p className='p-help'>{DosaCloud}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedManual === 'controladores' && (
        <div className='div-h2-help'>
          <div>
            <button className='botonBack' onClick={() => handleRemove()}><BiArrowBack/></button>
          </div>
          <div className='div-manuales'>
            <h2 className='h2-help'>{Controladores}</h2>
            <div className='manuales-dispositivos'>
              <div className='img-name' onClick={() => handleClick(DCW120manual, 'DCW120')}>
                <img src={DCW120} className='img-manuales' alt='DCW120' />
                <p className='p-help'>DCW120</p>
              </div>
              <div className='img-name' onClick={() => handleClick(DCW130manual, 'DCW130')}>
                <img src={DCW130} className='img-manuales' alt='DCW130' />
                <p className='p-help'>DCW130</p>
              </div>
              <div className='img-name' onClick={() => handleClick(DCW220manual, 'DCW220')}>
                <img src={DCW220} className='img-manuales' alt='DCW220' />
                <p className='p-help'>DCW220</p>
              </div>
              <div className='img-name' onClick={() => handleClick(DCW400manual, 'DCW400')}>
                <img src={DCW400} className='img-manuales' alt='DCW400' />
                <p className='p-help'>DCW400</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedManual === 'turbidez' && (
        <div className='div-h2-help'>
          <div>
            <button onClick={() => handleRemove()}><BiArrowBack/></button>
          </div>
          <div className='div-manuales'>
            <h2 className='h2-help'>{Turbidez}</h2>
            <div className='manuales-dispositivos'>
              <div className='img-name' onClick={() => handleClick(TUmanual, 'tu910')}>
                <img src={tu910} className='img-manuales' alt='TU 910 - TU 920' />
                <p className='p-help'>TU910920</p>
              </div>
              <div className='img-name' onClick={() => handleClick(tu8325manual, 'tu8325')}>
                <img src={tu8325} className='img-manuales' alt='TU 8325 - TU 8525' />
                <p className='p-help'>TU83258525</p>
              </div>
              <div className='img-name' onClick={() => handleClick(tu8355manual, 'tu8355')}>
                <img src={tu8355} className='img-manuales' alt='TU 8355 - TU 8555' />
                <p className='p-help'>TU83558555</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedManual === 'amperometricas' && (
        <div className='div-h2-help'>
          <div>
            <button onClick={() => handleRemove()}><BiArrowBack/></button>
          </div>
          <div className='div-manuales'>
            <h2 className='h2-help'>{Amperometricas}</h2>
            <div className='manuales-amperometricas'>
              <div className='img-name' onClick={() =>handleClick(AS,'AS2, AS3')}>
                <img src={AS2AS3} className='img-manuales' alt='AS2AS3' />
                <p className='p-help-amp'>AS2AS3</p>
              </div>
              <div className='img-name' onClick={() => handleClick(cs4in,'BR1, CS4, CC1, CP2/CP3/CP4, CN1, MST1')}>
                <img src={CS4} className='img-manuales' alt='CS4' />
                <p className='p-help-amp'>CS4</p>
              </div>
              <div className='img-name' onClick={() => handleClick(cd7, 'CD7, PES7, 0Z7, WP7')}>
                <img src={WP7} className='img-manuales' alt='WP7' />
                <p className='p-help-amp'>CD7</p>
              </div>
              <div className='img-name' onClick={() => handleClick(cd10, 'CD10, PES10, OZ10, WP10')}>
                <img src={WP10} className='img-manuales' alt='WP7' />
                <p className='p-help-amp'>CD10</p>
              </div>
              <div className='img-name' onClick={() => handleClick(cl4, 'CL4, CD4, OZ1')}>
                <img src={CL4} className='img-manuales' alt='CL4' />
                <p className='p-help-amp'>CL4</p>
              </div>
              <div className='img-name' onClick={() => handleClick(ils2in, 'ILS2, ILS3')}>
                <img src={ILS2} className='img-manuales' alt='ILS2' />
                <p className='p-help-amp'>ILS2</p>
              </div>
              <div className='img-name' onClick={() => handleClick(p92, 'P9.2')}>
                <img src={P9} className='img-manuales' alt='P9.2' />
                <p className='p-help-amp'>P9.2</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer/>
    </div>
  );
}

export default Help;
