import React, { useContext } from 'react';
import { FaHome, FaUser, FaWhatsapp } from 'react-icons/fa';
import { BiHelpCircle, BiSolidNotification, BiSolidBell, BiSolidFile } from 'react-icons/bi';
import { FaChartLine } from "react-icons/fa6";
import '../styles/Hamburguer.css'; // Importa tu archivo CSS para estilos
import { LanguageContext } from '../Components/LanguajeContext';
import { Link } from 'react-router-dom'; // Importa el componente Link


const translations = {
  es: {
    home: "Inicio",
    notifications: "Notificaciones",
    alarms: "Alarmas",
    data: "Datos",
    aboutUs: "Sobre Nosotros",
    manuals: "Manuales",
    contactUs: "Contáctenos",
  },
  en: {
    home: "Home",
    notifications: "Notifications",
    alarms: "Alarms",
    data: "Data",
    aboutUs: "About Us",
    manuals: "Manuals",
    contactUs: "Contact Us",
  },
  de: {
    home: "Startseite",
    notifications: "Benachrichtigungen",
    alarms: "Alarme",
    data: "Daten",
    aboutUs: "Über uns",
    manuals: "Handbücher",
    contactUs: "Kontaktieren Sie uns",
  },
  pt: {
    home: "Início",
    notifications: "Notificações",
    alarms: "Alarmes",
    data: "Dados",
    aboutUs: "Sobre nós",
    manuals: "Manuais",
    contactUs: "Contate-Nos",
  },
};

function Hamburguer({ isOpen }) {
  const { language } = useContext(LanguageContext);
  const { home, notifications, alarms, data, aboutUs, manuals, contactUs } = translations[language];

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      {!isOpen && (
        <div className="sidebar-icons">
          <FaHome />
          <BiHelpCircle />
          <FaWhatsapp />
        </div>
      )}
      {isOpen && (
    <div className='abierto'>
      <Link to="/Home" className="menu-item">
        <FaHome className='icon' />
        <span>{home}</span>
      </Link>
      <Link to="/Notificacion" className="menu-item">
        <BiSolidNotification className='icon' />
        <span>{notifications}</span>
      </Link>
      <Link to="/Alarmas" className="menu-item">
        <BiSolidBell className='icon' />
        <span>{alarms}</span>
      </Link>
      <Link to="/Datos" className="menu-item">
        <FaChartLine className='icon' />
        <span>{data}</span>
      </Link>
      <Link to="/AboutUs" className="menu-item">
        <FaUser className='icon' />
        <span>{aboutUs}</span>
      </Link>
      <Link to="/Help" className="menu-item">
        <BiHelpCircle className='icon' />
        <span>{manuals}</span>
      </Link>
      <Link to="/Contact" className="menu-item">
        <FaWhatsapp className='icon' />
        <span>{contactUs}</span>
      </Link>
    </div>
      )}
    </div>
  );
}

export default Hamburguer;
