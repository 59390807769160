import React, { useEffect, useState } from 'react';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { updateContraseña } from '../api/loginApi.js';

function Contraseña() {
  const { token } = useParams(); // Extraer los parámetros 'token' y 'email' de la URL
  const [tokenValid, setTokenValid] = useState(false);
  const [expired, setExpired] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (token) {
      // Llamar a la función para verificar el token aquí
      verifyToken(token);
    }
  }, [token]);

  const verifyToken = async (token) => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/verify_token', {
        token
      });

      if (response.data.tokenValid) {
        // El token es válido
        setTokenValid(true);
        setEmail(response.data.email);
        console.log('Token válido');
      } else {
        // El token no es válido
        setTokenValid(false);
        console.log('Token inválido');
      }
    } catch (error) {
      console.error('Error al verificar el token:', error);
      setTokenValid(false);
    }
  };

  const handleSubmit = () => {
  // Verificar si las contraseñas coinciden
    if (password !== repeatPassword) {
      alert('Las contraseñas no coinciden.');
      return;
    }
    console.log(email);
    updateContraseña(email, password)
      .then((response) => {
        console.log('Contraseña actualizada con éxito:', response);
      })
      .catch((error) => {
        console.error('Error al actualizar la contraseña:', error);
      });
  };

  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='div-formulario'>
        <div className='div-tittleContact'>
          <div className='box-recuperar'>
            <div className='tittle_register'>
              <h2>Restablecimiento de contraseña:</h2>
            </div>
            {tokenValid ? (
              expired ? (
                <p>Token caducado.</p>
              ) : (
                <div>
                  {/* Aquí renderizas el formulario para restablecer la contraseña */}
                  <div className='password'>
                    <input
                      className='input-registro'
                      type='password'
                      placeholder='Nueva contraseña *'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className='password'>
                    <input
                    className='input-registro'
                      type='password'
                      placeholder='Repetir nueva contraseña *'
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                  </div>
                  <div className='div-button-recuperar'>
                    <button className='botonRecuperar' onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              )
            ) : (
              <p>Token inválido o expirado.</p>
            )}
          </div>
        </div>
    </div>
    </div>
  );
}

export default Contraseña;