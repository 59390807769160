// loginApi.js
import axios from 'axios';

const API_BASE_URL = 'http://185.254.206.49:8080'; // URL base de tu servidor Flask

// Función para obtener datos de un endpoint protegido (requiere token)
export const fetchDataFromProtectedEndpoint = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/protected`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener datos del endpoint protegido:', error);
    throw error;
  }
};

// Función para iniciar sesión y obtener un token
export const loginAndGetToken = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, {
      username,
      password,
    });

    const { token } = response.data;

    // Almacena el token en el localStorage
    localStorage.setItem('token', token);

    return token;
  } catch (error) {
    console.error('Error al iniciar sesión:', error);
    throw error;
  }
};

// Función para iniciar sesión y obtener un token
export const usernameLogin = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/username`, {
      username,
    });
    return response.data.token;
  } catch (error) {
    console.error('Error al iniciar sesión:', error);
    throw error;
  }  
  };

  export const usernameType = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/type`);
      console.log(response.data.tipos);
      return response.data.tipos;
    } catch (error) {
      console.error('Error al obtener el tipo de usuario:', error);
      throw error;
    }
  };

  // Función para iniciar sesión y obtener un token
export const RegistroUser = async (username, password, email, rol, nombre, apellidos) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, {
      username,
      password,
      email, 
      rol, 
      nombre, 
      apellidos
    });
    return response.data.registro;
  } catch (error) {
    console.error('Error al iniciar sesión:', error);
    throw error;
  }
};

export const comprobarEmail = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Comparar`, {
      email
    });

    return response.data.comprobar;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const updateContraseña = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/cambiar_contraseña`, {
      email,
      password
    });

    return response.data.update;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const existeUsername = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/existe_username`, {
      username
    });
    console.log(response.data)
    return response.data.existe;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const existeEmail = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/existe_email`, {
      email
    });
    console.log(response.data)
    return response.data.existeEmail;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const infoUser = async (username, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/info`, {
      username,
      token
    },
    {
      headers: {
        Authorization: token, // Incluir el token en los encabezados de autorización
      }
    });
    console.log(response.data)
    return response.data.informacion;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const guardarNombre = async (id, name, surname, password, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/modificar_datos`, {
      id,
      name,
      surname,
      password,
      token
    },
    {
      headers: {
        Authorization: token, // Incluir el token en los encabezados de autorización
      }
    });
    console.log(response.data)
    return response.data.informacion;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getAlarmas = async (username, token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/alarmas`, {
      username,
      token
    },
    {
      headers: {
        Authorization: token, // Incluir el token en los encabezados de autorización
      }
    });
    console.log(response.data)
    return response.data.alarmas;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getAlias = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/alias`, {
      headers: {
        Authorization:  token // Incluir el token en los encabezados de la solicitud
      }
    });

    console.log(response.data.lista_alias);
    return response.data.lista_alias;
  } catch (error) {
    console.error('Error al obtener la lista de alias:', error);
    throw error;
  }
};

export const getDatos = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/datos`, {
      headers: {
        Authorization:  token // Incluir el token en los encabezados de la solicitud
      }
    });

    console.log(response.data.lista_datos);
    return response.data.lista_datos;
  } catch (error) {
    console.error('Error al obtener la lista de alias:', error);
    throw error;
  }
};

export const getGraficos = async (username, estaciones, filtro) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/graficos`, {
      username,
      estaciones,
      filtro
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.grafos;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getEstaciones = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/estaciones`, {
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.estacion;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getAlarmasDashboard = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/alarmas_dash`, {
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.alarmas_dashboard;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getGraphsDashboard = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/graph_dash`, {
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.graphs_dashboard;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getTiposProd = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/tipos_prod`, {
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.tipos;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getUbicaciones = async (username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/ubi`, {
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.ubicaciones;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getDatosReloj = async (canal,estaciones,username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/reloj`, {
      canal,
      estaciones,
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.datos;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getCanalesEstaciones = async (estaciones,username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/canales`, {
      estaciones,
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.canal;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

export const getUnidadesCanales = async (estaciones,username) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/unidades`, {
      estaciones,
      username,
      // token
    },
    {
      // headers: {
      //   Authorization: token, // Incluir el token en los encabezados de autorización
      // }
    });
    console.log(response.data)
    return response.data.unidad;
  } catch (error) {
    console.error('Error al comparar email:', error);
    throw error; // Relanza el error para que sea manejado por el código que llama a esta función
  }
};

