import React, { useState } from 'react';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import { comprobarEmail } from '../api/loginApi.js';
import '../styles/registro.css';
import '../styles/recuperarContraseña.css';
import '../styles/App.css';
import { useNavigate } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

function RecuperarContraseña() {
  const [email, setEmail] = useState('');
  const [showTimer, setShowTimer] = useState(false);
  const [timerKey, setTimerKey] = useState(0); // Key para reiniciar el temporizador
  const navigate = useNavigate();

  const handleComprobarEmail = async () => {
    try {
      const response = await comprobarEmail(email);
      if (response) {
        // Si la respuesta no está vacía, mostrar el temporizador
        setShowTimer(true);
      } else {
        // Si la respuesta está vacía, mostrar un mensaje de error
        alert('El correo no coincide con ningún usuario registrado');
      }
    } catch (error) {
      console.error('Error al comprobar el correo:', error);
      // Manejar el error según tus necesidades (mostrar mensaje, registrar, etc.)
    }
  };

  const handleResendEmail = () => {
    // Lógica para reenviar el correo electrónico y reiniciar el temporizador
    alert('Correo reenviado correctamente');
    setShowTimer(true); // Mostrar el temporizador nuevamente
    setTimerKey((prevKey) => prevKey + 1); // Incrementar la key para reiniciar el temporizador
  };

  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='div-formulario'>
        <div className='div-tittleContact'>
          <div className='box'>
            <div className='tittle_register'>
              <h2>Retrieve password:</h2>
            </div>
            <div className='tittle_register'>
              <div className='mail'>
                <input
                  className='input-mail'
                  placeholder='Mail *'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className='div-button-recuperar'>
              <button className='botonRecuperar' onClick={handleComprobarEmail}>
                Check Email
              </button>
            </div>
            {showTimer && (
              <div className='temporizador-container'>
                <CountdownCircleTimer
                  key={timerKey} // Usar la key como prop para reiniciar el temporizador
                  isPlaying={true}
                  duration={600} // Duración en segundos (10 minutos)
                  size={200} // Tamaño del temporizador
                  strokeWidth={10} // Grosor de la línea del círculo
                  trailColor="#8DB1C0" // Color del fondo del temporizador
                  colors={[['#002551']]} // Color del círculo
                  onComplete={() => {
                    setShowTimer(false); // Ocultar el temporizador al finalizar
                    alert('¡Tiempo agotado! Por favor, vuelve a enviar el correo.');
                  }}
                >
                  {({ remainingTime }) => {
                    const minutes = Math.floor(remainingTime / 60);
                    const seconds = remainingTime % 60;
                    return (
                      <div className='timer'>
                        <div className='text'>
                          {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} {/* Formato MM:SS */}
                        </div>
                      </div>
                    );
                  }}
                </CountdownCircleTimer>
                <button className='botonRecuperar' onClick={handleResendEmail}>
                  Reenviar Correo
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecuperarContraseña;
