import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAndGetToken, usernameLogin } from '../api/loginApi.js';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [showEnterMessage, setShowEnterMessage] = useState(false);
  const navigate = useNavigate();
  const [visibilityPass, setVisibilityPass] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setVisibilityPass((prevShowPassword) => !prevShowPassword);
  };

  const handleRememberMeChange = (event) => {
    const rememberMe = event.target.checked;
    setRememberMe(rememberMe);
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('username', username);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('username');
    }
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      handleLogin(); // Inicia sesión automáticamente si se recuerda el usuario
    }
  }, []);

  const handleArrowClick = () => {
    const user = usernameLogin(username);
    if (user) {
      setShowPassword(true);
      setError(false);
    } else {
      setShowPassword(false);
      setError(true);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setShowPassword(false);
    setError(false);
    setShowEnterMessage(event.target.value.length > 0); // Muestra el mensaje si hay texto en el input
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Evita enviar el formulario al presionar Enter
      handleArrowClick(); // Llama a la función de inicio de sesión
    }
  };

  const handleLogin = async () => {
    try {
      const token = await loginAndGetToken(username, password);
      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('rememberMe', 'true');
        navigate('/Home');
      } else {
        setError(true); // Error de inicio de sesión
      }
    } catch (error) {
      setError(true); // Error de inicio de sesión
    }
  };

  const handleRegister = async () => {
    try {
      navigate('/Registro');
    } catch (error) {
      setError(true); // Error de inicio de sesión
    }
  };

  const handleRecuperar = async () => {
    try {
      navigate('/RecuperarContraseña');
    } catch (error) {
      setError(true);
    }
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className='userLogin'>
          <div className='usuario'>
            <label htmlFor="username">Username:</label>
            <div className="username-input">
              <input
                className="inputLogin"
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
                onKeyPress={handleKeyPress}
              />  
            </div>
          </div>
          {showEnterMessage && !showPassword && <div className="enter-message">Press Enter</div>}
          {/* {error && <div className="error-message">Acceso no autorizado</div>} */}
          {showPassword && (
            <div className='usuario'>
              <label htmlFor="password">Password:</label>
              <div className='password-input'>
                <input
                  type={visibilityPass ? 'text' : 'password'}
                  className="inputLogin"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span className="password-toggle" onClick={handleTogglePasswordVisibility}>
                  {visibilityPass ? <VscEye /> : <VscEyeClosed />}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="recordarme">
          <input type="checkbox" id="remember" name="remember" onChange={handleRememberMeChange} />
          <label htmlFor="remember">Recordarme</label>
        </div>
        <div className='problemas'>
          <a href='#' onClick={handleRecuperar}>Recuperar contraseña</a>
          <a href='#' onClick={handleRegister}>Registrarse como nuevo usuario</a>
        </div>
        <button type="submit" className='botonLogin' onClick={handleLogin}>Login</button>
      </form>
    </div>
  );
}

export default Login;
