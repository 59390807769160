import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import Chart from 'chart.js/auto';
import { getAlarmasDashboard, getUbicaciones, getGraphsDashboard, getDatosReloj, getCanalesEstaciones } from '../api/loginApi.js';
import { AiFillEnvironment } from 'react-icons/ai';
import ReactDOMServer from 'react-dom/server';
import { BsArrowsAngleExpand, BsArrowsAngleContract } from 'react-icons/bs';
import Reloj from '../Components/Reloj.js';
import '../styles/Notificacion.css';
import '../styles/Datos.css';
import { FaChartLine } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';




function Dashboard() {
  const [alarmasList, setAlarmasList] = useState([]);
  const [ubicacionesList, setUbicacionesList] = useState([]);
  const [graphDataByAlias, setGraphDataByAlias] = useState({});
  const [averageByAlias, setAverageByAlias] = useState({});
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const chartInstances = useRef({});
  const defaultCenter = useMemo(() => ({ lat: 41.3851, lng: 2.1734 }), []);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [datosEstaciones, setDatosEstaciones] = useState({});
  const [canalesEstaciones, setCanalesEstaciones] = useState({});
  const [canalSeleccionado, setCanalSeleccionado] = useState(null);
  const [canalesSeleccionados, setCanalesSeleccionados] = useState({});
  const navigate = useNavigate(); // Hook de navegación
  const [selectedLocation, setSelectedLocation] = useState(null);



  console.log('Datos estaciones');
  console.log(datosEstaciones);

  const calculateTimeElapsed = useCallback((dateString) => {
    const then = new Date(dateString);
    const now = new Date();
    const differenceInMillis = now - then;
    const differenceInMinutes = differenceInMillis / 60000;

    if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutos`;
    } else if (differenceInMinutes < 1440) {
      const hours = Math.floor(differenceInMinutes / 60);
      const remainingMinutes = Math.floor(differenceInMinutes % 60);
      return `${hours} horas ${remainingMinutes} minutos`;
    } else {
      const days = Math.floor(differenceInMinutes / 1440);
      const remainingHours = Math.floor((differenceInMinutes % 1440) / 60);
      return `${days} días ${remainingHours} horas`;
    }
  }, []);

  const contarEstaciones = useCallback(() => alarmasList.length, [alarmasList]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const username = localStorage.getItem('username');
      try {
        const ubicaciones = await getUbicaciones(username);
        setUbicacionesList(ubicaciones);

        const alarmas = await getAlarmasDashboard(username);
        setAlarmasList(alarmas);

        const graphs = await getGraphsDashboard(username);
        const dataByAlias = {};
        const averages = {};

        for (const graph of graphs) {
          const { ALIAS, FECHA, DATO, CANAL, UNIDAD, ESTACION } = graph;
          if (!dataByAlias[ALIAS]) {
            dataByAlias[ALIAS] = [];
          }
          dataByAlias[ALIAS].push({ FECHA, DATO, CANAL, UNIDAD, ESTACION });
        }

        for (const [alias, data] of Object.entries(dataByAlias)) {
          averages[alias] = calculateAverage(data);

          const { ESTACION } = data[0];
          const firstCanalData = data.find(entry => entry.ESTACION === ESTACION);

          if (firstCanalData) {
            const { UNIDAD } = firstCanalData;
            await fetchDatosReloj(ESTACION, UNIDAD, alias);
            await fetchCanalEstacion(ESTACION, alias);
          }
        }

        setGraphDataByAlias(dataByAlias);
        setAverageByAlias(averages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (Object.keys(graphDataByAlias).length > 0) {
      renderCharts();
    }
  }, [graphDataByAlias]);

  useEffect(() => {
    const inicializarSeleccion = () => {
        const seleccionInicial = {};
        Object.entries(canalesEstaciones).forEach(([alias, canales]) => {
            if (canales.length > 0) {
                seleccionInicial[alias] = canales[0].canal;  // Selecciona el primer canal
            }
        });
        setCanalesSeleccionados(seleccionInicial);
    };

    inicializarSeleccion();
  }, []);

  const calculateAverage = useCallback((data) => {
    const sum = data.reduce((acc, entry) => acc + parseFloat(entry.DATO), 0);
    return (sum / data.length).toFixed(2);
  }, []);

  const handleSelectCanal = async (alias, canal) => {
    setCanalesSeleccionados(prevState => ({
        ...prevState,
        [alias]: canal
    }));
    const estacion = canalesEstaciones[alias].find(item => item.canal === canal).estacion;
    await fetchDatosReloj(estacion, canal, alias);
  };

  const fetchDatosReloj = useCallback(async (estacion, canal, alias) => {
    try {
      const username = localStorage.getItem('username');
      const token = localStorage.getItem('token');
      const data = await getDatosReloj(canal, estacion, username, token);
      if (data.length > 0) {
        setDatosEstaciones(prevState => ({
          ...prevState,
          [alias]: {
            maximo: data[0].MAXIMO,
            minimo: data[0].MINIMO,  
            promedio: data[0].PROMEDIO,
            // unidades: data[0].UNIDADES
          }
        }));
      }
    } catch (error) {
      console.error('Error al obtener datos del reloj:', error);
    }
  }, []);

  const fetchCanalEstacion = useCallback(async (estacion, nombre) => {
    try {
      const username = localStorage.getItem('username');
      const token = localStorage.getItem('token');
      const data = await getCanalesEstaciones(estacion, username, token);
      if (Array.isArray(data) && data.length > 0) {
        const canales = data.map(item => ({
          canal: item.CANAL,
          estacion: item.ESTACION,
          alias: item.ALIAS
        }));
        setCanalesEstaciones(prevState => ({
          ...prevState,
          [nombre]: canales
        }));
      }
    } catch (error) {
      console.error('Error al obtener canales de estación:', error);
    }
  }, []);

  const renderCharts = useCallback(() => {
    Object.entries(graphDataByAlias).forEach(([alias, data]) => {
      const ctx = document.getElementById(`chart-${alias}`).getContext('2d');
  
      // Destroy existing chart instance if it exists
      if (chartInstances.current[alias]) {
        chartInstances.current[alias].destroy();
      }
  
      const datasetsByCanal = {};
  
      data.forEach((entry) => {
        const { CANAL, FECHA, DATO, UNIDAD } = entry;
  
        if (!datasetsByCanal[CANAL]) {
          datasetsByCanal[CANAL] = {
            label: `${alias} - ${UNIDAD}`,
            data: [],
            borderColor: getRandomColor(),
            borderWidth: 1,
            pointRadius: 0,
            fill: true,
          };
        }
  
        datasetsByCanal[CANAL].data.push({
          x: new Date(FECHA),
          y: parseFloat(DATO)
        });
      });
  
      const datasets = Object.values(datasetsByCanal);
  
      // Add average ±20% value lines
      datasets.forEach(dataset => {
        const dataValues = dataset.data.map(entry => entry.y);
        const avgValue = dataValues.reduce((sum, value) => sum + value, 0) / dataValues.length;
        const upperLimit = avgValue * 1.2;
        const lowerLimit = avgValue * 0.8;
  
        const upperLine = {
          label: 'Máximo',
          data: dataset.data.map(entry => ({ x: entry.x, y: upperLimit })),
          borderColor: dataset.borderColor,
          borderWidth: 1,
          borderDash: [10, 5],
          pointRadius: 0,
          fill: false,
        };
  
        const lowerLine = {
          label: 'Mínimo',
          data: dataset.data.map(entry => ({ x: entry.x, y: lowerLimit })),
          borderColor: dataset.borderColor,
          borderWidth: 1,
          borderDash: [10, 5],
          pointRadius: 0,
          fill: false,
        };
  
        datasets.push(upperLine, lowerLine);
      });
  
      // Create new chart instance and store it in the ref
      chartInstances.current[alias] = new Chart(ctx, {
        type: 'line',
        data: {
          datasets
        },
        options: {
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'DD/MM HH:mm'
                }
              }
            },
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                }
              }
            },
            crosshair: {
              line: {
                color: '#F66',  // Crosshair line color
                width: 1        // Crosshair line width
              },
              sync: {
                enabled: false  // Sync crosshairs across multiple charts
              },
              zoom: {
                enabled: false  // Allow zooming with crosshair
              }
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'index',
            intersect: false
          }
        }
      });
    });
  }, [graphDataByAlias]);
  
  


  useEffect(() => {
    if (mapRef.current && ubicacionesList.length > 0 && !mapInstance.current) {
      const map = L.map(mapRef.current).setView([defaultCenter.lat, defaultCenter.lng], 4);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      ubicacionesList.forEach((ubicacion) => {
        const customIcon = L.divIcon({
          className: 'custom-icon',
          html: ReactDOMServer.renderToString(<AiFillEnvironment size={32} color="red" />)
        });
        const marker = L.marker([ubicacion.LATITUD, ubicacion.LONGITUD], { icon: customIcon }).addTo(map);

        const ultimoDato = graphDataByAlias[ubicacion.ALIAS]?.slice(-1)[0];

        // Crear el contenido del popup
        const popupContent = `
          <div class="popup-content">
            <strong>${ubicacion.ALIAS}</strong><br/>
            Último dato: ${ultimoDato ? `${ultimoDato.DATO} ${ultimoDato.UNIDAD}<br/>Fecha: ${new Date(ultimoDato.FECHA).toLocaleString()}` : 'No hay datos disponibles'}<br/>
            <button id="data-button" style="
              display: block;
              margin-top: 10px;
              padding: 5px 10px;
              background-color: #01284F;
              color: white;
              border: none;
              cursor: pointer;
              border-radius: 4px;
              width: calc(3rem);
              height: calc(5vh);
              font-size: small;
            ">
              <i class="fa fa-line-chart" style="font-size: 1.5em;"></i> <!-- Font Awesome icon -->
            </button>
            <button id="maps-button" style="
              display: block;
              margin-top: 10px;
              padding: 5px 10px;
              background-color: #a9cce3;
              color: white;
              border: none;
              cursor: pointer;
              border-radius: 4px;
              width: calc(10rem);
              height: calc(5vh);
              font-size: small;
            ">
              Abrir en Google Maps
            </button>
          </div>
        `;

        marker.bindPopup(popupContent);
        
        marker.on('popupopen', () => {
          // Manejador de eventos para el botón de datos
          const dataButton = document.getElementById('data-button');
          const mapsButton = document.getElementById('maps-button');

          if (dataButton) {
            dataButton.addEventListener('click', () => {
              navigate(`/Datos?alias=${ubicacion.NOMBRE}&filtro=Hoy`);
            });
          }

          if (mapsButton) {
            mapsButton.addEventListener('click', () => {
              window.open(`https://www.google.com/maps?q=${ubicacion.LATITUD},${ubicacion.LONGITUD}`, '_blank');
            });
          }
        });

        marker.on('click', () => {
          map.setView([ubicacion.LATITUD, ubicacion.LONGITUD], 15);
        });
      });

      if (currentLocation) {
        L.circleMarker([currentLocation.lat, currentLocation.lng], {
          radius: 8,
          color: '#A9CCE3',
          fillOpacity: 1
        }).addTo(map);
      }

      mapInstance.current = map;
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
      }
    };
  }, [mapRef, ubicacionesList, currentLocation, defaultCenter, graphDataByAlias, navigate]);

 

  useEffect(() => {
    // Verifica si el navegador soporta la geolocalización
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Guardar la ubicación actual en el estado
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error obteniendo la ubicación:", error);
          alert("No se pudo obtener la ubicación actual.");
        }
      );
    } else {
      alert("La geolocalización no está disponible en este navegador.");
    }
  }, []);
  
  

  const fetchCurrentLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => {
        console.error('Error al obtener la ubicación actual:', error);
      }
    );
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (!isFullScreen) {
      const mapContainer = mapInstance.current.getContainer();
      if (mapContainer.requestFullscreen) {
        mapContainer.requestFullscreen();
      } else if (mapContainer.mozRequestFullScreen) {
        mapContainer.mozRequestFullScreen();
      } else if (mapContainer.webkitRequestFullscreen) {
        mapContainer.webkitRequestFullscreen();
      } else if (mapContainer.msRequestFullscreen) {
        mapContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  }, [isFullScreen]);

  const getRandomColor = useCallback(() => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }, []);

  return (
    <div className='dashboard'>
      <div className='box-info'>
        <div className='incidence-container'>
          <div className='header'>
            <div className='title'>
              <FaExclamationTriangle className='warning' /> <h3>Sin conexión: </h3>
            </div>
            <h3>{contarEstaciones()} estaciones</h3>
          </div>
          <table>
            <tbody>
              {alarmasList.map((estacion, index) => (
                <tr key={index}>
                  <FaInfoCircle className='infocircle' /><td>{estacion.ALIAS} <p>(hace {calculateTimeElapsed(estacion.FECHA)})</p></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='map' ref={mapRef}></div>
        {!isFullScreen ? (
          <button onClick={toggleFullScreen} className='pantalla_completa'><BsArrowsAngleExpand size={24} /></button>
        ) : (
          <button onClick={toggleFullScreen} className='pantalla_completa'><BsArrowsAngleExpand size={24} className='pantalla_completa'/></button>
        )}
      </div>

      <div id='parameter-container' className='notification-box'>
    {Object.entries(graphDataByAlias).map(([alias, data]) => (
        <div key={alias} className='parameter-container-notification'>
            <div className='chart-container'>
                <canvas id={`chart-${alias}`} style={{}}></canvas>
            </div>
            <hr />
            <div className='reloj-container'>
                <div className='button-reloj'>
                    {canalesEstaciones[alias].map((canal, index) => (
                        <button
                            className={`botones_canales ${canalesSeleccionados[alias] === canal.canal ? 'boton-canal-seleccionado' : ''}`}
                            key={index}
                            onClick={() => handleSelectCanal(alias, canal.canal)}
                        >
                            {canal.canal}
                        </button>
                    ))}
                </div>
                <Reloj value={datosEstaciones[alias]?.promedio} max={datosEstaciones[alias]?.maximo} min={datosEstaciones[alias]?.minimo} unidades={datosEstaciones[alias]?.unidades} />
            </div>
        </div>
    ))}
</div>
    </div>
  );
}

export default Dashboard;
