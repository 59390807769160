import React, { useState, useEffect, useContext } from 'react';
import aboutus from '../assets/aboutus.png';
import NavbarExample from './Navbar';
import Footer from './Footer.js';
import '../styles/about.css';
import { usernameType, RegistroUser, existeUsername, existeEmail, getDatosReloj } from '../api/loginApi.js';
import { useNavigate } from 'react-router-dom';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { LanguageContext } from './LanguajeContext.js';

// translations.js
const translations = {
  es: {
    aguaEsNuestroElemento: "Agua es nuestro elemento",
    laEmpresa: "La empresa DOSATRONIC GmbH con sede en Ravensburg, Alemania, desarrolla, produce y distribuye desde hace más de 20 años productos y sistemas para el sector del tratamiento del agua, tratamiento de aguas residuales, aguas de uso industrial, agua potable y de piscina y de la tecnología medioambiental.",
    unPortafolio: "Un portafolio de productos completo, espíritu innovador y la cercanía al cliente nos convierten en un socio perfecto para el tratamiento y la desinfección del agua.",
    nuestraOferta: "Nuestra oferta de servicios incluye:",
    aparatosDeMedicion: "Aparatos de medición y regulación",
    electrodosDePH: "Electrodos de pH y redox así como células de medición para conductividad, turbidez y temperatura",
    sensoresElectroquimicos: "Sensores electroquímicos para la medición de parámetros como cloro, dióxido de cloro, ozono, bromo, peróxido de hidrógeno, ácido peracético y clorito",
    bombasDosificadoras: "Bombas dosificadoras y sistemas completos de dosificación",
    sistemasDeDesinfeccion: "Sistemas de desinfección de alta calidad como sistemas de dióxido de cloro, ultravioleta o electrólisis",
    sistemasParaPiscinas: "Sistemas para piscinas",
    paraDesarrollar: "Para desarrollar y fabricar equipos y sistemas hechos a la medida y que compaginen perfectamente, es determinante combinar correctamente el material, la técnica y el know-how. Y nosotros no descansamos hasta alcanzarlo.",
    losProductos: "Los productos DOSATRONIC se distribuyen en todo el mundo gracias a nuestros socios comerciales en muchos países, con los cuales trabajamos desde hace muchos años. En Alemania contamos además con personal regional del servicio externo para un excelente servicio al cliente in situ.",
    consulteConNosotros: "Consulte con nosotros y le asesoramos individualmente. ¡No dude en contactarnos! Estamos a su disposición siempre.",
    hitos: "Hitos",
    desdeSuFundacion: "Desde su fundación en el año 2000, DOSATRONIC GmbH ha pasado de ser un distribuidor de componentes individuales en el campo de la tecnología de medición, control y dosificación a convertirse en un proveedor integral y especialista en todos los campos relacionados con el tratamiento del agua. Siempre al día en los últimos avances tecnológicos, nuestro objetivo es ofrecer a nuestros clientes la solución mejor y más flexible para satisfacer sus exigencias específicas. Como empresa familiar, los valores personales y la sostenibilidad son muy importantes para nosotros.",
    footer: {
      company: "DOSATRONIC GmbH",
      address: "Zuppingerstraße 8",
      city: "88213 Ravensburg",
      country: "Alemania",
    }
  },
  en: {
    aguaEsNuestroElemento: "Water is our element",
    laEmpresa: "The company DOSATRONIC GmbH, based in Ravensburg, Germany, has been developing, producing, and distributing products and systems for water treatment, wastewater treatment, industrial water, drinking water, and pool water for more than 20 years.",
    unPortafolio: "A complete product portfolio, innovative spirit, and customer proximity make us the perfect partner for water treatment and disinfection.",
    nuestraOferta: "Our service offering includes:",
    aparatosDeMedicion: "Measuring and control devices",
    electrodosDePH: "pH and redox electrodes as well as measurement cells for conductivity, turbidity, and temperature",
    sensoresElectroquimicos: "Electrochemical sensors for measuring parameters such as chlorine, chlorine dioxide, ozone, bromine, hydrogen peroxide, peracetic acid, and chlorite",
    bombasDosificadoras: "Dosing pumps and complete dosing systems",
    sistemasDeDesinfeccion: "High-quality disinfection systems such as chlorine dioxide systems, ultraviolet or electrolysis",
    sistemasParaPiscinas: "Pool systems",
    paraDesarrollar: "To develop and manufacture tailor-made equipment and systems that fit together perfectly, it is crucial to combine the right material, technique, and know-how. And we don't rest until we achieve it.",
    losProductos: "DOSATRONIC products are distributed worldwide thanks to our commercial partners in many countries, with whom we have been working for many years. In Germany, we also have regional field service personnel for excellent customer service on site.",
    consulteConNosotros: "Consult with us and we will provide individual advice. Do not hesitate to contact us! We are always at your disposal.",
    hitos: "Milestones",
    desdeSuFundacion: "Since its founding in 2000, DOSATRONIC GmbH has evolved from being a distributor of individual components in the field of measurement, control, and dosing technology to becoming a comprehensive provider and specialist in all areas related to water treatment. Always up to date with the latest technological advances, our goal is to offer our customers the best and most flexible solution to meet their specific requirements. As a family business, personal values and sustainability are very important to us.",
    footer: {
      company: "DOSATRONIC GmbH",
      address: "Zuppingerstraße 8",
      city: "88213 Ravensburg",
      country: "Germany",
    }
  },
  de: {
    aguaEsNuestroElemento: "Wasser ist unser Element",
    laEmpresa: "Das Unternehmen DOSATRONIC GmbH mit Sitz in Ravensburg, Deutschland, entwickelt, produziert und vertreibt seit mehr als 20 Jahren Produkte und Systeme für die Wasserbehandlung, Abwasserbehandlung, Industriewasser, Trinkwasser und Poolwasser sowie Umwelttechnologie.",
    unPortafolio: "Ein umfassendes Produktportfolio, Innovationsgeist und Kundennähe machen uns zum perfekten Partner für die Wasserbehandlung und -desinfektion.",
    nuestraOferta: "Unser Dienstleistungsangebot umfasst:",
    aparatosDeMedicion: "Mess- und Regelgeräte",
    electrodosDePH: "pH- und Redoxelektroden sowie Messzellen für Leitfähigkeit, Trübung und Temperatur",
    sensoresElectroquimicos: "Elektrochemische Sensoren zur Messung von Parametern wie Chlor, Chlordioxid, Ozon, Brom, Wasserstoffperoxid, Peressigsäure und Chlorit",
    bombasDosificadoras: "Dosierpumpen und komplette Dosiersysteme",
    sistemasDeDesinfeccion: "Hochwertige Desinfektionssysteme wie Chlordioxid-Systeme, UV- oder Elektrolyse",
    sistemasParaPiscinas: "Pool-Systeme",
    paraDesarrollar: "Um maßgeschneiderte Geräte und Systeme zu entwickeln und herzustellen, die perfekt zusammenpassen, ist es entscheidend, das richtige Material, die richtige Technik und das Know-how zu kombinieren. Und wir ruhen nicht, bis wir es erreicht haben.",
    losProductos: "DOSATRONIC-Produkte werden weltweit dank unserer Handelspartner in vielen Ländern verteilt, mit denen wir seit vielen Jahren zusammenarbeiten. In Deutschland haben wir auch regionales Außendienstpersonal für exzellenten Kundenservice vor Ort.",
    consulteConNosotros: "Konsultieren Sie uns und wir bieten Ihnen individuelle Beratung. Zögern Sie nicht, uns zu kontaktieren! Wir stehen Ihnen immer zur Verfügung.",
    hitos: "Meilensteine",
    desdeSuFundacion: "Seit der Gründung im Jahr 2000 hat sich die DOSATRONIC GmbH von einem Distributor einzelner Komponenten im Bereich Mess-, Regel- und Dosiertechnik zu einem umfassenden Anbieter und Spezialisten in allen Bereichen der Wasserbehandlung entwickelt. Immer auf dem neuesten Stand der technologischen Entwicklungen ist unser Ziel, unseren Kunden die beste und flexibelste Lösung zur Erfüllung ihrer spezifischen Anforderungen anzubieten. Als Familienunternehmen sind persönliche Werte und Nachhaltigkeit für uns sehr wichtig.",
    footer: {
      company: "DOSATRONIC GmbH",
      address: "Zuppingerstraße 8",
      city: "88213 Ravensburg",
      country: "Deutschland",
    }
  },
  pt: {
    aguaEsNuestroElemento: "Água é nosso elemento",
    laEmpresa: "A empresa DOSATRONIC GmbH, sediada em Ravensburg, Alemanha, desenvolve, produz e distribui há mais de 20 anos produtos e sistemas para o setor de tratamento de água, tratamento de águas residuais, águas industriais, água potável e piscina, e tecnologia ambiental.",
    unPortafolio: "Um portfólio completo de produtos, espírito inovador e proximidade com o cliente nos tornam o parceiro perfeito para o tratamento e desinfecção da água.",
    nuestraOferta: "Nossa oferta de serviços inclui:",
    aparatosDeMedicion: "Dispositivos de medição e controle",
    electrodosDePH: "Eletrodos de pH e redox, bem como células de medição para condutividade, turbidez e temperatura",
    sensoresElectroquimicos: "Sensores eletroquímicos para medição de parâmetros como cloro, dióxido de cloro, ozônio, bromo, peróxido de hidrogênio, ácido peracético e clorito",
    bombasDosificadoras: "Bombas dosadoras e sistemas completos de dosagem",
    sistemasDeDesinfeccion: "Sistemas de desinfecção de alta qualidade como sistemas de dióxido de cloro, ultravioleta ou eletrólise",
    sistemasParaPiscinas: "Sistemas para piscinas",
    paraDesarrollar: "Para desenvolver e fabricar equipamentos e sistemas sob medida que se encaixem perfeitamente, é crucial combinar o material certo, a técnica certa e o know-how. E não descansamos até conseguirmos.",
    losProductos: "Os produtos DOSATRONIC são distribuídos mundialmente graças aos nossos parceiros comerciais em muitos países, com os quais trabalhamos há muitos anos. Na Alemanha, também contamos com pessoal regional de serviço de campo para excelente atendimento ao cliente no local.",
    consulteConNosotros: "Consulte-nos e forneceremos aconselhamento individual. Não hesite em nos contatar! Estamos sempre à sua disposição.",
    hitos: "Marcos",
    desdeSuFundacion: "Desde a sua fundação em 2000, a DOSATRONIC GmbH evoluiu de um distribuidor de componentes individuais no campo da tecnologia de medição, controle e dosagem para se tornar um provedor abrangente e especialista em todas as áreas relacionadas ao tratamento da água. Sempre atualizada com os mais recentes avanços tecnológicos, nosso objetivo é oferecer aos nossos clientes a melhor e mais flexível solução para atender às suas necessidades específicas. Como empresa familiar, valores pessoais e sustentabilidade são muito importantes para nós.",
    footer: {
      company: "DOSATRONIC GmbH",
      address: "Zuppingerstraße 8",
      city: "88213 Ravensburg",
      country: "Alemanha",
    }
  }
};


function AboutUs() {
  const { language } = useContext(LanguageContext);
  const {
    aguaEsNuestroElemento,
    laEmpresa,
    unPortafolio,
    nuestraOferta,
    aparatosDeMedicion,
    electrodosDePH,
    sensoresElectroquimicos,
    bombasDosificadoras,
    sistemasDeDesinfeccion,
    sistemasParaPiscinas,
    paraDesarrollar,
    losProductos,
    consulteConNosotros,
    hitos,
    desdeSuFundacion,
    footer
  } = translations[language];

  return (
    <div>
      <NavbarExample/>
      <img src={aboutus} className='imagenaboutus' alt="About Us"/>
      <div className='boxaboutus'>
        <div className='elementaboutus1'>
          <h3 className='font'><strong>{aguaEsNuestroElemento}</strong></h3>
          <p className='paboutus'>{laEmpresa}</p>
          <p className='paboutus'>{unPortafolio}</p>
        </div>
        <div className='elementaboutus2'>
          <p className='paboutus'>{nuestraOferta}</p>
          <ul className='ulaboutus'>
            <li className='liaboutus'>{aparatosDeMedicion}</li>
            <li className='liaboutus'>{electrodosDePH}</li>
            <li className='liaboutus'>{sensoresElectroquimicos}</li>
            <li className='liaboutus'>{bombasDosificadoras}</li>
            <li className='liaboutus'>{sistemasDeDesinfeccion}</li>
            <li className='liaboutus'>{sistemasParaPiscinas}</li>
          </ul>
          <p className='paboutus2'>{paraDesarrollar}</p>
          <p className='paboutus'>{losProductos}</p>
          <p className='paboutus'>{consulteConNosotros}</p>
        </div>
      </div>
      <div className='divdivhitos'>
        <div className='divhitos'>
          <h3 className='h3hitos'>{hitos}</h3>
          <p className='phitos'>{desdeSuFundacion}</p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default AboutUs;
