import React, { useState, useEffect, useRef, useContext } from 'react';
import NavbarExample from './Navbar';
import { getGraficos, getEstaciones } from '../api/loginApi';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import '../styles/Datos.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'; 
import {BiFullscreen, BiExitFullscreen,  BiArrowToBottom, BiAlignLeft,  BiAlignMiddle, BiAlignRight  } from "react-icons/bi";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'chartjs-plugin-crosshair';
import 'jspdf-autotable';
//import Modal from '@mui/material/Modal';
import { BsXLg, BsFiletypePng, BsFiletypeCsv, BsFiletypeXls  } from "react-icons/bs";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import * as htmlDocx from 'html-docx-js/dist/html-docx';
import { BsArrowRightShort, BsArrowLeftShort  } from "react-icons/bs";
// import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';
import * as docx from 'docx'; // Importar todo el módulo docx
import { FaRegFilePdf } from "react-icons/fa6";
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import { IoIosSettings } from "react-icons/io";
import { LanguageContext } from '../Components/LanguajeContext';
import { Modal, Button } from 'react-bootstrap';
import { Select } from '@material-ui/core';
import { IoIosSave } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
import { selectClasses } from '@mui/material';




const translations = {
  es: {
    titulo: "Configuración",
    maximo: "Máximo",
    minimo: "Mínimo",
    estacion: "Estación",
    periodo: "Período",
    hoy: "Hoy",
    dia: "Días",
    semana: "Semana",
    mes: "Mes",
    año: "Año",
    canal: "Canal",
    fecha: "Fecha",
    dato: "Dato",
    unidad: "Unidad",
    selectEstacion: "Seleccionar Estación",
    selectFecha: "Seleccionar Fecha",
    selectColor: "Seleccionar color"
  },
  en: {
    titulo: "Settings",
    maximo: "Maximum",
    minimo: "Minimum",
    estacion: "Station",
    periodo: "Period",
    hoy: "Today",
    dia: "Days",
    semana: "Week",
    mes: "Month",
    año: "Year",
    canal: "Channel",
    fecha: "Date",
    dato: "Data",
    unidad: "Unit",
    selectEstacion: "Select Station",
    selectFecha: "Select Date",
    selectColor: "Select Color",

  },
  pt: {
    titulo: "Configuração",
    maximo: "Máximo",
    minimo: "Mínimo",
    estacion: "Estação",
    periodo: "Período",
    hoy: "Hoje",
    dia: "Dias",
    semana: "Semana",
    mes: "Mês",
    ano: "Ano",
    canal: "Canal",
    dato: "Data",
    unidad: "Unidade",
    selectEstacion: "Selecionar Estação",
    selectFecha: "Selecionar Data",
    selectColor: "Selecionar cor",
  },
  de: {
    titulo: "Konfiguration",
    maximo: "Maximal",
    minimo: "Minimum",
    estacion: "Station",
    periodo: "Zeitraum",
    hoy: "Heute",
    dias: "Tage",
    semana: "Woche",
    mes: "Monat",
    año: "Jahr",
    canal: "Kanal",
    dato: "Datum",
    unidad: "Einheit",
    selectEstacion: "Station auswählen",
    selectFecha: "Datum auswählen",
    selectColor: "Farbe auswählen",
  }
};


function formatDate(dateTimeString) {
  if (!dateTimeString) return ''; // Manejar caso de valor indefinido
  
  const dateTime = new Date(dateTimeString);
  if (isNaN(dateTime.getTime())) return ''; // Manejar formato de fecha inválido

  const day = dateTime.getDate().toString().padStart(2, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const year = dateTime.getFullYear();
  const hours = dateTime.getHours().toString().padStart(2, '0');
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

// Tamaños de folio en píxeles (aproximados)
const pageSizes = {
  A2: { width: 420 * 3.7795275591, height: 397 * 3.7795275591 }, // 420mm x 594mm
  A3: { width: 297 * 3.7795275591, height: 420 * 3.7795275591 }, // 297mm x 420mm
  A4: { width: 210 * 3.7795275591, height: 594 * 3.7795275591 }, // 210mm x 297mm
};

function Datos() {
  const [estaciones, setEstaciones] = useState([]);
  const [selectedEstacion, setSelectedEstacion] = useState('');
  const [graficoData, setGraficoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartInstance, setChartInstance] = useState(null);
  const [filtroSeleccionado, setFiltroSeleccionado] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState('');
  const [pageSize, setPageSize] = useState('A4');
  const [headerImage, setHeaderImage] = useState(null);
  const [footerImage, setFooterImage] = useState(null);
  const [headerImagePosition, setHeaderImagePosition] = useState('');
  const [footerImagePosition, setFooterImagePosition] = useState('');
  const [imageWidth, setImageWidth] = useState(50); // Default width
  const [imageHeight, setImageHeight] = useState(20); // Default height
  const [imageWidthFooter, setImageWidthFooter] = useState(50); // Default width
  const [imageHeightFooter, setImageHeightFooter] = useState(20); // Default height
  const [showPageNumbers, setShowPageNumbers] = useState(false);
  const [showPageNumbersFooter, setShowPageNumbersFooter] = useState(false)
  const [pageNumberPosition, setPageNumberPosition] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [headerText, setHeaderText] = useState('');
  const [headerTextPosition, setHeaderTextPosition] = useState('');
  const [footerText, setFooterText] = useState('');
  const [footerTextPosition, setFooterTextPosition] = useState('');
  const [showPortada, setShowPortada] = useState(false);
  const [flechasNav, setFlechasNav] = useState('izquierda');
  const [aliasEstacion, setAliasEstacion] = useState('');
  const [canalesAlias, setCanalesAlias] = useState([]);
  const [posicion1, setPosicion1] = useState('');
  const [posicion2, setPosicion2] = useState('');
  const [posicion3, setPosicion3] = useState('');
  const [posicion4, setPosicion4] = useState('');
  const [showTableinforme, setShowTableinforme] = useState(false);
  const [mediaInforme, setMediaInforme] = useState(0);
  const [canalAliasSeleccionado, setCanalAliasSeleccionado] = useState('');
  const [ultimoValor, setUltimoValor] = useState('');
  const contentRef = useRef();
  const location = useLocation(); // Obtiene la ubicación actual de la URL
  const [openSettings, setOpenSetting] = useState(false);
  const { language } = useContext(LanguageContext);
  const { titulo, periodo, estacion, hoy, dia, semana, mes, año, canal, fecha, dato, unidad, selectEstacion, selectFecha, minimo, maximo, selectColor } = translations[language];

  // const { Document, Packer, Paragraph, Table, TableCell, TableRow } = require("docx");

  useEffect(() => {
    async function fetchData() {
      try {
        const username = localStorage.getItem('username');
        const estacionesData = await getEstaciones(username);
        setEstaciones(estacionesData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error al obtener las estaciones:', error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleOpenSetting = () => {
    setOpenSetting(true);
  };
  
  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  useEffect(() => {
    async function fetchGraficoData() {
      try {
        if (selectedEstacion && filtroSeleccionado) {
          const username = localStorage.getItem('username');
          const graficosData = await getGraficos(username, selectedEstacion, filtroSeleccionado);
          setGraficoData(graficosData);
          setShowTable(true);
        }
      } catch (error) {
        console.error('Error al obtener los gráficos:', error);
      }
    }

    if (selectedEstacion && filtroSeleccionado) {
      fetchGraficoData();
    }
  }, [selectedEstacion, filtroSeleccionado]);

  useEffect(() => {
    if (graficoData && graficoData.length > 0) {
      renderizarGrafico();
    }
  }, [graficoData]);

  const toggleMenu = () => {
    // Implementa lógica de toggle para el menú si es necesario
  };

  useEffect(() => {
    // Obtener parámetros de la URL
    const query = new URLSearchParams(location.search);
    const alias = query.get('alias');
    const filtro = query.get('filtro');

    if (alias) {
      setSelectedEstacion(alias);
    }
    if (filtro) {
      setFiltroSeleccionado(filtro);
    }
  }, [location.search]);

  const handleEstacionChange = (event) => {
    const selected = event.target.value;
    setSelectedEstacion(selected);
    setGraficoData([]);
    setShowTable(false);
  };

  const handleFiltroChange = (event) => {
    const selectedFiltro = event.target.value;
    setFiltroSeleccionado(selectedFiltro);
    setShowTable(false);
  };


  const calcularMedia = (data) => {
    console.log('DATA:');
    console.log(data);
    const total = data.reduce((sum, value) => Number(sum) + Number(value), 0);
    console.log('TOTAL');
    console.log(total);
    console.log('TOTAL/LENGTH');
    console.log(total/data.length);
    return total / data.length;
  };
  
  const calcularLineasDiscontinuas = (media) => {
    const incremento = media * 0.2;
    return {
      max: media + incremento,
      min: media - incremento,
    };
  };
  

  const renderizarGrafico = () => {
    if (!selectedEstacion || !filtroSeleccionado || !graficoData || graficoData.length === 0) {
      console.error('No hay datos válidos para graficar.');
      return;
    }
  
    if (chartInstance) {
      chartInstance.destroy();
    }
  
    const canvas = document.getElementById(`grafico-${selectedEstacion}`);
    if (!canvas) {
      console.error('Elemento canvas no encontrado.');
      return;
    }
  
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('No se pudo obtener el contexto 2D del canvas.');
      return;
    }
  
    ctx.fillStyle = '#ffffff'; // Color blanco
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    const datasetsByCanal = {};
  
    graficoData.forEach((dato) => {
      const { CANAL, UNIDAD, FECHA, DATO } = dato;
      if (!datasetsByCanal[CANAL]) {
        datasetsByCanal[CANAL] = {
          label: `${CANAL} - ${UNIDAD}`,
          data: [],
          borderColor: getRandomBlueColor(),
          fill: true,
          tension: 0.1,
          pointRadius: 0,
        };
      }
      datasetsByCanal[CANAL].data.push({ x: new Date(FECHA), y: DATO });
    });
  
    const datasets = Object.values(datasetsByCanal);
  
    datasets.forEach((dataset) => {
      const todosLosDatos = dataset.data.map(d => d.y);
      const max = Math.max(...todosLosDatos);
      const min = Math.min(...todosLosDatos);
  
      const maxAdjusted = max + max * 0.2;
      const minAdjusted = min - min * 0.2;
  
      const maxLineData = dataset.data.map((point) => ({ x: point.x, y: maxAdjusted }));
      const minLineData = dataset.data.map((point) => ({ x: point.x, y: minAdjusted }));
  
      datasets.push({
        label: `Max ${maxAdjusted.toFixed(2)}`, // Mostrar solo el valor original de max
        data: maxLineData,
        borderColor: dataset.borderColor,
        borderDash: [10, 5],
        fill: false,
        pointRadius: 0,
      });
  
      datasets.push({
        label: `Min ${minAdjusted.toFixed(2)}`, // Mostrar solo el valor original de min
        data: minLineData,
        borderColor: dataset.borderColor,
        borderDash: [10, 5],
        fill: false,
        pointRadius: 0,
      });
    });
  
    const chartConfig = {
      type: 'line',
      data: {
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: filtroSeleccionado === 'Hoy' || filtroSeleccionado === '2 días' ? 'minute' : 'day',
              displayFormats: {
                [filtroSeleccionado === 'Hoy' || filtroSeleccionado === '2 días' ? 'minute' : 'day']: filtroSeleccionado === 'Hoy' || filtroSeleccionado === '2 días' ? 'HH:mm' : 'DD/MM',
              },
            },
            ticks: {
              source: 'auto',
            },
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            grace: '10%',
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          crosshair: {
            line: {
              color: '#F66',
              width: 1,
            },
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
          },
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      },
    };
  
    const newChartInstance = new Chart(ctx, chartConfig);
    setChartInstance(newChartInstance);
  };
  
  const handleOpen = () => {
    setOpen(true);
    aliasNombre();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportToWord = async () => {
    const doc = new docx.Document();
  
    const addImageToWord = async (imageData) => {
      try {
        // Convertir la imagen a un Blob
        const response = await fetch(imageData);
        const imageBlob = await response.blob();
  
        // Verificar que la respuesta es válida antes de agregar la imagen
        if (!imageBlob) {
          console.error('Error: No se pudo obtener el Blob de la imagen.');
          return;
        }
  
        // Agregar la imagen al documento
        const image = docx.Media.addImage(doc, imageBlob);
        if (!image) {
          console.error('Error: No se pudo agregar la imagen al documento.');
          return;
        }
        const imageParagraph = new docx.Paragraph().addRun(image);
        doc.addParagraph(imageParagraph);
      } catch (error) {
        console.error('Error al agregar la imagen al documento:', error);
      }
    };
  
    const addTextToWord = (text) => {
      const textParagraph = new docx.Paragraph().addRun(new docx.TextRun(text));
      doc.addParagraph(textParagraph);
    };
  
    try {
      addTextToWord('Informe Generado\n\n');
  
      // Agregar tabla al documento
      addTextToWord('Datos Tabulados\n\n');
      const table = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({ children: [new docx.Paragraph('Canal')] }),
              new docx.TableCell({ children: [new docx.Paragraph('Fecha')] }),
              new docx.TableCell({ children: [new docx.Paragraph('Dato')] }),
              new docx.TableCell({ children: [new docx.Paragraph('Unidad')] }),
            ],
          }),
          // Ejemplo de datos para la tabla
          ...graficoData.map((graph) =>
            new docx.TableRow({
              children: [
                new docx.TableCell({ children: [new docx.Paragraph(graph.CANAL)] }),
                new docx.TableCell({ children: [new docx.Paragraph(formatDate(graph.FECHA))] }),
                new docx.TableCell({ children: [new docx.Paragraph(graph.DATO)] }),
                new docx.TableCell({ children: [new docx.Paragraph(graph.UNIDAD)] }),
              ],
            })
          ),
        ],
      });
      doc.addTable(table);
  
      addTextToWord('\n\nGráfico Generado\n\n');
  
      const canvasElement = document.getElementById(`grafico-${selectedEstacion}`);
      if (canvasElement) {
        const chartCanvas = await html2canvas(canvasElement, { scale: 2 });
        const chartImage = chartCanvas.toDataURL('image/png');
  
        await addImageToWord(chartImage); // Llama a la función para agregar la imagen al documento
      } else {
        console.error('Elemento canvas del gráfico no encontrado.');
      }
  
      const buffer = await docx.Packer.toBuffer(doc);
      saveAs(new Blob([buffer]), 'informe.docx');
    } catch (error) {
      console.error('Error al exportar a Word:', error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setHeaderImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setHeaderImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const addHeaderImage = (pdf, yOffset) => {
    if (headerImage) {
      const imgProps = pdf.getImageProperties(headerImage);
      const imgWidth = imgProps.width / 60; // Reducir el ancho a un cuarto
      const imgHeight = imgProps.height / 60; // Reducir la altura a un cuarto
      pdf.addImage(headerImage, 'PNG', 10, 10, imgWidth, imgHeight);
      return imgHeight + 20; // Ajuste de margen después de la imagen
    }
    return 0;
  };

  const handleExportToPDFinforme = async () => {
    const pdf = new jsPDF();
    const options = {
      useCORS: true,
      scale: 2,
    };

    const addContentToPDF = async (position, pdf, yOffset) => {
      const pageHeight = pdf.internal.pageSize.height;
      const margin = 20;

      switch (position) {
        case 'Tabla':
          const hiddenTableElement = document.createElement('table');
          const tableHead = `
            <tr>
              <th>Canal</th>
              <th>Fecha</th>
              <th>Dato</th>
              <th>Unidad</th>
            </tr>`;
          const tableBody = graficoData.map(graph => `
            <tr>
              <td>${graph.CANAL}</td>
              <td>${formatDate(graph.FECHA)}</td>
              <td>${graph.DATO}</td>
              <td>${graph.UNIDAD}</td>
            </tr>`).join('');

          hiddenTableElement.innerHTML = `
            <thead>${tableHead}</thead>
            <tbody>${tableBody}</tbody>`;

          hiddenTableElement.setAttribute('style', 'position: absolute; left: -9999px');
          document.body.appendChild(hiddenTableElement);

          pdf.autoTable({
            html: hiddenTableElement,
            startY: yOffset,
            headStyles: {
              fillColor: [1, 40, 79],
              textColor: [255, 255, 255],
              fontStyle: 'bold'
            },
          });

          document.body.removeChild(hiddenTableElement);
          return pdf.autoTable.previous.finalY + margin; // Añadir margen adicional

        case 'Grafico':
          const canvasElement = document.getElementById(`grafico-${selectedEstacion}`);
          if (canvasElement) {
            const chartCanvas = await html2canvas(canvasElement, { scale: 2 });
            const chartImage = chartCanvas.toDataURL('image/png');
            const chartWidth = pdf.internal.pageSize.getWidth() - 20;
            const chartHeight = (chartCanvas.height * chartWidth) / chartCanvas.width;

            if (yOffset + chartHeight + margin > pageHeight) {
              pdf.addPage();
              yOffset = addHeaderImage(pdf, 10); // Reiniciar el desplazamiento vertical con la imagen de encabezado
            }

            pdf.addImage(chartImage, 'PNG', 10, yOffset, chartWidth, chartHeight);
            return yOffset + chartHeight + margin; // Añadir margen adicional
          }
          break;

        case 'ultimo':
          const ultimoValorRecogido = graficoData[graficoData.length - 1].DATO;
          pdf.setFontSize(12);
          pdf.setFillColor(255, 255, 255);
          pdf.rect(10, yOffset, 190, 20, 'F');
          pdf.text(`Último valor ${canalesAlias}: ${ultimoValorRecogido}`, 15, yOffset + 15);
          return yOffset + 50; // Añadir margen adicional

        case 'Medio':
          const valoresDatos = graficoData.map(graph => graph.DATO);
          const media = calcularMedia(valoresDatos);
          pdf.setFontSize(12);
          pdf.setFillColor(255, 255, 255);
          pdf.rect(10, yOffset, 190, 20, 'F');
          pdf.text(`Valor medio ${canalesAlias}: ${media}`, 15, yOffset + 15);
          return yOffset + 50; // Añadir margen adicional

        default:
          return yOffset;
      }
    };

    try {
      let yOffset = 10; // Desplazamiento vertical inicial

      if (showPortada) {
        const portadaCanvas = await html2canvas(contentRef.current, options);
        const portadaImage = portadaCanvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (portadaCanvas.height * imgWidth) / portadaCanvas.width;

        pdf.addImage(portadaImage, 'PNG', 0, 0, imgWidth, imgHeight);
        yOffset = imgHeight + 10;

        if (yOffset >= pageHeight) {
          pdf.addPage();
          addHeaderImage(pdf);
          yOffset = 10;
        }
      }

      yOffset = addHeaderImage(pdf, yOffset);

      yOffset = await addContentToPDF(posicion1, pdf, yOffset);
      if (yOffset >= pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yOffset = addHeaderImage(pdf, 10);
      }

      yOffset = await addContentToPDF(posicion2, pdf, yOffset);
      if (yOffset >= pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yOffset = addHeaderImage(pdf, 10);
      }

      yOffset = await addContentToPDF(posicion3, pdf, yOffset);
      if (yOffset >= pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yOffset = addHeaderImage(pdf, 10);
      }

      yOffset = await addContentToPDF(posicion4, pdf, yOffset);
      if (yOffset >= pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yOffset = addHeaderImage(pdf, 10);
      }

      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.text(`Página ${i} de ${pageCount}`, 105, 290, { align: 'center' });
      }

      pdf.save('informe.pdf');
    } catch (error) {
      console.error('Error al exportar a PDF:', error);
    }
  };



  const handlePageNumberPositionChange = (position) => {
    if (position !== headerImagePosition) {
      setPageNumberPosition(position);
    }
  };


  const handleImageUploadFooter = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFooterImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDropFooter = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFooterImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  
  
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRandomBlueColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.floor(Math.random() * 30);
    const lightness = 40 + Math.floor(Math.random() * 40);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const handleExportToExcel = () => {
    if (!graficoData || graficoData.length === 0) return;
  
    const formattedData = graficoData.map((data) => ({
      Canal: data.CANAL,
      Fecha: formatDate(data.FECHA),
      Dato: data.DATO,
      Unidad: data.UNIDAD,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
  
    // Generar un archivo binario desde el libro y guardarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(excelBlob, 'datos.xlsx');
  };

  const handleExportChart = () => {
    if (chartInstance) {
      const canvas = chartInstance.canvas;
      canvas.toBlob((blob) => {
        saveAs(blob, 'grafico.png');
      });
    }
  };

  const handleExportToPDF = async () => {
    const pdf = new jsPDF('landscape'); // Orientación landscape para mejor visualización de la tabla

    // Exportar gráfico
    const canvasElement = document.getElementById(`grafico-${selectedEstacion}`);
    if (canvasElement) {
        const chartCanvas = await html2canvas(canvasElement, { scale: 2 });
        const chartImage = chartCanvas.toDataURL('image/png');
        const chartWidth = pdf.internal.pageSize.getWidth() - 20;
        const chartHeight = (chartCanvas.height * chartWidth) / chartCanvas.width;

        pdf.addImage(chartImage, 'PNG', 10, 10, chartWidth, chartHeight);
        pdf.addPage(); // Agregar una nueva página para la tabla
    }

    // Crear una tabla completa en HTML que no se muestra en pantalla
    const hiddenTableElement = document.createElement('table');
    const tableHead = `
        <tr>
          <th>Canal</th>
          <th>Fecha</th>
          <th>Dato</th>
          <th>Unidad</th>
        </tr>`;
    const tableBody = graficoData.map(graph => `
        <tr>
          <td>${graph.CANAL}</td>
          <td>${formatDate(graph.FECHA)}</td>
          <td>${graph.DATO}</td>
          <td>${graph.UNIDAD}</td>
        </tr>`).join('');
        
    hiddenTableElement.innerHTML = `
        <thead>${tableHead}</thead>
        <tbody>${tableBody}</tbody>`;
        
    hiddenTableElement.setAttribute('style', 'position: absolute; left: -9999px'); // Hacer la tabla invisible pero accesible

    document.body.appendChild(hiddenTableElement);

    // Convertir la tabla en PDF usando jspdf-autotable
    pdf.autoTable({
        html: hiddenTableElement,
        headStyles: {
            fillColor: [1, 40, 79], // color de fondo del encabezado
            textColor: [255, 255, 255], // color del texto del encabezado
            fontStyle: 'bold' // estilo de texto en negrita del encabezado
        }
    });

    // Eliminar la tabla temporal del DOM
    document.body.removeChild(hiddenTableElement);

    // Guardar el PDF
    pdf.save('datos.pdf');
};

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Activar pantalla completa
      const element = document.getElementById(`grafico-${selectedEstacion}`);
      if (element) {
        element.requestFullscreen();
      }
    } else {
      // Salir de pantalla completa
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen); // Toggle el estado
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
  
    document.addEventListener('fullscreenchange', fullscreenChangeHandler);
  
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
    };
  }, []);
  
  const findNearestPoint = (targetDate) => {
    if (!chartInstance || !targetDate) return null;
  
    const datasets = chartInstance.data.datasets;
    let nearestPoint = null;
    let minDistance = Infinity;
    const targetDateObj = new Date(targetDate); // Convertir a objeto Date
  
    datasets.forEach((dataset) => {
      dataset.data.forEach((point) => {
        const distance = Math.abs(point.x.getTime() - targetDateObj.getTime()); // Calcular la distancia en milisegundos
        if (distance < minDistance) {
          minDistance = distance;
          nearestPoint = point;
        }
      });
    });
  
    return nearestPoint;
  };
  
  
  const highlightPoint = (fecha) => {
    if (!chartInstance || !fecha) return;
  
    const targetDate = new Date(fecha);
  
    const nearestPoints = chartInstance.getElementsAtEventForMode('click', targetDate, { intersect: false }, false);
    if (nearestPoints.length > 0) {
      const datasetIndex = nearestPoints[0].datasetIndex;
      const dataIndex = nearestPoints[0].index;
      const dataset = chartInstance.data.datasets[datasetIndex];
      const point = dataset.data[dataIndex];
      
      // Actualizar el tooltip con la información del punto
      chartInstance.tooltip._active = [point];
      chartInstance.tooltip.update();
  
      // Dibujar el gráfico
      chartInstance.draw();
    }
  };
  
  
  
  const handleTableRowClick = (fecha) => {
    // Buscar el punto más cercano en el gráfico y resaltarlo
    const nearestPoint = findNearestPoint(fecha);
    if (nearestPoint) {
      highlightPoint(nearestPoint);
    }
  };

  const toggleBold = () => {
    if (headerText.includes("**")) {
      setHeaderText(headerText.replace(/\*\*/g, ""));
    } else {
      setHeaderText(`**${headerText}**`);
    }

    if(footerText.includes("**")) {
      setFooterText(footerText.replace(/\*\*/g, ""));
    } else {
      setFooterText(`**${footerText}**`);
    }
  };
  
  const toggleItalic = () => {
    if (headerText.includes("*")) {
      setHeaderText(headerText.replace(/\*/g, ""));
    } else {
      setHeaderText(`*${headerText}*`);
    }

    if (footerText.includes("*")) {
      setFooterText(footerText.replace(/\*/g, ""));
    } else {
      setFooterText(`*${footerText}*`);
    }
  };

  const isBold = (text) => {
    return text.startsWith("**") && text.endsWith("**");
  };
  
  const isItalic = (text) => {
    return text.startsWith("*") && text.endsWith("*");
  };
  
  const renderFormattedText = (text) => {
    let formattedText = text;
    if (isBold(text)) {
      formattedText = text.slice(2, -2); // Elimina los marcadores de negrita
    }
    if (isItalic(text)) {
      formattedText = text.slice(1, -1); // Elimina los marcadores de cursiva
    }
    return formattedText;
  };

  const aliasNombre = () => {
    const selectedItem = estaciones.find(item => item.ESTACION === selectedEstacion);
    if (selectedItem) {
      setAliasEstacion(selectedItem.ALIAS);
    }

    const SelectedCanales = graficoData.filter(item => item.ALIAS !== aliasEstacion);

    if (SelectedCanales.length > 0) {
      const canales = SelectedCanales.map(item => item.CANAL);
      const canalesUnicos = [...new Set(canales)]; // Eliminar duplicados usando Set
      setCanalesAlias(canalesUnicos);
    } else {
      // Manejar el caso donde no se encontraron canales diferentes
      setCanalesAlias([]);
    }
    

    console.log('canales');
    console.log(canalesAlias);
    
  };

  const handlePositionChange1 = (value) => {
    setPosicion1(value);
  };

  const handlePositionChange2 = (value) => {
    setPosicion2(value);
  };
  
  const handlePositionChange3 = (value) => {
    setPosicion3(value);
  };
  
  const handlePositionChange4 = (value) => {
    setPosicion4(value);
  };

  const handleValorMedio = () => {
    // Filtrar los datos por la estación y canal seleccionados
    const datosFiltrados = graficoData.filter(item =>
      item.ESTACION === selectedEstacion && item.CANAL === canalesAlias
    );

    console.log('datos filtrados');
    console.log(datosFiltrados);
  
    // Verificar si se encontraron datos para calcular la media
    if (datosFiltrados.length === 0) {
      console.log(`No hay datos para la estación ${selectedEstacion} y canal ${canalesAlias}`);
      return;
    }
  
    // Obtener los valores numéricos de DATO y calcular la media
    const valores = datosFiltrados.map(item => parseFloat(item.DATO));
    console.log('valores');
    console.log(valores);
    const media = valores.reduce((acc, val) => acc + val, 0) / valores.length;
    console.log('media');
    console.log(media);
    setMediaInforme(media);
  
    console.log(`La media de los datos para la estación ${selectedEstacion} y canal ${canalesAlias} es: ${media} ppm`);
  };

const renderContentByPosition = (position) => {
  switch (position) {
    case 'Tabla':
      return (
        <div className='tabla_datosss' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <TableContainer component={Paper} style={{ width: '100%', borderRadius: '10px', height: '100%' }} className='table-container'>
            <Table id="tabla-datosss" aria-label="alarmas table" style={{ width: '100%', overflowY: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell className='head-alarmas'>{canal}</TableCell>
                  <TableCell className='head-alarmas'>{fecha}</TableCell>
                  <TableCell className='head-alarmas'>{dato}</TableCell>
                  <TableCell className='head-alarmas'>{unidad}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: 'auto' }}>
                {graficoData.map((graph, index) => (
                  <TableRow key={index} onClick={() => handleTableRowClick(new Date(graph.DATO))} style={{ cursor: 'pointer' }}>
                    <TableCell>{graph.CANAL}</TableCell>
                    <TableCell>{formatDate(graph.FECHA)}</TableCell>
                    <TableCell>{graph.DATO}</TableCell>
                    <TableCell>{graph.UNIDAD}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    case 'Grafico':
      return (
        <div>
          <canvas id={`grafico-${selectedEstacion}`} width="200" height="100" className='canvas-fullscreen'></canvas>
        </div>
      );
      case 'ultimo':
        // Llamar handleUltimoValor aquí solo si es necesario
        const ultimoValorRecogido = graficoData[graficoData.length - 1].DATO;
        return (
          <div className='boxMediaInforme'>
            <label>Último valor {canalesAlias}</label>
            <div className='mediainforme'>
              <p className='pmediaInforme'>{ultimoValorRecogido}</p>
            </div>
          </div>
        );
    case 'Medio':
      // Assuming 'graficoData' contains the data you want to calculate the average of
      const valoresDatos = graficoData.map(graph => graph.DATO);
      const media = calcularMedia(valoresDatos);
      
      return (
        <div className='boxMediaInforme'>
          <label>Valor medio {canalesAlias}</label>
          <div className='mediainforme'>
            <p className='pmediaInforme'>{media}</p>
          </div>
        </div>
      );
    default:
      return null;
  }
};

  const handleCanalAliasChange = (event) => {
    const selected = event.target.value;
    setCanalAliasSeleccionado(selected);
    handleValorMedio();
  };

const handleUltimoValor = () => {
  // Obtén el último valor de graficoData
  const ultimo = graficoData[graficoData.DATO.length - 1];
  setUltimoValor(ultimo);
};


  return (
    <div>
      <NavbarExample toggleMenu={toggleMenu} />

      <div className='select-estacion'>
        <div className='select-label'>
        <label className='titulo-label'>{estacion}: </label>
        {!isLoading && estaciones && estaciones.length > 0 ? (
          <select className='seleccionador' onChange={handleEstacionChange} value={selectedEstacion}>
            <option className='option-select' value="">{selectEstacion}</option>
            {estaciones.map((item) => (
              <option key={item.ESTACION} value={item.ESTACION}>
                {item.ALIAS}
              </option>
            ))}
          </select>
        ) : (
          <p>Cargando estaciones...</p>
        )}

        </div>

        <div className='select-label'>
          <label className='titulo-label'>{periodo}: </label>
        <select className='seleccionador' onChange={handleFiltroChange} value={filtroSeleccionado}>
          <option value="">- {selectFecha} -</option>
          <option value="Hoy">{hoy}</option>
          <option value="2 días">2 {dia}</option>
          <option value="1 semana">1 {semana}</option>
          <option value="1 mes">1 {mes}</option>
          <option value="1 año">1 {año}</option>
        </select>
        </div>
      </div>




<div className='informacion'>
      <div className='cuadrar-grafico'>
        {selectedEstacion && (

          <div className='grafico-datos'>
                      <div className='pantallaCompleta'>
          <button className='boton-dentro-pantalla' onClick={toggleFullScreen}>
      {isFullScreen ? <BiExitFullscreen /> : <BiFullscreen />}
    </button>
    <button className='boton-dentro-pantalla' onClick={handleOpenSetting}>
      <IoIosSettings />
    </button>
    </div>
            <canvas id={`grafico-${selectedEstacion}`} width="400" height="200" className='canvas-fullscreen'></canvas>
          </div>
        )}
      </div>

      <Modal show={openSettings} onHide={handleCloseSetting}>
  <Modal.Header>
    <Modal.Title className='headerfooter'>
      <h2>{titulo}</h2>
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {[...new Set(graficoData.map(graph => graph.CANAL))].map((canal, index) => (
      <div key={index}>
        <div className='canal-select-container'>
          <label className='titulo-label'>{canal}: </label>
          <select className='seleccionador'>
            <option value="" disabled>{selectColor}</option>
            <option value="#cd6155" style={{ backgroundColor: 'white', color: '#cd6155' }}>───────────────</option>
            <option value="#a569bd" style={{ backgroundColor: 'white', color: '#a569bd' }}>───────────────</option>
            <option value="#2980b9" style={{ backgroundColor: 'white', color: '#2980b9' }}>───────────────</option>
            <option value="#1abc9c" style={{ backgroundColor: 'white', color: '#1abc9c' }}>───────────────</option>
            <option value="#16a085" style={{ backgroundColor: 'white', color: '#16a085' }}>───────────────</option>
            <option value="#f1c40f" style={{ backgroundColor: 'white', color: '#f1c40f' }}>───────────────</option>
            <option value="#e67e22" style={{ backgroundColor: 'white', color: '#e67e22' }}>───────────────</option>
            <option value="#2e4053" style={{ backgroundColor: 'white', color: '#2e4053' }}>───────────────</option>
            <option value="black" style={{ backgroundColor: 'white', color: 'black' }}>───────────────</option>
          </select>
        </div>

        <div className='maxmincanal'>
          <div className='canal-select-container'>
            <label className='titulo-label'>{minimo}: </label>
            <input type="number" className='input-minimo' />
          </div>

          <div className='canal-select-container'>
            <label className='titulo-label'>{maximo}: </label>
            <input type="number" className='input-maximo' />
          </div>
        </div>
        <br />
        <hr />
        <br />
      </div>
    ))}
    <div className='maxmincanal'>
      <button className='boton-dentro'><IoIosSave /></button>
      <button className='boton-dentro' onClick={handleCloseSetting}><IoMdCloseCircle /></button>
    </div>
  </Modal.Body>
</Modal>







      {showTable && (
      <div className='tabla_datosss' style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40%'}}>
        <TableContainer component={Paper} style={{ width: '100%', borderRadius: '10px', height: '100%'}} className='table-container'>
          <Table id="tabla-datosss" aria-label="alarmas table" style={{ width: '100%', overflowY: 'auto'}}>
            <TableHead>
              <TableRow>
                <TableCell className='head-alarmas'>{canal}</TableCell>
                <TableCell className='head-alarmas'>{fecha}</TableCell>
                <TableCell className='head-alarmas'>{dato}</TableCell>
                <TableCell className='head-alarmas'>{unidad}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{overflowY:'auto'}}>
              {graficoData.map((graph, index) => (
                <TableRow key={index} onClick={() => handleTableRowClick(new Date(graph.DATO))} style={{cursor: 'pointer'}}>
                  <TableCell>{graph.CANAL}</TableCell>
                  <TableCell>{formatDate(graph.FECHA)}</TableCell>
                  <TableCell>{graph.DATO}</TableCell>
                  <TableCell>{graph.UNIDAD}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )}
</div>

  
{showTable && (
      <div className='centrar-dentro'>

        <div className='dividir-datos'>

        <label className='titulo-label'>Exportar Gráfico: </label>
      <button className='boton-dentro' onClick={handleExportChart}>
              <BsFiletypePng/>
            </button>

            <button className='boton-dentro' onClick={handleExportToPDF}>
              <FaRegFilePdf/>
            </button>
        </div>

        <div className='dividir-datos'>
          <label className='titulo-label'>Exportar tabla: </label>
        <CSVLink
          data={graficoData.map(({ CANAL, FECHA, DATO, UNIDAD }) => ({
            Canal: CANAL,
            Fecha: formatDate(FECHA),
            Dato: DATO,
            Unidad: UNIDAD,
          }))}
          filename={"datos.csv"}
          className='boton-dentro'
        >
          <BsFiletypeCsv/>
        </CSVLink>
        
          <button className='boton-dentro' onClick={handleExportToExcel}>
            <BsFiletypeXls/>
          </button>
        </div>
      </div>
             )}
{showTable && (
              <div className='box-generar'>
             <button className='boton-informe' onClick={handleOpen}>Generar informe<BiArrowToBottom/></button>

             <Modal open={open} onClose={handleClose} className='modal_inf'>
  <div className='model_informe'>
    <button onClick={handleClose}>
      <BsXLg />
    </button>
    <h2 className='h2Informe'>INFORME ESTACIÓN DE {aliasEstacion}</h2>
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}></div>
    <div className='folio'>
      <label htmlFor="pageSize">Tamaño del folio: </label>
      <div className='botonesFolio'>
        <button onClick={() => setPageSize("A4")} className='A4Folio'>A4</button>
        <button onClick={() => setPageSize("A3")} className='A3Folio'>A3</button>
        <button onClick={() => setPageSize("A2")} className='A2Folio'>A2</button>
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
      {flechasNav === 'izquierda' &&
      <div className='contenido'>
        <div className='headerinforme'>
          <h2>Header</h2>
          <div className='boxheader'>
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{
                border: '2px dashed #ccc',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                 width: 'calc(20rem)'
              }}
              onClick={() => document.getElementById('headerImageUpload').click()}
            >
              {headerImage ? (
                <img src={headerImage} alt="Header" style={{ maxWidth: `${imageWidth}%`, maxHeight: `${imageHeight}px` }} />
              ) : (
                <p>Arrastra una imagen aquí o haz clic para seleccionar una</p>
              )}
              <input
                type="file"
                id="headerImageUpload"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
            </div>
            <div className='imageSize'>
              <div className='imageSizeBox'>
                <p className='anchoinforme'>Ancho: </p>
                <input className='inputsize'
                  type="number"
                  id="imageWidth"
                  value={imageWidth}
                  onChange={(e) => setImageWidth(e.target.value)}
                />
              </div>
              <div className='imageSizeBox'>
                <p className='anchoinforme'>Altura: </p>
                <input className='inputsize'
                  type="number"
                  id="imageHeight"
                  value={imageHeight}
                  onChange={(e) => setImageHeight(e.target.value)}
                />
              </div>
            </div>
            <div className='botonesAlineacion'>
              <button onClick={() => setHeaderImagePosition("left")}><BiAlignLeft /></button>
              <button onClick={() => setHeaderImagePosition("center")}><BiAlignMiddle /></button>
              <button onClick={() => setHeaderImagePosition("right")}><BiAlignRight /></button>
            </div>
          </div>
          <div className='pageNumber'>
            <input
              type="checkbox"
              id="showPageNumbers"
              checked={showPageNumbers}
              onChange={(e) => setShowPageNumbers(e.target.checked)}
            />
            <p>Mostrar numeración de página</p>
            {showPageNumbers && (
              <div className='botonesAlineacion'>
                <button onClick={() => handlePageNumberPositionChange("left")} disabled={headerImagePosition === "left"}><BiAlignLeft /></button>
                <button onClick={() => handlePageNumberPositionChange("center")} disabled={headerImagePosition === "center"}><BiAlignMiddle /></button>
                <button onClick={() => handlePageNumberPositionChange("right")} disabled={headerImagePosition === "right"}><BiAlignRight /></button>
              </div>
            )}
          </div>
          <div className='pageNumber'>
            <p className='anchoinforme'>Texto: </p>
            <input type='text' value={headerText} onChange={(e) => setHeaderText(e.target.value)} />
            <div className='botonesAlineacion'>
              <button onClick={() => setHeaderTextPosition("left")} disabled={headerImagePosition === "left"}><BiAlignLeft /></button>
              <button onClick={() => setHeaderTextPosition("center")} disabled={headerImagePosition === "center"}><BiAlignMiddle /></button>
              <button onClick={() => setHeaderTextPosition("right")} disabled={headerImagePosition === "right"}><BiAlignRight /></button>
              <button onClick={() => toggleBold()}><strong>B</strong></button> {/* Botón para negrita */}
              <button onClick={() => toggleItalic()}><em>I</em></button> {/* Botón para cursiva */}
            </div>
          </div>
        </div>
        <div className='headerinforme'>
          <h2>Footer</h2>
          <div className='boxheader'>
            <div
              onDrop={handleDropFooter}
              onDragOver={handleDragOver}
              style={{
                border: '2px dashed #ccc',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                width: 'calc(20rem)'
              }}
              onClick={() => document.getElementById('footerImageUpload').click()}
            >
              {footerImage ? (
                <img src={footerImage} alt="Footer" style={{ maxWidth: `${imageWidth}%`, maxHeight: `${imageHeight}px` }} />
              ) : (
                <p>Arrastra una imagen aquí o haz clic para seleccionar una</p>
              )}
              <input
                type="file"
                id="footerImageUpload"
                style={{ display: 'none' }}
                onChange={handleImageUploadFooter}
              />
            </div>
            <div className='imageSize'>
              <div className='imageSizeBox'>
                <p className='anchoinforme'>Ancho: </p>
                <input className='inputsize'
                  type="number"
                  id="imageWidth"
                  value={imageWidthFooter}
                  onChange={(e) => setImageWidthFooter(e.target.value)}
                />
              </div>
              <div className='imageSizeBox'>
                <p className='anchoinforme'>Altura: </p>
                <input className='inputsize'
                  type="number"
                  id="imageHeight"
                  value={imageHeightFooter}
                  onChange={(e) => setImageHeightFooter(e.target.value)}
                />
              </div>
            </div>
            <div className='botonesAlineacion'>
              <button onClick={() => setFooterImagePosition("left")}><BiAlignLeft /></button>
              <button onClick={() => setFooterImagePosition("center")}><BiAlignMiddle /></button>
              <button onClick={() => setFooterImagePosition("right")}><BiAlignRight /></button>
            </div>
          </div>
          <div className='pageNumber'>
            <input
              type="checkbox"
              id="showPageNumbers"
              checked={showPageNumbersFooter}
              onChange={(e) => setShowPageNumbersFooter(e.target.checked)}
            />
            <p>Mostrar numeración de página</p>
            {showPageNumbersFooter && (
              <div className='botonesAlineacion'>
                <button onClick={() => handlePageNumberPositionChange("left")} disabled={headerImagePosition === "left"}><BiAlignLeft /></button>
                <button onClick={() => handlePageNumberPositionChange("center")} disabled={headerImagePosition === "center"}><BiAlignMiddle /></button>
                <button onClick={() => handlePageNumberPositionChange("right")} disabled={headerImagePosition === "right"}><BiAlignRight /></button>
              </div>
            )}
          </div>
          <div className='pageNumber'>
            <p className='anchoinforme'>Texto: </p>
            <input type='text' value={footerText} onChange={(e) => setFooterText(e.target.value)} />
            <div className='botonesAlineacion'>
              <button onClick={() => setFooterTextPosition("left")} disabled={footerImagePosition === "left"}><BiAlignLeft /></button>
              <button onClick={() => setFooterTextPosition("center")} disabled={footerImagePosition === "center"}><BiAlignMiddle /></button>
              <button onClick={() => setFooterTextPosition("right")} disabled={footerImagePosition === "right"}><BiAlignRight /></button>
              <button onClick={() => toggleBold()}><strong>B</strong></button> {/* Botón para negrita */}
              <button onClick={() => toggleItalic()}><em>I</em></button> {/* Botón para cursiva */}
            </div>
          </div>
        </div>

        <div>
          <h2>Portada</h2>
          <div className='pageNumber'>
          <input
              type="checkbox"
              id="showPageNumbers"
              checked={showPortada}
              onChange={(e) => setShowPortada(e.target.checked)}
            />
            <p>Añadir portada</p>
            </div>
        </div>
      </div>
      }

      {flechasNav === 'derecha' &&

        <div className='contenido'>
          <div className='boxSelectInforme'>
            <div className='selectinforme'>
              <label>{periodo}: </label>
                <select onChange={handleFiltroChange} value={filtroSeleccionado}>
                  <option value="">- {selectFecha} -</option>
                  <option value="Hoy">{hoy}</option>
                  <option value="2 días">2 {dia}</option>
                  <option value="1 semana">1 {semana}</option>
                  <option value="1 mes">1 {mes}</option>
                  <option value="1 año">1 {año}</option>
                </select>
            </div>
            <div>
              <label>{canal}:</label>
              <select value={canalAliasSeleccionado} onChange={handleCanalAliasChange}>
        {canalesAlias.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
            </div>
          </div>

          <h2>POSICIÓN 1</h2>
          <div>
            <select onChange={(e) => handlePositionChange1(e.target.value)}>
              <option value=''>-</option>
              <option value='Tabla'>Tabla</option>
              <option value='Grafico'>Gráfico</option>
              <option value='Medio'>Valor medio</option>
              <option value='ultimo'>Último valor</option>
            </select>
          </div>


          <h2>POSICIÓN 2</h2>
          <div>
            <select onChange={(e) => handlePositionChange2(e.target.value)}>
              <option value=''>-</option>
              <option value='Tabla'>Tabla</option>
              <option value='Grafico'>Gráfico</option>
              <option value='Medio'>Valor medio</option>
              <option value='ultimo'>Último valor</option>
            </select>
          </div>

          <h2>POSICIÓN 3</h2>
          <div>
            <select onChange={(e) => handlePositionChange3(e.target.value)}>
              <option value=''>-</option>
              <option value='Tabla'>Tabla</option>
              <option value='Grafico'>Gráfico</option>
              <option value='Medio'>Valor medio</option>
              <option value='ultimo'>Último valor</option>
            </select>
          </div>

          <h2>POSICIÓN 4</h2>
          <div>
            <select onChange={(e) => handlePositionChange4(e.target.value)}>
              <option value=''>-</option>
              <option value='Tabla'>Tabla</option>
              <option value='Grafico'>Gráfico</option>
              <option value='Medio'>Valor medio</option>
              <option value='ultimo'>Último valor</option>
            </select>
          </div>
        </div>

      }

      <div
        id="pdfContent"
        style={{
          flex: 1,
          padding: '20px',
          border: '1px solid #ddd',
          height: pageSizes[pageSize].height / 3.7795275591, // Convert pixels back to mm
          width: pageSizes[pageSize].width / 3.7795275591, // Convert pixels back to mm
          overflowY: 'auto',
          position: 'relative'
        }}
      >
        <div>
        {showPortada && (
    <div style={{ marginBottom: '20px' , height: pageSizes[pageSize].height / 3.7795275591, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <h1 className='portadaInforme'>Informe Estación</h1>
    </div>
  )}

  {/* Separador entre la portada y el contenido principal */}
  {showPortada && (
    <hr style={{ border: 'none', borderBottom: '1px dashed #000', marginBottom: '20px' }} />
  )}
  <div style={{ pageBreakBefore: showPortada ? 'auto' : 'always' }}>
  <div style={{ display: 'flex', justifyContent: headerImagePosition, height: pageSizes[pageSize].height / 3.7795275591 }}>
          {headerImage && (
            <img src={headerImage} alt="Header" style={{ maxWidth: `${imageWidth}%`, maxHeight: `${imageHeight}px` }} />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: headerTextPosition }}>
          {headerText && (
            <div style={{ fontWeight: isBold(headerText) ? 'bold' : 'normal', fontStyle: isItalic(headerText) ? 'italic' : 'normal' }}>
              {renderFormattedText(headerText)}
            </div>
          )}
        </div>
        {showPageNumbers && (
          <div style={{
            textAlign: pageNumberPosition,
            position: 'absolute',
            top: 0,
            width: '100%',
            textAlign: pageNumberPosition === 'left' ? 'left' : (pageNumberPosition === 'right' ? 'right' : 'center')
          }}>
            1 de {totalPages}
          </div>
        )}
      {renderContentByPosition(posicion1)}
      {renderContentByPosition(posicion2)}
      {renderContentByPosition(posicion3)}
      {renderContentByPosition(posicion4)}
        <div
          id="contentElement"
          className="ql-container ql-snow"
          style={{ border: 'none', height: '100%', overflowY: 'auto' }}
          dangerouslySetInnerHTML={{ __html: editorContent }}
        />
        <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: footerImagePosition }}>
          {footerImage && (
            <img src={footerImage} alt="Footer" style={{ maxWidth: `${imageWidthFooter}%`, maxHeight: `${imageHeightFooter}px` }} />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: footerTextPosition }}>
          {footerText && (
            <div style={{ fontWeight: isBold(footerText) ? 'bold' : 'normal', fontStyle: isItalic(footerText) ? 'italic' : 'normal' }}>
              {renderFormattedText(footerText)}
            </div>
          )}
        </div>
        {showPageNumbersFooter && (
          <div style={{
            textAlign: pageNumberPosition,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            textAlign: pageNumberPosition === 'left' ? 'left' : (pageNumberPosition === 'right' ? 'right' : 'center')
          }}>
            1 de {totalPages}
          </div>
        )}
  </div>
</div>

 

      </div>
      
    </div>
    <div className='botonesInf'>
    <div className='botonNavInforme'>
          <button className='botonDesplazamientoInforme' onClick={() => setFlechasNav('izquierda')}><BsArrowLeftShort/> </button>
          <button className='botonDesplazamientoInforme' onClick={() => setFlechasNav('derecha')}><BsArrowRightShort/></button>
      </div>
      <div className='botonNavInforme'>
    <button onClick={handleExportToPDFinforme}>Exportar a PDF</button>
    <button onClick={handleExportToWord}>Exportar a Word</button>
    </div>
  </div>
  </div>
</Modal>

             </div>


)}
  <Footer/>
    </div>
  );
}

export default Datos;
