import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../styles/Navbar.css';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import { FaRegUserCircle, FaSearch } from 'react-icons/fa';
import { BiMenu } from 'react-icons/bi';
import espana from '../assets/flags/Spanish_flag.png';
import inglaterra from '../assets/flags/Englang_flag.svg.png';
import alemania from '../assets/flags/Germany_flag.svg.png';
import portugal from '../assets/flags/Portugal_flag.svg.png';
import { useNavigate, Link } from 'react-router-dom'; // Importa Link aquí
import { LanguageContext } from '../Components/LanguajeContext';
import Hamburguer from './Hamburguer';

function NavbarExample() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogOut = () => {
    navigate('/Information');
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang); // Guardar el idioma seleccionado en localStorage con una clave
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language'); // Obtener el idioma almacenado
    if (storedLanguage) {
      setLanguage(storedLanguage); // Establecer el idioma si está guardado
    }
  }, [setLanguage]); // Se ejecuta solo una vez al montar el componente

  const getNavLinkText = (lang) => {
    switch (lang) {
      case 'es':
        return 'Inicio';
      case 'en':
        return 'Home';
      case 'de':
        return 'Startseite';
      case 'pt':
        return 'Início';
      default:
        return 'Home';
    }
  };

  return (
    <div className='div-navbar'>
      <div className='idiomas-div'>
        <img src={logo} width="130" height="auto" alt="logo" className='logo'/>
        <div className='idiomas'>
          <img
            src={espana}
            alt="España"
            width="30"
            height="15"
            className={`bandera ${language === 'es' ? 'bandera-seleccionada' : ''}`}
            onClick={() => changeLanguage('es')}
          />
          <img
            src={inglaterra}
            alt="English"
            width="30"
            height="15"
            className={`bandera ${language === 'en' ? 'bandera-seleccionada' : ''}`}
            onClick={() => changeLanguage('en')}
          />
          <img
            src={alemania}
            alt="Alemania"
            width="30"
            height="15"
            className={`bandera ${language === 'de' ? 'bandera-seleccionada' : ''}`}
            onClick={() => changeLanguage('de')}
          />
          <img
            src={portugal}
            alt="Portugal"
            width="30"
            height="15"
            className={`bandera ${language === 'pt' ? 'bandera-seleccionada' : ''}`}
            onClick={() => changeLanguage('pt')}
          />
        </div>
      </div>
      <div className={`navbar-border ${isMenuOpen ? 'menu-open' : ''}`}>
        <Navbar expand="md" className="bg-body-tertiary">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <div className='navbar-items'>
              <div className='navbar-localizador'>
                <button className="boton-bimenu" onClick={toggleMenu}>
                  <BiMenu className='navbar-hamburguer' />
                </button>
                <span className='navbar-separator'> / </span>
                <Link to='/Home' className='indice'>
                  {getNavLinkText(language)}
                </Link>
              </div>
              <div className='logout'>
                <div className='input-with-icon'>
                  <input className='inputNavbar' />
                  <FaSearch className='searchIcon' />
                </div>
                <FaRegUserCircle className='user' onClick={handleLogOut}/>
              </div>
            </div>
          </Nav>
        </Navbar>
      </div>
      <Hamburguer isOpen={isMenuOpen} />
    </div>
  );
}

export default NavbarExample;
