import React, { useState, useEffect } from 'react';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import '../styles/registro.css';
import { usernameType, RegistroUser, existeUsername, existeEmail } from '../api/loginApi.js';
import { useNavigate } from 'react-router-dom';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

function Registro() {
  const [tipos, setTipos] = useState([]);
  const [aceptoCondiciones, setAceptoCondiciones] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [rol, setRol] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Estado para controlar si las contraseñas coinciden
  const [formError, setFormError] = useState(null); // Estado para controlar errores de formulario
  const navigate = useNavigate();

  // Verifica si la contraseña cumple con ciertos criterios de seguridad
  const isPasswordSecure = (pass) => {
    return pass.length >= 8 && /[A-Z]/.test(pass) && /[a-z]/.test(pass) && /[0-9]/.test(pass) && /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(pass);
  };

  // Genera una contraseña aleatoria
  const generateRandomPassword = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}[]:;<>,.?~\\-';
    let newPassword = '';
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * randomChars.length);
      newPassword += randomChars[randomIndex];
    }
    return newPassword;
  };

  const handleSignUp = () => {
    if (!aceptoCondiciones) {
      alert('Debes aceptar las condiciones de uso para registrarte.');
      return; // No se permite continuar con el registro
    }

    // Validar campos obligatorios
    if (!username || !password || !email || !rol || !nombre || !apellidos || !confirmPassword) {
      setFormError('Por favor, completa todos los campos.');
      return;
    }

    // Validar si las contraseñas coinciden
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    let finalPassword = password;

    // Verificar si la contraseña es segura
    if (!isPasswordSecure(password)) {
      const confirmGenerate = window.confirm('La contraseña no es segura, debe contener mayusculas, minusculas, numeros y simbolos. ¿Deseas generar una contraseña automáticamente?');
      if (confirmGenerate) {
        finalPassword = generateRandomPassword();
        alert('Se ha generado una nueva contraseña automáticamente.');
      } else {
        return; // No se permite continuar con el registro
      }
    }

    // Si todo está bien, proceder con el registro
    const registro = RegistroUser(username, finalPassword, email, rol, nombre, apellidos);
    alert('Usuario registrado. Espere la confirmación del servicio técnico');
    navigate('/');
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const fetchTipos = async () => {
      const response = await usernameType();
      setTipos(response);
    };

    fetchTipos();
  }, []);

  useEffect(() => {
    let timeoutId = null;
  
    if (username !== '') {
      timeoutId = setTimeout(() => {
        existeUsername(username)
          .then((response) => {
            if (response) {
              alert('El username ya existe, elija otro');
              setUsername('');
            }
            console.log('Username existe:', response);
          })
          .catch((error) => {
            console.error('Error al verificar username:', error);
          });
      }, 2000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [username]);

  useEffect(() => {
    let timeoutId = null;
  
    if (email !== '') {
      timeoutId = setTimeout(() => {
        existeEmail(email)
          .then((response) => {
            if (response) {
              alert('El email ya existe, elija otro');
              setEmail('');
            }
            console.log('Email existe:', response);
          })
          .catch((error) => {
            console.error('Error al verificar email:', error);
          });
      }, 2000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [email]);

  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='div-formulario'>
        <div className='div-tittleContact'>
          <div className='box'>
            <div className='tittle_register'>
              <h2>Sign Up:</h2>
            </div>
            <div className='registro-box'>
              <div className='nombre'>
                <input
                  className='input-registro'
                  placeholder='Nombre *'
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </div>
              <div className='nombre'>
                <input
                  className='input-registro'
                  placeholder='Apellidos *'
                  value={apellidos}
                  onChange={(e) => setApellidos(e.target.value)}
                />
              </div>
            </div>
            <div className='mail'>
              <input
                className='input-mail'
                placeholder='Mail *'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='registro-box'>
              <div className='nombre'>
                <input
                  className='input-registro'
                  placeholder='Username *'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className='nombre'>
                <select
                  className='select-type'
                  value={rol}
                  onChange={(e) => setRol(e.target.value)}
                >
                  <option value='' disabled defaultValue className='placeholderSelectType'>
                    Select a Type of User *
                  </option>
                  {tipos && tipos.map((item) => (
                    <option key={item.ROLNUM} value={item.ROLNUM} label={item.ROL}>
                      {item.ROL}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='registro-box'>
              <div className='nombre'>
                <input
                  className='input-registro'
                  placeholder='Password *'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className='eye-icon'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VscEyeClosed /> : <VscEye />}
                </span>
              </div>
              <div className='nombre'>
                <input
                  className='input-registro'
                  placeholder='Password confirmation *'
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className='eye-icon'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <VscEyeClosed /> : <VscEye />}
                </span>
              </div>
            </div>
            {formError && (
              <div className="recordarme">
                <p style={{ color: 'red', textAlign: 'center' }}>
                  {formError}
                </p>
              </div>
            )}
            {!passwordsMatch && (
              <div className="recordarme">
                <p style={{ color: 'red', textAlign: 'center' }}>
                  Las contraseñas no coinciden. Por favor, inténtalo de nuevo.
                </p>
              </div>
            )}
            <div className="recordarme">
              <input
                type="checkbox"
                id="remember"
                name="remember"
                checked={aceptoCondiciones}
                onChange={(e) => setAceptoCondiciones(e.target.checked)}
              />
              <label className='condicionesUso' onClick={openModal}>
                He leído y acepto las <span className="linkCondiciones"> condiciones de uso</span>
              </label>
            </div>

            <div id="myModal" className={`modal ${modalVisible ? 'show' : ''}`}>
              <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <p>
  <strong>Condiciones de Uso</strong><br />
  Bienvenido a nuestro sitio web. Al acceder y utilizar este sitio, aceptas estar legalmente vinculado por las siguientes condiciones de uso. Si no estás de acuerdo con alguna parte de estas condiciones, por favor no utilices este sitio.
</p>
<ol>
    <strong>1. Uso del Sitio</strong><br />
    Este sitio web tiene como objetivo proporcionar información sobre los dispositivos instalados por Dosatornic Iberia, S.L en España. La información presentada aquí es solo para propósitos informativos. Queda prohibido cualquier uso no autorizado de este sitio, incluyendo la extracción automática de datos u otras actividades que puedan interferir con el funcionamiento del sitio.<br/><br/>
    <strong>2. Propiedad de la Información</strong><br />
    Todos los datos y la información presentados en este sitio web son propiedad de Dosatornic Iberia, S.L. No está permitida la reproducción o distribución de esta información sin autorización previa por escrito.<br/><br/>
    <strong>3. Privacidad y Protección de Datos</strong><br />
    El uso de este sitio web está sujeto a nuestra Política de Privacidad, la cual describe cómo recopilamos, utilizamos y protegemos tus datos personales. Al utilizar este sitio, aceptas nuestro tratamiento de datos de acuerdo con dicha política.<br/><br/>
    <strong>4. Precisión de la Información</strong><br />
    Nos esforzamos por proporcionar información precisa y actualizada en este sitio web. Sin embargo, no garantizamos la exactitud o integridad de la información presentada. La información está sujeta a cambios sin previo aviso.<br/><br/>
    <strong>5. Enlaces a Terceros</strong><br />
    Este sitio puede incluir enlaces a sitios web de terceros. Estos enlaces son proporcionados únicamente para tu conveniencia. No tenemos control sobre el contenido de estos sitios y no asumimos responsabilidad alguna por el contenido o las políticas de privacidad de dichos sitios.<br/><br/>
    <strong>6. Limitación de Responsabilidad</strong><br />
    Este sitio web se proporciona "tal cual", sin garantías de ningún tipo. En ningún caso seremos responsables por daños directos, indirectos, incidentales, especiales, consecuentes u otros daños derivados del uso de este sitio o de la imposibilidad de usarlo.<br/><br/>
    <strong>7. Ley Aplicable y Jurisdicción</strong><br />
    Estas condiciones de uso se rigen por las leyes de España. Cualquier disputa que surja en relación con este sitio web estará sujeta a la jurisdicción exclusiva de los tribunales de España.<br/><br/>
    <strong>8. Modificaciones</strong><br />
    Nos reservamos el derecho de modificar estas condiciones de uso en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en este sitio web. Se te recomienda revisar periódicamente estas condiciones para estar al tanto de cualquier cambio.<br/><br/>
    <strong>9. Contacto</strong><br />
    Si tienes alguna pregunta o inquietud sobre estas condiciones de uso, por favor contáctanos a <a href="mailto:info@dosatroniciberia.es">info@dosatroniciberia.es</a>.
</ol>
              </div>
            </div>
            <div className='div-button-sign-up'>
              <button className='botonSignUp' onClick={handleSignUp}>Sign Up</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registro;
