import React, { useState, useEffect } from 'react';
import NavbarExample from './Navbar';
import { getAlarmas, getAlias, getDatos } from '../api/loginApi';
import '../styles/Alarmas.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FaExclamationTriangle } from 'react-icons/fa';
import { BsArrowsVertical, BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Footer from './Footer';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function renderValue(value) {
  if (value === '$$_ZEUS__$$') {
    return 'Sistemas';
  }
  return value;
}

function Alarmas() {
  const [isOpen, setIsOpen] = useState(false);
  const [alarmas, setAlarmas] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [aliasList, setAliasList] = useState([]);
  const [datosList, setDatosList] = useState([]);
  const [order, setOrder] = useState({
    fecha: undefined,
    fechaReconocida: undefined,
    fechaCerrada: undefined
  });
  const [selectedRow, setSelectedRow] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchAlarmas();
    fetchAliasList();
  }, []);

  const fetchAlarmas = async () => {
    try {
      const username = localStorage.getItem('username');
      const token = localStorage.getItem('token');
      const data = await getAlarmas(username, token);
      setAlarmas(data);
    } catch (error) {
      console.error('Error al obtener alarmas:', error);
    }
  };

  const fetchAliasList = async () => {
    try {
      const token = localStorage.getItem('token');
      const aliasData = await getAlias(token);
      setAliasList(aliasData);
      const datosData = await getDatos(token);
      setDatosList(datosData);
    } catch (error) {
      console.error('Error al obtener la lista de alias:', error);
    }
  };

  const handleSort = (column) => {
    const isDateColumn = ['fecha', 'fechaReconocida', 'fechaCerrada'].includes(column);
    
    let newOrder;
    if (order[column] === 'asc') {
      newOrder = 'desc';
    } else {
      newOrder = 'asc';
    }
  
    setOrder({ ...order, [column]: newOrder });
  
    const sortedAlarmas = [...alarmas].sort((a, b) => {
      if (isDateColumn) {
        const dateA = new Date(a[column]).getTime();
        const dateB = new Date(b[column]).getTime();
        return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
      } else {
        const valueA = a[column];
        const valueB = b[column];
        return newOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    });
  
    setAlarmas(sortedAlarmas);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderFechaReconocida = (fecha) => {
    if (!fecha) {
      return <FaExclamationTriangle color="red" />;
    }
    return formatDate(fecha);
  };

  const renderFechaCerrada = (fecha) => {
    if (!fecha) {
      return <FaExclamationTriangle color="red" />;
    }
    return formatDate(fecha);
  };

  const getSortIndicator = (column) => {
    if (order[column] === 'asc') {
      return <BsArrowUpShort />;
    } else if (order[column] === 'desc') {
      return <BsArrowDownShort />;
    } else {
      return <BsArrowsVertical />;
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedRow(index);
  };

  const handleCerrarAlarma = () => {
    console.log('Cerrar alarma seleccionada');
  };

  return (
    <div>
      <NavbarExample toggleMenu={toggleMenu} />
      <Accordion>
        <AccordionSummary className='accordion-filtros'
          expandIcon={<BsArrowDownShort className='filtros' />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {!isOpen && <Typography className='filtros'>Filtrar Tabla</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', justifyContent: 'right' }}>
          <div className='div-select-filtro'>
            <select className='seleccionador'>
              <option className='option-select' value="">Seleccionar Alias</option>
              {aliasList.map((alias, index) => (
                <option className='option-select' key={index} value={alias.ALIAS}>{alias.ALIAS}</option>
              ))}
            </select>

            <select className='seleccionador'>
              <option className='option-select' value="">Seleccionar Dato</option>
              {datosList.map((dato, index) => (
                <option className='option-select' key={index} value={dato.DATO}>{dato.DATO}</option>
              ))}
            </select>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className='tabla_alarmas'>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={alarmas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper} style={{ width: '90%', borderRadius: '10px' }}>
          <Table aria-label="alarmas table">
            <TableHead>
              <TableRow>
                <TableCell className='head-alarmas'/>
                <TableCell className='head-alarmas'>
                  Alias
                </TableCell>
                <TableCell className='head-alarmas'>
                  Grupo
                </TableCell>
                <TableCell className='head-alarmas' onClick={() => handleSort('fecha')}>
                  Fecha {getSortIndicator('fecha')}
                </TableCell>
                <TableCell className='head-alarmas'>
                  Dato
                </TableCell>
                <TableCell className='head-alarmas' onClick={() => handleSort('fechaReconocida')}>
                  Fecha Reconocida {getSortIndicator('fechaReconocida')}
                </TableCell>
                <TableCell className='head-alarmas' onClick={() => handleSort('fechaCerrada')}>
                  Fecha Cerrada {getSortIndicator('fechaCerrada')}
                </TableCell>
                <TableCell className='head-alarmas'>
                  Reconocida Por
                </TableCell>
                <TableCell className='head-alarmas'>
                  Cerrada Por
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarmas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((alarma, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRow === index}
                      onChange={() => handleCheckboxChange(index)}
                      style={{ color: selectedRow === index ? '#003f5e' : 'inherit' }}
                    />
                  </TableCell>
                  <TableCell>{alarma.ALIAS}</TableCell>
                  <TableCell>{alarma.GRUPO}</TableCell>
                  <TableCell>{formatDate(alarma.FECHA)}</TableCell>
                  <TableCell>{alarma.DATO}</TableCell>
                  <TableCell>{renderFechaReconocida(alarma.FRECONOCIDA)}</TableCell>
                  <TableCell>{renderFechaCerrada(alarma.FCERRADA)}</TableCell>
                  <TableCell>{renderValue(alarma.RECONOCIDA)}</TableCell>
                  <TableCell>{renderValue(alarma.CERRADA)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={alarmas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {selectedRow !== null && (
          <Button
            onClick={handleCerrarAlarma}
            className='boton-cerrar-alarma'
          >
            Cerrar Alarma
          </Button>
        )}
      </div>
      <Footer/>
    </div>
  );
}

export default Alarmas;
