import React, { useEffect, useRef, useState } from 'react';
import '../styles/PressureGauge.css';

function PressureGauge({ value, min, max, unidades }) {
  const gaugeFillRef = useRef(null);
  const needleRef = useRef(null);
  const gaugeValueRef = useRef(null);
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {//esta es para la animacion
    let animationFrameId;
    const animate = () => {
      setCurrentValue((prevValue) => {
        if (prevValue !== value) {
          cancelAnimationFrame(animationFrameId);
          return value;
        }
        const newValue = prevValue + (value - prevValue) * 0.05;
        console.log('newValue');
        console.log(newValue);
        animationFrameId = requestAnimationFrame(animate);
        return newValue !== value ? value : newValue;
      });
    };

    setCurrentValue(0); // Reset current value to 0 when the value prop changes
    animationFrameId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameId);
  }, [value]);

  useEffect(() => {
    const adjustedValue = Math.max(min, Math.min(max, currentValue));//media del valor minimo y maximo
    console.log('adjustedValue');
    console.log(adjustedValue);
    const range = max - min;//rango en el que se mueve
    console.log('range');
    console.log(range);
    const angle = -98 + ((value * 180) / max);//angulo para la aguja ((media - valor minimo)/rango)/180
    console.log('angle');
    console.log(angle);
    const dashArrayValue = ((value * 125) / max);
    console.log('dashArrayValue');
    console.log(dashArrayValue);

    if (gaugeFillRef.current) {
      gaugeFillRef.current.setAttribute('stroke-dasharray', `${dashArrayValue}, 180`);
      console.log('gaugeFillRef');
      console.log(gaugeFillRef);
    }

    if (gaugeValueRef.current) {
      gaugeValueRef.current.textContent = adjustedValue.toFixed(2);
    }

    if (needleRef.current) {
      // Adjusted the angle to match the blue indicator
      needleRef.current.style.transform = `rotate(${angle}deg)`;
    }
  }, [currentValue, min, max]);

  return (
    <div className="gauge-container">
      <svg id="gauge" viewBox="0 0 100 50">
        <path
          className="gauge-bg"
          d="M10,40 A30,30 0 0,1 90,40"
          fill="none"
          stroke="#e6e6e6"
          strokeWidth="20"
        ></path>
        <path
          ref={gaugeFillRef}
          className="gauge-fill"
          d="M10,40 A30,30 0 0,1 90,40"
          fill="none"
          stroke="#01284F"
          strokeWidth="5"
          strokeDasharray="0, 180"
        ></path>
        <line
          ref={needleRef}
          id="needle"
          x1="50"
          y1="40"
          x2="55"
          y2="0"
          stroke="black"
          strokeWidth="0.5"
          style={{ transformOrigin: '50% 80%', transition: 'transform 0.5s ease-in-out' }}
        ></line>
        <text x="10" y="45" textAnchor="middle" className="gauge-label-min">{Number(min).toFixed(3)}</text>
        <text x="90" y="45" textAnchor="middle" className="gauge-label-max">{Number(max).toFixed(3)}</text>
      </svg>
      <div className="gauge-value-container">
        <span ref={gaugeValueRef} className="gauge-value">{Number(currentValue).toFixed(2)} {unidades}</span> 
      </div>
    </div>
  );
}

export default PressureGauge;
