import React, { useContext } from 'react';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import videoGif from '../assets/video.gif';
import videoWeb from '../assets/videoweb.gif';
import '../styles/Home.css';
import NavbarExample from './Navbar';
import { BiSolidNotification, BiSolidBell } from 'react-icons/bi';
import { FaChartLine } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../Components/LanguajeContext'; // Importar el contexto del idioma
import Footer from './Footer.js';

const translations = {
  es: {
    title: " Gestión del Agua",
    notifications: "Notificaciones",
    alarms: "Alarmas",
    data: "Datos",
  },
  en: {
    title: " Water Management",
    notifications: "Notifications",
    alarms: "Alarms",
    data: "Data",
  },
  de: {
    title: " Wasserverwaltung",
    notifications: "Benachrichtigungen",
    alarms: "Alarme",
    data: "Daten",
  },
  pt: {
    title: " Gestão da Água",
    notifications: "Notificações",
    alarms: "Alarmes",
    data: "Dados",
  },
};

function Home() {
  const { language } = useContext(LanguageContext); // Utilizar el contexto del idioma
  const navigate = useNavigate();
  const { title, notifications, alarms, data } = translations[language]; // Obtener las traducciones según el idioma

  const handletoNotificaciones = () => {
    navigate('/Notificacion');
  };

  const handletoAlarmas = () => {
    navigate('/Alarmas');
  };

  const handletoDatos = () => {
    navigate('/Datos');
  };

  return (
    <div>
      <NavbarExample />
      <div className="gif-container">
        <img src={videoWeb} alt="Video GIF" className='video'/>
      </div>
      <div className='dosa'>
        <h2 className='h2dosa'><span className='titulodosa'>Dosatronic</span> | {title}</h2>
      </div>
      <div className='buttonhome'>
        <div className='divbuttonhome'>
          <button className='botonesinicio' onClick={handletoNotificaciones}><BiSolidNotification/></button>
          <h4 className='h4home'>{notifications}</h4>
        </div>
        <div className='divbuttonhome'>
          <button className='botonesinicio' onClick={handletoAlarmas}><BiSolidBell/></button>
          <h4 className='h4home'>{alarms}</h4>
        </div>
        <div className='divbuttonhome'>
          <button className='botonesinicio' onClick={handletoDatos}><FaChartLine/></button>
          <h4 className='h4home'>{data}</h4>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
