import { LanguageContext } from './LanguajeContext.js';
import React, { useState, useContext } from 'react';
import '../styles/footer.css';
import { Modal, Button } from 'react-bootstrap';
import { BiCheck } from "react-icons/bi";


const translations = {
    es: {
      footer: {
        company: "DOSATRONIC IBERIA S.L",
        address: "C/Valles, 7",
        city: "88213 Ravensburg",
        country: "Alemania",
        Telefono1: "+34 93 517 20 08",
        Fax: "",
        mail: "info@dosatroniciberia.es",
        condiciones: "TÉRMINOS Y CONDICIONES GENERALES",
        aviso: "AVISO LEGAL", 
        proteccion: "PROTECCIÓN DE DATOS",
        titulocondiciones: "TÉRMINOS Y CONDICIONES GENERALES",
        punto1: "I. Ámbito de aplicación",
        parrafo1punto1: "1. Las presentes condiciones generales de entrega se aplicarán exclusivamente; las condiciones diferentes o contrarias a las del cliente solo se aplicarán si el proveedor las ha aprobado por escrito.",
        parrafo2punto1: "2. Las presentes condiciones generales de entrega se aplicarán también a pedidos posteriores y a suministros de piezas de repuesto sin necesidad de una nueva indicación de este hecho.",
        parrafo3punto1: "3. Los complementos y representaciones, así como las modificaciones o adiciones a un contrato celebrado por escrito o por fax/correo electrónico, deberán realizarse por escrito.",
        punto2: "II. Oferta y confirmación del pedido",
        parrafo1punto2: "1. Las ofertas solo serán vinculantes si en ellas se indica un plazo para su aceptación. Para que sean jurídicamente vinculantes, las ofertas deberán contar con la confirmación por escrito del proveedor.",
        parrafo2punto2: "2. El proveedor se reserva todos los derechos de propiedad y derechos de autor sobre figuras, dibujos, cálculos y demás documentación de la oferta e información similar, tanto física como no física, también en formato electrónico; estos solo podrán ser comunicados a terceros con el consentimiento por escrito del proveedor y deberán ser devueltos inmediatamente al proveedor si lo solicita si no se le adjudica ningún pedido.",
        punto3: "III: Alcance de los suministros y servicios",
        parrafo1punto3: "1. Los suministros y servicios se determinan en base a las declaraciones escritas mutuas. A falta de tales declaraciones, será determinante la confirmación escrita del pedido del proveedor. En el caso de los contratos de simple compraventa, las condiciones de suministro acordadas se interpretarán de acuerdo con los INCOTERMS vigentes en el momento de la celebración del contrato.",
        parrafo2punto3: "2. Los datos incluidos en folletos, catálogos o documentación técnica general sólo serán vinculantes si se hace referencia a ellos por escrito.",
        parrafo3punto3: "3. Los costes de un montaje y ensamblaje acordados, incluidos todos los costes adicionales necesarios, como los gastos de viaje o los costes de transporte de herramientas o equipaje personal, serán remunerados por separado por el cliente, a menos que se haya acordado otra cosa.",
        parrafo4punto3: "4. Si el alcance del suministro incluye software, se concede al cliente un derecho no exclusivo de uso del software. El cliente puede copiar o editar el software sólo en el ámbito legalmente permitido.",
        parrafo5punto3: "5. Se permiten entregas parciales, siempre que sean razonables para el cliente, teniendo en cuenta los intereses tanto del proveedor como del cliente.",
        parrafo6punto3: "6. En caso de entregas al extranjero, la obligación del proveedor quedará sujeta a que se concedan los permisos de exportación necesarios.",
        punto4: "IV. Precios y condiciones de pago.",
        parrafo1punto4: "1. Todos los precios se expresan en euros, salvo que se indique lo contrario. Se aplican a operaciones de entrega “ex works” (EXW), sin embalaje.",
        parrafo2punto4: "2. Los precios no incluyen ningún impuesto de facturación. Este impuesto se detalla por separado en la factura en el importe legal aplicable en la fecha de facturación.",
        parrafo3punto4: "3. La deducción de descuentos requiere un acuerdo especial por escrito.",
        parrafo4punto4: "4. A menos que se indique lo contrario en la confirmación del pedido, el precio de venta deberá pagarse a los 30 días a partir de la fecha de la factura sin ninguna deducción.",
        parrafo5punto4: "5. Si el cliente no cumple con la fecha de pago, deberá pagar intereses de demora por un importe de 8 puntos porcentuales por encima del tipo de interés vigente de conformidad con el § 247 del Código Civil alemán a partir de la fecha de vencimiento. Queda reservado el pago de daños y perjuicios adicionales.",
        parrafo6punto4: "6. A menos que se haya acordado lo contrario, la entrega de mercancías para entregas en el extranjero se realizará con la condición de que el cliente emita una carta de crédito comercial irrevocable a favor del proveedor y sea confirmada por una institución bancaria alemana.",
        parrafo7punto4: "7. En caso de demora en el pago, el proveedor podrá suspender el cumplimiento de sus propias obligaciones hasta recibir el pago total, notificándolo por escrito al cliente.",
        parrafo8punto4: "8. El cliente sólo podrá compensar créditos o hacer valer un derecho de retención, siempre que estos sean indiscutibles o hayan llegado a ser inapelables.",
        punto5: "V. Plazos de entrega o prestación de servicios",
        parrafo1punto5: "1. En lo que respecta a los plazos, serán determinantes las declaraciones escritas mutuas o, en su defecto, la confirmación escrita del pedido del proveedor. El plazo se considerará respetado siempre que se reciba a tiempo toda la documentación que deba proporcionar el cliente, se faciliten todos los permisos, autorizaciones, en particular los planos, y el cliente cumpla las condiciones de pago y otras obligaciones acordadas. Si no se cumplen estos requisitos a tiempo, el plazo se prolongará razonablemente; esto no se aplicará si el proveedor es responsable del retraso.",
        parrafo2punto5: "2. Si el incumplimiento de los plazos se debe a un caso de fuerza mayor, p. ej., movilización, guerra, motín o acontecimientos similares, p. ej., huelga o cierre patronal, los plazos acordados se prolongarán razonablemente.",
        parrafo3punto5: "3. Si el montaje y el ensamblaje no forman parte de los servicios acordados, el plazo se considerará respetado si la mercancía lista para su uso se envió o se recogió dentro del plazo. 4. Si el suministro se retrasa por causas imputables al cliente, el plazo se considerará cumplido en el momento de la notificación de la disponibilidad para el envío.",
        parrafo4punto5: "4. Si el proveedor es responsable del incumplimiento del plazo, el cliente, siempre que haya sufrido un daño real, podrá exigir una indemnización por retraso por cada semana completa de retraso de un máximo del 0,5 %, pero sin superar el 5 % del precio de la parte del suministro que no haya podido ponerse en funcionamiento debido al retraso. Las reclamaciones de indemnización del cliente que superen los límites estipulados en el punto 5.4 quedan excluidas en todos los casos de retraso en la entrega o en el servicio, incluso después de la expiración de cualquier período de gracia establecido para el proveedor. Esto no se aplicará en la medida en que exista responsabilidad obligatoria en casos de dolo, negligencia grave o lesiones personales; en este caso no se da una inversión de la carga de la prueba en perjuicio del cliente.",
        parrafo5punto5: "5. El derecho del cliente a rescindir el contrato después de la expiración ineficaz de un período de gracia para el proveedor no se verá afectado. El plazo de gracia, sin embargo, debe ser razonable y ascender a por lo menos cuatro semanas.",
        parrafo6punto5: "6. Si el envío o la entrega se demoran más de un mes después de la notificación de la disponibilidad para el envío a petición del cliente, se podrán cobrar al cliente gastos de almacenamiento por un importe del 0,5 % del precio de la mercancía entregada, pero sin superar un total del 5 %, por cada mes iniciado. Las partes del contrato tendrán la libertad de aportar pruebas de gastos de almacenamiento mayores o menores.",
        punto6: "VI. Pasaje de utilidad y riesgo; seguro; embalaje",
        parrafo1punto6: "1. El riesgo de las entregas y servicios prestados por el proveedor se transmite al cliente de la siguiente manera, incluso en caso de entregas a portes pagados.",
        parrafo1punto6a: "a) para entregas sin montaje ni montaje, incluso en caso de entregas parciales, si estas han sido enviadas o recogidas. Los envíos serán asegurados por el proveedor contra los riesgos habituales de transporte a petición y a expensas del cliente. Si existe tal seguro, el proveedor será notificado inmediatamente sobre cualquier daño a las mercancías en tránsito.",
        parrafo1punto6b: "b) para entregas con montaje o montaje el día de la aceptación en las operaciones del cliente o, si se acuerda, después de la operación de prueba perfecta.",
        parrafo2punto6: "2. Si el envío, la entrega, el inicio, la ejecución del montaje o la aceptación del montaje en las operaciones del cliente o la operación de prueba se retrasan por razones imputables al cliente o si el cliente retrasa la aceptación por otras razones, el riesgo se transmite al cliente.",
        parrafo3punto6: "3. El envío se realiza en principio en embalajes estándar del proveedor. Este último tendrá derecho a elegir los tipos de embalajes especiales que considere necesarios a criterio del proveedor. Los costes de estos embalajes correrán a cargo del cliente.",
        punto7: "VII. Montaje y ensamblaje",
        previopunto7: "El montaje, ensamblaje e instalación de los equipos y dispositivos del proveedor solo pueden ser realizados por especialistas, observando las directrices del proveedor y las normas técnicas aplicables. Si el montaje y/o ensamblaje los realiza el proveedor, se aplicarán las siguientes disposiciones, a menos que se acuerde otra cosa por escrito:",
        parrafo1punto7: "1. El cliente asumirá y proporcionará a tiempo y a su cargo:",
        parrafo1punto7a: "a) todos los trabajos de movimiento de tierras, construcción y otros trabajos auxiliares diferentes, incluidos los especialistas y el personal auxiliar necesarios, los materiales y las herramientas.",
        parrafo1punto7b: "b) los suministros y materiales como andamios, grúas y ascensores y otros dispositivos, combustibles, lubricantes y productos químicos necesarios para el montaje y la puesta en servicio.",
        parrafo1punto7c: "c) energía y agua en el lugar de uso, incluidas las conexiones, la calefacción y la iluminación,",
        parrafo1punto7d: "d) habitaciones suficientemente grandes, adecuadas, secas y con llave en el lugar de montaje para almacenar piezas de máquinas, fijaciones, materiales y herramientas, etc., y salas de trabajo y descanso adecuadas para el personal de montaje, incluidas las instalaciones sanitarias apropiadas. Para la protección de la propiedad del proveedor y del personal de montaje, el cliente también deberá tomar las medidas que normalmente tomaría para proteger su propia propiedad.",
        parrafo1punto7e: "e) ropa de protección y dispositivos de protección que sean necesarios debido a circunstancias especiales en el lugar de montaje.",
        parrafo2punto7: "2. Antes del inicio del trabajo de montaje, el cliente deberá proporcionar espontáneamente la información requerida sobre la posición de las conducciones subterráneas de energía, gas, agua o instalaciones similares, así como los datos requeridos sobre estática.",
        parrafo3punto7: "3. Antes del inicio del montaje o montaje, los complementos y objetos necesarios para comenzar el trabajo deben estar en el lugar de montaje o montaje y todos los preparativos previos al inicio de la instalación deben estar adelantados de tal manera que el montaje o montaje pueda comenzar según lo acordado y pueda realizarse sin interrupciones. Las rutas de acceso y el lugar de montaje o montaje deben estar nivelados y libres de cualquier objeto.",
        parrafo4punto7: "4. Si el montaje, montaje o puesta en servicio se retrasa por razones ajenas al proveedor, el cliente deberá asumir los costos del tiempo de espera y, además, los rastreos necesarios del proveedor o del personal de montaje en una cantidad adecuada.",
        parrafo5punto7: "5. Si una planta no puede ser instalada inmediatamente después de la entrega, el cliente será responsable de un almacenamiento adecuado de acuerdo con las pautas del proveedor.",
        parrafo6punto7: "6. El cliente deberá proporcionar al proveedor información semanal sobre la duración de las horas de trabajo del personal de montaje y deberá confirmar inmediatamente la finalización del montaje, ensamblaje o puesta en servicio.",
        parrafo7punto7: "7. La puesta en servicio sólo podrá ser realizada por técnicos reconocidos por el proveedor y según las instrucciones de éste. Los técnicos tendrán derecho a rechazar la puesta en servicio de la planta si las condiciones de funcionamiento que debe proporcionar el cliente no garantizan un funcionamiento correcto de la planta. El cliente correrá con los costes que se ocasionen al proveedor por el retraso en la puesta en servicio.",
        parrafo8punto7: "8. Si el proveedor solicita la aceptación de los suministros y servicios una vez finalizados, el cliente está obligado a hacerlo en el plazo de dos semanas. En caso contrario, la aceptación se considerará realizada. La aceptación también se considerará realizada si los productos y servicios entregados, incluso después de la finalización de una fase de prueba acordada, si la hubiera, se han puesto en uso.",
        punto8: "VIII. Garantía",
        parrafo1punto8: "1. Si los productos entregados o los servicios prestados por el proveedor resultan defectuosos porque no poseen la calidad acordada o porque no son adecuados para el uso acordado o habitual, el proveedor, a su discreción, reparará las piezas o servicios en cuestión o los entregará o prestará nuevamente sin costo dentro del plazo de prescripción, siempre que la causa del defecto ya existiera en el momento de la transmisión del riesgo.",
        parrafo2punto8: "2. Las reclamaciones por defectos materiales prescriben a los 12 meses. El plazo comienza a contar con la transmisión del riesgo (punto 6). Las disposiciones anteriores no se aplican en la medida en que la ley prescriba plazos más largos de conformidad con los §§438(1) n.º 2 del Código Civil alemán (bienes para edificaciones), §479(1) del Código Civil alemán (derecho de recurso) y §634a del Código Civil alemán (defectos estructurales). El plazo de garantía puede prolongarse hasta 60 meses en casos adecuados, siempre que el cliente celebre un contrato de mantenimiento por el período correspondiente.",
        parrafo3punto8: "3. El cliente deberá notificar inmediatamente los defectos al proveedor.",
        parrafo4punto8: "4. En caso de notificación de defectos, el cliente podrá retener los pagos en una cantidad que guarde una proporción razonable con los defectos materiales que se hayan producido. El cliente solo podrá retener los pagos si se presenta una notificación de defectos cuya justificación sea indudable. 6. Si la notificación de defecto se realiza de forma indebida, el proveedor tendrá derecho a exigir al cliente una compensación por los gastos en que haya incurrido. En primer lugar, el proveedor siempre tendrá la oportunidad de realizar el cumplimiento posterior en un plazo razonable. El cliente deberá conceder al proveedor el tiempo y la oportunidad necesarios para ello. Si el cliente se niega a ello, el proveedor quedará exento de responsabilidad por defectos.",
        parrafo5punto8: "5. Si el cumplimiento posterior fracasa, el cliente, sin perjuicio de posibles reclamaciones por daños y perjuicios, podrá rescindir el contrato o reducir la compensación. El cliente no podrá reclamar una compensación por gastos inútiles.",
        parrafo6punto8: "6. No se admitirán reclamaciones por defectos en caso de pequeñas desviaciones de la calidad acordada o estimada, de pequeñas alteraciones de la utilidad, de desgaste natural o de daños que se produzcan después de la transmisión del riesgo debidos a un manejo incorrecto o negligente, a un uso excesivo, a un material de funcionamiento inadecuado, a una construcción defectuosa, a un subsuelo inadecuado o a influencias externas especiales no previstas en el contrato, así como en caso de errores de software no reproducibles. Si el cliente o terceros realizan modificaciones o trabajos de reparación inadecuados, no se admitirán reclamaciones por defectos por estos y por las consecuencias que de ellos se deriven.",
        parrafo7punto8: "7. El proveedor no se hará cargo de los gastos adicionales, en particular de transporte, seguimiento, mano de obra y material, que resulten del hecho de que el objeto de la entrega se haya transportado posteriormente a un lugar distinto al de la sucursal del cliente o al lugar de destino original, a menos que el transporte corresponda a su uso adecuado.",
        parrafo8punto8: "8. En todos los casos, el cliente está obligado a adoptar todas las medidas posibles y razonables para que los gastos de ejecución del contrato sean lo más reducidos posible. El proveedor solo participará en los costes de una campaña de retirada del producto si es necesario en función de los hechos y de la ley. El cliente está obligado a devolver los productos defectuosos o a mantenerlos listos para su inspección y reparación, a discreción del proveedor.",
        parrafo9punto8: "9.  Las reclamaciones de recurso del cliente contra el proveedor solo existirán en la medida en que el cliente no haya celebrado con el comprador de este ningún acuerdo que exceda las reclamaciones legales por defectos. Además, el punto 8.8 se aplicará en consecuencia al alcance del derecho de recurso del cliente contra el proveedor.",
        parrafo10punto8: "10.  Además, el punto 11 (Otras reclamaciones por daños y perjuicios) también se aplica a las reclamaciones por daños y perjuicios. Quedan excluidas las reclamaciones más amplias o diferentes a las estipuladas en el presente punto 8 del cliente contra el proveedor y las personas empleadas por este para el cumplimiento de las obligaciones por un defecto material.",
        punto9: "IX. Derechos de propiedad industrial y derechos de autor; defectos de titularidad",
        parrafo1punto9: "1. A menos que se haya convenido otra cosa, el proveedor está obligado a liberar el suministro de derechos de propiedad industrial y derechos de autor de terceros (en adelante: derechos de propiedad) únicamente en el país del lugar de suministro. En caso de que un tercero presente reclamaciones justificadas contra el cliente por violación de derechos de propiedad por suministros entregados por el proveedor y utilizados de acuerdo con el contrato, el proveedor será responsable ante el cliente dentro del plazo estipulado en el punto 8.2 de lo siguiente:",
        parrafo1punto9a: "a) el proveedor obtendrá, a su propio costo y a su discreción, un derecho de uso para los suministros en cuestión, los modificará de manera que no se violen los derechos de propiedad o los cambiará. Si el proveedor no puede hacerlo en condiciones razonables, el cliente tendrá derecho a la revocación legal de los derechos de reducción. El cliente no podrá reclamar una indemnización por gastos inútiles.",
        parrafo1punto9b: "b) la obligación del proveedor de pagar daños y perjuicios estará sujeta al punto 11.",
        parrafo1punto9c: "c) Las obligaciones antes mencionadas del proveedor sólo se cumplirán si el cliente informa inmediatamente por escrito al proveedor sobre las reclamaciones de terceros, se niega a reconocer una infracción y todas las medidas de protección y procedimientos de resolución siguen siendo reservadas al proveedor. Si el cliente deja de usar la mercancía entregada con el fin de reducir el daño o por otras razones, el cliente está obligado a informar al tercero sobre el hecho de que la interrupción del uso no representa un reconocimiento de la violación de los derechos de propiedad.",
        parrafo2punto9: "2. Se excluyen las reclamaciones del cliente si el cliente es responsable de la violación de los derechos de propiedad.",
        parrafo3punto9: "3. Además, se excluyen las reclamaciones del cliente en la medida en que la violación de los derechos de propiedad haya sido causada por normas especiales estipuladas por el cliente, por un uso no previsible por el proveedor o por el hecho de que la mercancía entregada haya sido modificada por el cliente o utilizada en combinación con productos no suministrados por el proveedor.",
        parrafo4punto9: "4. En caso de infracción de derechos de propiedad intelectual, se aplicarán las reclamaciones del cliente previstas en el punto 9.1 a), además de lo dispuesto en los puntos 8.4, 8.5 y 8.10. En caso de otros defectos de propiedad, se aplicarán en consecuencia las disposiciones del punto 8. Quedan excluidas las reclamaciones más amplias o distintas de las previstas en el presente punto 9 del cliente contra el proveedor y las personas empleadas por éste para el cumplimiento de las obligaciones por un defecto de propiedad.",
        punto10: "X. Imposibilidad; adaptación del contrato",
        parrafo1punto10: "1. En caso de que la entrega no sea posible, el cliente tendrá derecho a reclamar daños y perjuicios, a menos que la imposibilidad sea imputable al proveedor. Sin embargo, las reclamaciones por daños y perjuicios del cliente se limitarán al 10 % de la parte de la entrega que no pueda ponerse en funcionamiento debido a la imposibilidad. Esta limitación no se aplicará en caso de responsabilidad obligatoria por dolo, negligencia grave o lesiones personales; en este caso no se prevé una inversión de la carga de la prueba en perjuicio del cliente. El derecho del cliente a rescindir el contrato no se verá afectado.",
        parrafo2punto10: "2. En caso de imposibilidad temporal, se aplicará el punto 5 (plazos).",
        parrafo3punto10: "3. Si acontecimientos imprevisibles en el sentido del punto 5.2 modifican significativamente el significado económico o el contenido de la entrega o tienen un efecto significativo en las operaciones del proveedor, el contrato se adaptará de buena fe. En la medida en que esto no sea económicamente razonable, el proveedor tendrá derecho a rescindir el contrato. Si el proveedor desea hacer valer este derecho de rescisión, deberá informar inmediatamente al cliente, una vez que haya tenido conocimiento del alcance del hecho. Esto también se aplicará si se ha acordado previamente con el cliente una prolongación del plazo de entrega.",
        punto11: "XI. Otras reclamaciones por daños y perjuicios.",
        parrafo1punto11: "1. Quedan excluidas las reclamaciones del cliente por daños y perjuicios y por indemnización de gastos por incumplimiento de obligaciones principales o accesorias de la obligación contractual, por motivos de fuerza mayor o de otro tipo.",
        parrafo2punto11: "2. Esta exclusión no se aplicará en la medida en que exista una responsabilidad obligatoria, por ejemplo, de conformidad con la Ley de responsabilidad civil por productos, en caso de dolo, negligencia grave, por daños personales, por la asunción de una garantía de la existencia de un bien o por incumplimiento de obligaciones contractuales esenciales. Sin embargo, la indemnización por el incumplimiento de obligaciones contractuales esenciales se limitará al daño previsible y típico del contrato, siempre que no exista dolo o negligencia grave o responsabilidad por daños personales o por la asunción de una garantía de la existencia de un bien. Las disposiciones anteriores no se refieren a una transferencia de la carga de la responsabilidad en perjuicio del cliente.",
        parrafo3punto11: "3. Si la ley imperativa no establece plazos de prescripción más largos, todas las reclamaciones por daños y perjuicios prescribirán dentro de los plazos establecidos en el punto 8.2.",
        punto12: "XII. Garantía y descripción del producto",
        parrafo1punto12: "1. Las garantías sólo surtirán efecto si se hacen por escrito.",
        parrafo2punto12: "2. Los datos descritos en catálogos, documentos de licitación y otros impresos, así como las declaraciones publicitarias generales, no constituyen una oferta para la celebración de un contrato de garantía.",
        punto13: "XIII. Reserva de dominio",
        parrafo1punto13: "1. El proveedor se reserva el dominio de la mercancía entregada (mercancía de reserva) hasta que el cliente haya pagado la totalidad de la deuda resultante de la relación comercial. La reserva de dominio también se extiende al saldo reconocido, en la medida en que el proveedor anote las reclamaciones contra el cliente en cuenta corriente (reserva de cuenta corriente).",
        parrafo2punto13: "2. Si el proveedor acepta la devolución de la mercancía entregada, esto significará la rescisión del contrato. En caso de devolución de la mercancía comprada, el proveedor tendrá derecho a realizar dicha mercancía, abonando los gastos de realización. En caso de embargo de la mercancía entregada, el proveedor tendrá derecho a rescindir el contrato sin fijar un plazo. En caso de embargo u otras intervenciones de terceros, el cliente deberá informar inmediatamente al proveedor por escrito para que este pueda presentar una demanda de conformidad con el § 771 del Código de Procedimiento Civil alemán. En caso de que terceros no puedan reembolsar al proveedor los gastos judiciales y extrajudiciales de una demanda de conformidad con el § 771 del Código de Procedimiento Civil alemán, el cliente será responsable de la pérdida sufrida por el proveedor.",
        parrafo3punto13: "3. El cliente tiene derecho a revender la mercancía entregada en el curso normal de sus negocios, pero cede al proveedor desde ahora todas las reclamaciones por el importe de la factura final, incluido el impuesto sobre el valor añadido, que le correspondan por la reventa frente a su comprador o terceros, independientemente de si la mercancía entregada se revendió sin procesar o después de procesarla. El cliente tiene derecho a cobrar esta reclamación también después de su cesión. Esto no afecta al derecho del proveedor de cobrar la reclamación por sí mismo; el proveedor puede exigir al cliente que le revele las reclamaciones cedidas y sus deudores, que le facilite la información necesaria para el cobro, que le facilite la documentación pertinente y que informe al deudor (tercero) sobre la cesión.",
        parrafo4punto13: "4. La transformación y el procesamiento de la mercancía entregada por parte del cliente se realizarán siempre para el proveedor. Si la mercancía entregada se procesa junto con otros objetos que no pertenecen al proveedor, este obtendrá la copropiedad del nuevo objeto en la proporción del valor de la mercancía entregada en relación con los otros objetos procesados ​​en el momento de la transformación. En lo demás, se aplicarán a la materia resultante de la transformación las mismas disposiciones que para la mercancía reservada. El cliente cederá también al proveedor los derechos de garantía de las reclamaciones del proveedor que le correspondan frente a un tercero mediante la unión de los bienes de entrega con un inmueble.",
        parrafo5punto13: "5. Si los bienes de entrega se mezclan inseparablemente con otros objetos que no pertenecen al proveedor, éste adquiere la copropiedad del nuevo objeto en proporción al valor de los bienes de entrega en relación con los demás objetos mezclados en el momento de la mezcla. Si la mezcla se realiza de tal manera que el material del cliente se considere un componente principal, las partes acuerdan que el cliente cederá al proveedor la copropiedad proporcional. El cliente conservará la propiedad exclusiva o la copropiedad para el proveedor. El cliente deberá asegurarla en el alcance habitual contra riesgos habituales como, por ejemplo, incendio, robo, agua y similares. El cliente cederá desde ahora al proveedor las reclamaciones de indemnización que le correspondan por daños de la clase mencionada frente a aseguradores u otros terceros, por un importe equivalente al valor de factura de los bienes.",
        parrafo6punto13: "6. Si el valor realizable de las garantías adeudadas al proveedor excede el total de las reclamaciones del proveedor en más del 10%, el proveedor estará obligado a liberar, a su discreción, las garantías a petición del cliente o de un tercero afectado por el exceso de garantía.",
        punto14: "XIV. Jurisdicción y derecho aplicable",
        parrafo1punto14: "1. La jurisdicción competente para todas las controversias derivadas del presente contrato será la sede del proveedor, siempre que el cliente sea un comerciante. No obstante, el proveedor tendrá derecho a interponer una demanda en la sede del cliente.",
        parrafo2punto14: "2. A las relaciones contractuales se aplicará el derecho alemán. Se excluye la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías (CISG).",
        punto15: "XV. Divisibilidad",
        parrafo1punto15: "En caso de que alguna de las cláusulas del presente contrato fuese ineficaz desde el punto de vista jurídico, la validez de las demás cláusulas no se verá afectada en modo alguno. Esto no se aplicará si el cumplimiento del contrato supusiera una carga irrazonable para la otra parte contratante.",
        legal: "AVISO LEGAL",
        information: "Información según el artículo 5 de la TMG",
        parrafo1information: "DOSATRONIC IBERIA S.L,",
        parrafo2information: "Calle del Vallés, 7",
        parrafo3information: "08397 Pineda de mar",
        parrafo4information: "Registro mercantil: HRB 552723",
        parrafo4information: "Tribunal de registro: Ulm",
        parrafo5information: "Representado por:",
        parrafo6information: "Director General Ángel Villaret",
        parrafo7information: "",
        contact: "Contacto",
        parrafo1contact: "Móvil: +34 93 517 20 08", //+34 93 517 20 08
        parrafo2contact: "",
        parrafo3contact: "E-mail: info@dosatroniciberia.es", //info@dosatroniciberia.es
        vat: "Número de IVA",
        parrafo1vat: "Número de identificación fiscal según el artículo 27 a de la Ley del Impuesto sobre las Ventas:",
        parrafo2vat: "DE812973283",
        dispute: "Resolución de disputas en la UE",
        parrafo1dispute: "La Comisión Europea ofrece una plataforma para la resolución de litigios en línea (ODR): https://ec.europa.eu/consumers/odr/ .",
        parrafo2dispute: "Nuestra dirección de correo electrónico se encuentra en el aviso legal que aparece más arriba.",
        dispute2: "Procedimiento de resolución de conflictos ante una junta arbitral de consumo",
        parrafo1dispute2: "No estamos dispuestos ni obligados a participar en procedimientos de resolución de disputas ante una junta de arbitraje de consumo.",
        images: "Imagenes:",
        parrafo1images: "84367010, Fotolia, Creator: digieye",
        parrafo2images: "83867300, Fotolia, Creator: somchaij",
        parrafo3images: "124411039, Fotolia, Creator: samafoto",
        parrafo4images: "119530550, Fotolia, Creator: yotrakbutda",
        parrafo5images: "69418987, Fotolia, Creator: bigfoot",
        parrafo6images: "178882165, Fotolia, Creator: psdesign1",
        parrafo7images: "Metallverarbeitung Emotion: © photosoup/stock.adobe.com",
        parrafo8images: "Metallverarbeitung: © leungchopan/stock.adobe.com",
        parrafo9images: "Tierzucht und Landwirtschaft Emotion: © Kadmy/stock.adobe.com",
        parrafo10images: "Tierzucht und Landwirtschaft: © 88studio/stock.adobe.com",
        parrafo11images: "Wasseraufbereitung Emotion: © OrthsMedien/stock.adobe.com",
        parrafo12images: "Fahrzeugwäsche Emotion: © PixPartout/stock.adobe.com",
        parrafo13images: "Fahrzeugwäsche: © industrieblick/stock.adobe.com",
        parrafo14images: "Druckindustrie Emotion: ©  rh2010/stock.adobe.com",
        parrafo15images: "Lebensmittelindustrie Emotion: © agnormark/stock.adobe.com",
        parrafo16images: "Imágenes de productos, DOSATRONIC GmbH, Creador: Sr. Hubert Müller, editor técnico, DOSATRONIC GmbH, Zuppingerstraße 8, 88213 Ravensburg",
        parrafo17images: "Icono de Google Maps:  Freepik  de  www.flaticon.com ,  CC 3.0 BY",
        parrafo18images: "Flaggen Icons von Flagpedia. lizenziert unter Public Domain",
        parrafo19images: "Schweine Icon: Icons made by photo3idea_studio from www.flaticon.com",
        parrafo20images: "Medizin Icon: Icons made by SumberRejeki from www.flaticon.com",
        parrafo21images: "Icons made by Freepik from www.flaticon.com",
        parrafo22images: "Icons made by photo3idea_studio from www.flaticon.com",
        parrafo23images: "Icons made by smashingstocks from www.flaticon.com",
        parrafo24images: "Icons made by Those Icons from www.flaticon.com",
        parrafo25images: "Icons made by Kemalmoe from www.flaticon.com",
        privacy: "POLÍTICA DE PRIVACIDAD DE DATOS",
        tituloprivacy: "política de privacidad",
        protecciondatos: "1. Visión general de la protección de datos",
        sub1protecciondatos: "información general",
        parrafo1protecciondatos: "La siguiente información le proporcionará una visión general, de fácil lectura, de lo que sucederá con sus datos personales cuando visite este sitio web. El término “datos personales” incluye todos los datos que puedan utilizarse para identificarle personalmente. Para obtener información detallada sobre el tema de la protección de datos, consulte nuestra Declaración de Protección de Datos, que hemos incluido debajo de esta copia.",
        sub2protecciondatos: "Registro de datos en este sitio web",
        subsub1protecciondatos: "¿Quién es el responsable del registro de datos en este sitio web (es decir, el “responsable del tratamiento”)?",
        parrafo2protecciondatos: "Los datos de este sitio web son procesados ​​por el operador del sitio web, cuyos datos de contacto están disponibles en la sección “Información sobre la parte responsable (denominada “controlador” en el RGPD)” de esta Política de privacidad.",
        subsub2protecciondatos: "¿Cómo registramos sus datos?",
        parrafo3protecciondatos: "Recopilamos sus datos como resultado de que usted comparta sus datos con nosotros. Por ejemplo, puede tratarse de información que usted introduce en nuestro formulario de contacto.",
        parrafo4protecciondatos: "Otros datos serán registrados por nuestros sistemas informáticos de forma automática o tras su consentimiento durante su visita al sitio web. Estos datos incluyen principalmente información técnica (por ejemplo, navegador web, sistema operativo u hora de acceso al sitio web). Esta información se registra automáticamente cuando accede a este sitio web.",
        subsub3protecciondatos: "¿Para qué finalidades utilizamos tus datos?",
        parrafo5protecciondatos: "Una parte de la información se genera para garantizar que el sitio web funcione sin errores. Otros datos pueden utilizarse para analizar sus patrones de uso.",
        subsub4protecciondatos: "¿Qué derechos tiene usted en relación a su información?",
        parrafo6protecciondatos: "Tiene derecho a recibir en cualquier momento información sobre el origen, los destinatarios y los fines de los datos personales que le conciernen, sin tener que pagar ninguna tasa por ello. También tiene derecho a exigir que se rectifiquen o eliminen sus datos. Si ha dado su consentimiento para el tratamiento de los datos, tiene la posibilidad de revocarlo en cualquier momento, lo que afectará a todos los tratamientos de datos futuros. Además, tiene derecho a exigir que se restrinja el tratamiento de sus datos en determinadas circunstancias. Además, tiene derecho a presentar una reclamación ante la autoridad supervisora ​​competente.",
        parrafo7protecciondatos: "No dude en ponerse en contacto con nosotros en cualquier momento si tiene preguntas sobre este o cualquier otro tema relacionado con la protección de datos.",
        sub3protecciondatos: "Herramientas de análisis y herramientas proporcionadas por terceros",
        parrafo8protecciondatos: "Existe la posibilidad de que sus patrones de navegación sean analizados estadísticamente cuando visite este sitio web. Dichos análisis se realizan principalmente con lo que llamamos programas de análisis.",
        parrafo9protecciondatos: "Para obtener información detallada sobre estos programas de análisis, consulte nuestra Declaración de Protección de Datos a continuación.",
        hosting: "2. Hosting",
        parrafo1hosting: "Alojamos el contenido de nuestro sitio web en el siguiente proveedor:",
        sub1hosting: "Mittwald",
        parrafo2hosting: "El proveedor es Mittwald CM Service GmbH & Co. KG, Königsberger Straße 4-6, 32339 Espelkamp, ​​​​Alemania (en lo sucesivo, Mittwald).",
        parrafo3hosting: "Para obtener más detalles, consulte la política de privacidad de datos de Mittwald: https://www.mittwald.de/datenschutz .",
        parrafo4hosting: "Utilizamos Mittwald sobre la base del art. 6(1)(f) del RGPD. Tenemos un interés legítimo en la representación más fiable posible de nuestro sitio web. Si se ha obtenido el consentimiento correspondiente, el tratamiento se lleva a cabo exclusivamente sobre la base del art. 6(1)(a) del RGPD y del § 25 (1) de la TTDSG, siempre que el consentimiento incluya el almacenamiento de cookies o el acceso a información en el dispositivo terminal del usuario (por ejemplo, la huella digital del dispositivo) en el sentido de la TTDSG. Este consentimiento puede revocarse en cualquier momento.",
        subsub1hosting: "Proceso de datos",
        parrafo5hosting: "Hemos celebrado un contrato de procesamiento de datos (DPA) para el uso del servicio mencionado anteriormente. Se trata de un contrato exigido por las leyes de privacidad de datos que garantiza que procesamos los datos personales de los visitantes de nuestro sitio web únicamente según nuestras instrucciones y de conformidad con el RGPD.",
        general: "3. Información general e información obligatoria",
        sub1general: "Protección de datos",
        parrafo1general: "Los operadores de este sitio web y de sus páginas se toman muy en serio la protección de sus datos personales. Por ello, tratamos sus datos personales de forma confidencial y de conformidad con las disposiciones legales en materia de protección de datos y con esta declaración de protección de datos.",
        parrafo2general: "Cada vez que utilice este sitio web, se recopilará una variedad de información personal. Los datos personales incluyen datos que pueden usarse para identificarlo personalmente. Esta Declaración de Protección de Datos explica qué datos recopilamos, así como para qué los utilizamos. También explica cómo y para qué propósito se recopila la información.",
        parrafo3general: "Le informamos de que la transmisión de datos a través de Internet (es decir, mediante comunicaciones por correo electrónico) puede presentar lagunas de seguridad. No es posible proteger completamente los datos contra el acceso de terceros.",
        sub2general: "Información sobre el responsable del tratamiento (denominado «responsable del tratamiento» en el RGPD)",
        parrafo4general: "El responsable del tratamiento de datos en este sitio web es:",
        parrafo5general: "DOSATRONIC GmbH",
        parrafo6general: "Zuppingerstraße 8",
        parrafo7general: "88213 Ravensburg",
        parrafo8general: "Phone: +49-751-29512-0",
        parrafo9general: "E-mail: info@dosatronic.de",
        parrafo10general: "El responsable del tratamiento es la persona física o jurídica que, sola o junto con otras, decide sobre los fines y los medios del tratamiento de datos personales (por ejemplo, nombres, direcciones de correo electrónico, etc.).",
        sub3general: "Duración del almacenamiento",
        parrafo11general: "A menos que se especifique un período de almacenamiento más específico en esta política de privacidad, sus datos personales permanecerán con nosotros hasta que deje de aplicarse el propósito para el que fueron recopilados. Si presenta una solicitud justificada de eliminación o revoca su consentimiento para el procesamiento de datos, sus datos serán eliminados, a menos que tengamos otros motivos legalmente permitidos para almacenar sus datos personales (por ejemplo, períodos de retención de la legislación fiscal o comercial); en este último caso, la eliminación se llevará a cabo después de que dejen de aplicarse estos motivos.",
        sub4general: "Información general sobre la base legal para el tratamiento de datos en este sitio web",
        parrafo12general: "Si ha dado su consentimiento para el tratamiento de datos, procesaremos sus datos personales sobre la base del art. 6 (1) (a) del RGPD o del art. 9 (2) (a) del RGPD, si se procesan categorías especiales de datos de acuerdo con el art. 9 (1) del RGPD. En caso de consentimiento explícito para la transferencia de datos personales a terceros países, el tratamiento de datos también se basa en el art. 49 (1) (a) del RGPD. Si ha dado su consentimiento para el almacenamiento de cookies o para el acceso a información en su dispositivo final (por ejemplo, mediante la huella digital del dispositivo), el tratamiento de datos se basa además en el § 25 (1) de la TTDSG. El consentimiento puede revocarse en cualquier momento. Si sus datos son necesarios para el cumplimiento de un contrato o para la implementación de medidas precontractuales, procesamos sus datos sobre la base del art. 6 (1) (b) del RGPD. Además, si sus datos son necesarios para el cumplimiento de una obligación legal, los procesamos sobre la base del art. 6 (1) (c) del RGPD. Además, el tratamiento de datos puede tener lugar sobre la base de nuestro interés legítimo de conformidad con el art. 6(1)(f) del RGPD. En los siguientes apartados de esta política de privacidad se ofrece información sobre la base jurídica pertinente en cada caso concreto.",
        sub5general: "Información sobre la transferencia de datos a EE. UU. y otros países no pertenecientes a la UE",
        parrafo13general: "Entre otras cosas, utilizamos herramientas de empresas con sede en Estados Unidos o en otros países no pertenecientes a la UE que no son seguros desde el punto de vista de la protección de datos. Si estas herramientas están activas, sus datos personales podrían transferirse a estos países no pertenecientes a la UE y procesarse allí. Debemos señalar que en estos países no se puede garantizar un nivel de protección de datos comparable al de la UE. Por ejemplo, las empresas estadounidenses están obligadas a facilitar datos personales a las agencias de seguridad y usted, como interesado, no tiene ninguna opción de litigar para defenderse en los tribunales. Por lo tanto, no se puede descartar que las agencias estadounidenses (por ejemplo, el Servicio Secreto) procesen, analicen y archiven permanentemente sus datos personales con fines de vigilancia. No tenemos ningún control sobre estas actividades de procesamiento.",
        sub6general: "Revocación de su consentimiento para el tratamiento de datos",
        parrafo14general: "Una gran variedad de operaciones de tratamiento de datos solo son posibles con su consentimiento expreso. También puede revocar en cualquier momento cualquier consentimiento que ya nos haya otorgado. Esto se entenderá sin perjuicio de la legalidad de cualquier recopilación de datos realizada antes de su revocación.",
        sub7general: "Derecho a oponerse a la recogida de datos en casos especiales; derecho a oponerse a la publicidad directa (art. 21 del RGPD)",
        parrafo15general: "EN CASO DE QUE LOS DATOS SE TRATEN CON BASE EN EL ART. 6(1)(E) O (F) DEL RGPD, USTED TIENE DERECHO A OPONERSE EN CUALQUIER MOMENTO AL TRATAMIENTO DE SUS DATOS PERSONALES BASÁNDOSE EN MOTIVOS DERIVADOS DE SU SITUACIÓN PARTICULAR. ESTO TAMBIÉN SE APLICA A CUALQUIER PERFIL BASADO EN ESTAS DISPOSICIONES. PARA DETERMINAR LA BASE JURÍDICA EN LA QUE SE BASA CUALQUIER TRATAMIENTO DE DATOS, CONSULTE ESTA DECLARACIÓN DE PROTECCIÓN DE DATOS. SI USTED PRESENTA UNA OBJECIÓN, YA NO PROCESAREMOS SUS DATOS PERSONALES AFECTADOS, A MENOS QUE ESTEMOS EN POSICIÓN DE PRESENTAR MOTIVOS IMPERIOSOS Y DIGNOS DE PROTECCIÓN PARA EL PROCESAMIENTO DE SUS DATOS, QUE PREVALEZCAN SOBRE SUS INTERESES, DERECHOS Y LIBERTADES O SI EL PROPÓSITO DEL PROCESAMIENTO ES LA RECLAMACIÓN, EL EJERCICIO O LA DEFENSA DE DERECHOS LEGALES (OBJECIÓN DE CONFORMIDAD CON EL ART. 21(1) DEL RGPD).",
        parrafo16general: "SI SUS DATOS PERSONALES ESTÁN SIENDO TRATADOS PARA REALIZAR PUBLICIDAD DIRECTA, USTED TIENE DERECHO A OPONERSE EN CUALQUIER MOMENTO AL TRATAMIENTO DE SUS DATOS PERSONALES AFECTADOS PARA LOS EFECTOS DE DICHA PUBLICIDAD. ESTO TAMBIÉN SE APLICA A LA ELABORACIÓN DE PERFILES EN LA MEDIDA EN QUE ESTÉ RELACIONADA CON DICHA PUBLICIDAD DIRECTA. SI USTED SE OPONE, SUS DATOS PERSONALES YA NO SERÁN UTILIZADOS PARA LOS FINES DE PUBLICIDAD DIRECTA (OBJECIÓN DE CONFORMIDAD CON EL ART. 21(2) DEL RGPD).",
        sub8general: "Derecho a presentar una reclamación ante la autoridad de control competente",
        parrafo17general: "En caso de infracción del RGPD, el interesado tendrá derecho a presentar una reclamación ante una autoridad de control, en particular en el Estado miembro en el que tenga su domicilio habitual, su lugar de trabajo o el lugar donde se haya producido la supuesta infracción. El derecho a presentar una reclamación se aplica independientemente de cualquier otro recurso administrativo o judicial disponible.",
        sub9general: "Derecho a la portabilidad de datos",
        parrafo18general: "Tiene derecho a exigir que le entreguemos a usted o a un tercero los datos que procesamos automáticamente sobre la base de su consentimiento o para el cumplimiento de un contrato en un formato de uso común y legible por máquina. Si solicita la transmisión directa de los datos a otro responsable, esto se hará solo si es técnicamente posible.",
        sub10general: "Información, rectificación y supresión de datos",
        parrafo19general: "En el marco de las disposiciones legales aplicables, usted tiene derecho a solicitar en cualquier momento información sobre sus datos personales almacenados, su origen y destinatario, así como sobre la finalidad del tratamiento de sus datos. También puede tener derecho a solicitar la rectificación o eliminación de sus datos. Si tiene alguna pregunta sobre este tema o sobre cualquier otra pregunta relacionada con los datos personales, no dude en ponerse en contacto con nosotros en cualquier momento.",
        sub11general: "Derecho a exigir limitaciones del tratamiento",
        parrafo12general: "Tiene derecho a solicitar la limitación del tratamiento de sus datos personales. Para ello, puede ponerse en contacto con nosotros en cualquier momento. El derecho a solicitar la limitación del tratamiento se aplica en los siguientes casos:",
        ul1general: "En caso de que usted cuestione la exactitud de los datos que hemos archivado sobre usted, normalmente necesitaremos un tiempo para verificar esta afirmación. Durante el tiempo que dure esta investigación, usted tiene derecho a exigir que restrinjamos el procesamiento de sus datos personales.",
        ul2general: "Si el procesamiento de sus datos personales se realizó/se realiza de manera ilegal, usted tiene la opción de exigir la restricción del procesamiento de sus datos en lugar de exigir la erradicación de dichos datos.",
        ul3general: "Si ya no necesitamos sus datos personales y usted los necesita para ejercer, defender o reclamar derechos legales, tiene derecho a exigir la restricción del procesamiento de sus datos personales en lugar de su erradicación.",
        ul4general: "Si ha presentado una objeción de conformidad con el artículo 21(1) del RGPD, se deberán sopesar sus derechos y los nuestros. Mientras no se haya determinado qué intereses prevalecen, usted tiene derecho a exigir la limitación del tratamiento de sus datos personales.",
        parrafo13general: "Si ha restringido el procesamiento de sus datos personales, estos datos, con excepción de su archivo, solo podrán procesarse con su consentimiento o para reclamar, ejercer o defender derechos legales o para proteger los derechos de otras personas físicas o jurídicas o por razones importantes de interés público citadas por la Unión Europea o un estado miembro de la UE.",
        sub12general: "Cifrado SSL y/o TLS",
        parrafo14general: "Por razones de seguridad y para proteger la transmisión de contenido confidencial, como pedidos de compra o consultas que nos envíe como operador del sitio web, este sitio web utiliza un programa de cifrado SSL o TLS. Puede reconocer una conexión cifrada comprobando si la línea de dirección del navegador cambia de “http://” a “https://” y también por la aparición del icono del candado en la línea del navegador.",
        parrafo15general: "Si el cifrado SSL o TLS está activado, los datos que nos transmita no podrán ser leídos por terceros.",
        sub13general: "Rechazo de correos electrónicos no solicitados",
        parrafo16general: "Nos oponemos a que los datos de contacto publicados junto con la información obligatoria que debe facilitarse en nuestro Aviso legal se utilicen para enviarnos material promocional e informativo que no hayamos solicitado expresamente. Los operadores de este sitio web y de sus páginas se reservan el derecho expreso de emprender acciones legales en caso de envío no solicitado de información promocional, por ejemplo, mediante mensajes SPAM.",
        recording: "4. Registro de datos en este sitio web",
        sub1recording: "Cookies",
        parrafo1recording: "Nuestros sitios web y páginas utilizan lo que la industria denomina “cookies”. Las cookies son pequeños paquetes de datos que no causan ningún daño a su dispositivo. Se almacenan temporalmente durante la duración de una sesión (cookies de sesión) o se archivan de forma permanente en su dispositivo (cookies permanentes). Las cookies de sesión se eliminan automáticamente una vez que finaliza su visita. Las cookies permanentes permanecen archivadas en su dispositivo hasta que las elimine activamente o su navegador web las elimine automáticamente.",
        parrafo2recording: "Las cookies pueden ser emitidas por nosotros (cookies propias) o por empresas de terceros (las denominadas cookies de terceros). Las cookies de terceros permiten la integración de determinados servicios de empresas de terceros en sitios web (por ejemplo, cookies para gestionar servicios de pago).",
        parrafo3recording: "Las cookies tienen distintas funciones. Muchas cookies son técnicamente esenciales, ya que ciertas funciones del sitio web no funcionarían sin ellas (por ejemplo, la función del carrito de compras o la visualización de videos). Otras cookies pueden utilizarse para analizar el comportamiento del usuario o con fines promocionales.",
        parrafo4recording: "Las cookies necesarias para la realización de transacciones de comunicación electrónica, para la prestación de determinadas funciones que desee utilizar (por ejemplo, para la función de carrito de la compra) o para la optimización (cookies necesarias) del sitio web (por ejemplo, cookies que proporcionan información medible sobre la audiencia web) se almacenarán sobre la base del art. 6 (1) (f) del RGPD, a menos que se indique una base jurídica diferente. El operador del sitio web tiene un interés legítimo en el almacenamiento de las cookies necesarias para garantizar la prestación técnicamente libre de errores y optimizada de los servicios del operador. Si se ha solicitado su consentimiento para el almacenamiento de cookies y tecnologías de reconocimiento similares, el tratamiento se realiza exclusivamente sobre la base del consentimiento obtenido (art. 6 (1) (a) del RGPD y § 25 (1) TTDSG); este consentimiento puede revocarse en cualquier momento.",
        parrafo5recording: "Tiene la opción de configurar su navegador para que le notifique cada vez que se instalen cookies y para permitir la aceptación de cookies solo en casos específicos. También puede excluir la aceptación de cookies en ciertos casos o en general o activar la función de eliminación para la eliminación automática de cookies al cerrar el navegador. Si desactiva las cookies, las funciones de este sitio web pueden verse limitadas.",
        parrafo6recording: "Qué cookies y servicios se utilizan en este sitio web se pueden consultar en esta política de privacidad.",
        sub2recording: "Consentimiento con Cookiebot",
        parrafo7recording: "Nuestro sitio web utiliza la tecnología de consentimiento de Cookiebot para obtener su consentimiento para el almacenamiento de determinadas cookies en su dispositivo final o para el uso de determinadas tecnologías y para documentarlo de forma que cumpla con las normas de protección de datos. El proveedor de esta tecnología es Cybot A/S, Havnegade 39, 1058 Copenhague, Dinamarca (en adelante, «Cookiebot»).",
        parrafo8recording: "Cuando accede a nuestro sitio web, se establece una conexión con los servidores de Cookiebot para obtener su consentimiento y proporcionarle otras explicaciones sobre el uso de cookies. A continuación, Cookiebot almacenará una cookie en su navegador para identificar el consentimiento que ha otorgado o su revocación. Los datos recopilados de esta manera se almacenan hasta que nos solicite que los eliminemos, eliminemos la propia cookie de Cookiebot o deje de aplicarse el propósito para el que se almacenan los datos. Las obligaciones legales de almacenamiento obligatorias no se ven afectadas.",
        parrafo9recording: "Cookiebot se utiliza para obtener el consentimiento legalmente requerido para el uso de cookies. La base legal para ello es el art. 6(1)(c) del RGPD.",
        sub3recording: "Proceso de datos",
        parrafo10recording: "Hemos celebrado un contrato de procesamiento de datos (DPA) para el uso del servicio mencionado anteriormente. Se trata de un contrato exigido por las leyes de privacidad de datos que garantiza que procesamos los datos personales de los visitantes de nuestro sitio web únicamente según nuestras instrucciones y de conformidad con el RGPD.",
        sub4recording: "Formulario de contacto",
        parrafo11recording: "Si nos envía consultas a través de nuestro formulario de contacto, almacenaremos la información proporcionada en el formulario de contacto, así como cualquier información de contacto proporcionada en el mismo, para gestionar su consulta y en caso de que tengamos más preguntas. No compartiremos esta información sin su consentimiento.",
        parrafo12recording: "El tratamiento de estos datos se basa en el art. 6(1)(b) del RGPD, si su solicitud está relacionada con la ejecución de un contrato o si es necesaria para llevar a cabo medidas precontractuales. En todos los demás casos, el tratamiento se basa en nuestro interés legítimo en el procesamiento efectivo de las solicitudes que se nos dirigen (art. 6(1)(f) del RGPD) o en su consentimiento (art. 6(1)(a) del RGPD) si este se ha solicitado; el consentimiento puede revocarse en cualquier momento.",
        parrafo13recording: "Los datos que haya introducido en el formulario de contacto permanecerán en nuestro poder hasta que nos solicite su eliminación, revoque su consentimiento para el almacenamiento de los datos o si el fin para el que se almacenan los datos ya no existe (por ejemplo, después de que hayamos finalizado la respuesta a su consulta). Esto se entenderá sin perjuicio de las disposiciones legales obligatorias, en particular los plazos de conservación.",
        sub5recording: "Solicitud por correo electrónico, teléfono o fax",
        parrafo14recording: "Si se pone en contacto con nosotros por correo electrónico, teléfono o fax, su solicitud, incluidos todos los datos personales que se deriven de ella (nombre, solicitud), se almacenarán y procesarán con el fin de tramitar su solicitud. No transmitiremos estos datos sin su consentimiento.",
        parrafo15recording: "El tratamiento de estos datos se basa en el art. 6(1)(b) del RGPD, si su consulta está relacionada con el cumplimiento de un contrato o es necesaria para la ejecución de medidas precontractuales. En todos los demás casos, el tratamiento de los datos se basa en nuestro interés legítimo en tramitar de forma eficaz las consultas que nos ha enviado (art. 6(1)(f) del RGPD) o en su consentimiento (art. 6(1)(a) del RGPD), si lo ha obtenido; el consentimiento puede revocarse en cualquier momento.",
        parrafo16recording: "Los datos que nos envíe a través de solicitudes de contacto permanecerán en nuestro poder hasta que nos solicite su eliminación, revoque su consentimiento para el almacenamiento o deje de existir la finalidad del almacenamiento de los datos (por ejemplo, una vez finalizada su solicitud). Las disposiciones legales obligatorias, en particular los plazos de conservación legales, no se verán afectadas.",
        analisys: "5. Herramientas de análisis y publicidad",
        sub1analisys: "Administrador de etiquetas de Google",
        parrafo1analisys: "Utilizamos Google Tag Manager. El proveedor es Google Ireland Limited, Gordon House, Barrow Street, Dublín 4, Irlanda.",
        parrafo2analisys: "Google Tag Manager es una herramienta que nos permite integrar herramientas de seguimiento o estadísticas y otras tecnologías en nuestro sitio web. Google Tag Manager por sí mismo no crea perfiles de usuario, no almacena cookies ni realiza análisis independientes, sino que únicamente gestiona y ejecuta las herramientas integradas a través de él. Sin embargo, Google Tag Manager sí recopila su dirección IP, que también puede ser transferida a la empresa matriz de Google en Estados Unidos.",
        parrafo3analisys: "El uso de Google Tag Manager se basa en el art. 6(1)(f) del RGPD. El operador del sitio web tiene un interés legítimo en la integración y administración rápida y sencilla de diversas herramientas en su sitio web. Si se ha obtenido el consentimiento correspondiente, el tratamiento se lleva a cabo exclusivamente sobre la base del art. 6(1)(a) del RGPD y del § 25 (1) de la TTDSG, siempre que el consentimiento incluya el almacenamiento de cookies o el acceso a información en el dispositivo terminal del usuario (por ejemplo, huella digital del dispositivo) en el sentido de la TTDSG. Este consentimiento puede revocarse en cualquier momento.",
        sub2analisys: "Análisis de Google",
        parrafo4analisys: "Este sitio web utiliza funciones del servicio de análisis web Google Analytics. El proveedor de este servicio es Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublín 4, Irlanda.",
        parrafo5analisys: "Google Analytics permite al operador del sitio web analizar los patrones de comportamiento de los visitantes del sitio web. Para ello, el operador del sitio web recibe diversos datos de los usuarios, como las páginas visitadas, el tiempo que han permanecido en la página, el sistema operativo utilizado y el origen del usuario. Estos datos se asignan al dispositivo final del usuario. No se realiza ninguna asignación a un ID de usuario.",
        parrafo6analisys: "Google Analytics utiliza tecnologías que permiten el reconocimiento del usuario con el fin de analizar sus patrones de comportamiento (por ejemplo, cookies o huellas digitales del dispositivo). La información sobre el uso del sitio web recopilada por Google se transmite, por regla general, a un servidor de Google en Estados Unidos, donde se almacena.",
        parrafo7analisys: "El uso de estos servicios se basa en su consentimiento de conformidad con el art. 6(1)(a) del RGPD y el § 25(1) de la TTDSG. Puede revocar su consentimiento en cualquier momento.",
        parrafo8analisys: "La transmisión de datos a EE. UU. se basa en las cláusulas contractuales estándar (SCC) de la Comisión Europea. Puede encontrar más información aquí: https://privacy.google.com/businesses/controllerterms/mccs/ .",
        sub3analisys: "Anonimización de IP",
        parrafo9analisys: "En este sitio web hemos activado la función de anonimización de IP. De esta forma, Google acortará su dirección IP dentro de los estados miembros de la Unión Europea o en otros estados que hayan ratificado el Convenio sobre el Espacio Económico Europeo antes de su transmisión a los Estados Unidos. Solo en casos excepcionales se transmitirá la dirección IP completa a un servidor de Google en los Estados Unidos y se acortará allí. En nombre del operador de este sitio web, Google utilizará esta información para evaluar su uso de este sitio web, para generar informes sobre las actividades del sitio web y para prestar otros servicios al operador de este sitio web relacionados con el uso del sitio web y de Internet. La dirección IP transmitida por su navegador en relación con Google Analytics no se combinará con otros datos en posesión de Google.",
        sub4analisys: "Complemento del navegador",
        parrafo10analisys: "Puede evitar el registro y el procesamiento de sus datos por parte de Google descargando e instalando el complemento del navegador disponible en el siguiente enlace: https://tools.google.com/dlpage/gaoptout?hl=es .",
        parrafo11analisys: "Para obtener más información sobre el tratamiento de datos de usuarios por parte de Google Analytics, consulte la declaración de privacidad de Google en: https://support.google.com/analytics/answer/6004245?hl=es .",
        sub5analisys: "Parámetros demográficos proporcionados por Google Analytics",
        parrafo12analisys: "Este sitio web utiliza la función de “características demográficas” de Google Analytics para poder mostrar a los visitantes del sitio web anuncios publicitarios compatibles con la red publicitaria de Google. Esto permite crear informes que contienen información sobre la edad, el sexo y los intereses de los visitantes del sitio web. Las fuentes de esta información son la publicidad basada en intereses de Google, así como los datos de los visitantes obtenidos de proveedores externos. Estos datos no se pueden asignar a una persona específica. Tiene la opción de desactivar esta función en cualquier momento realizando los cambios pertinentes en la configuración de publicidad en su cuenta de Google o puede prohibir en general el registro de sus datos por parte de Google Analytics como se explica en el apartado “Oposición al registro de datos”.",
        sub6analisys: "Tratamiento de datos por contrato",
        parrafo13analisys: "Hemos celebrado un contrato de tratamiento de datos con Google y aplicamos al máximo las estrictas disposiciones de las autoridades alemanas de protección de datos al utilizar Google Analytics.",
        newsletter: "6. Boletín informativo",
        sub1newsletter: "Datos del boletín informativo",
        parrafo1newsletter: "Si desea recibir el boletín informativo que se ofrece en el sitio web, necesitamos que nos facilite una dirección de correo electrónico y datos que nos permitan comprobar que usted es el titular de la dirección de correo electrónico facilitada y que está de acuerdo con recibir el boletín informativo. No se recogen otros datos o solo se recogen de forma voluntaria. Para gestionar el boletín informativo, utilizamos proveedores de servicios de boletines informativos, que se describen a continuación.",
        sub2newsletter: "Rapidmail",
        parrafo2newsletter: "Este sitio web utiliza Rapidmail para el envío de boletines informativos. El proveedor es rapidmail GmbH, Augustinerplatz 2, 79098 Friburgo de Brisgovia, Alemania.",
        parrafo3newsletter: "Los servicios de Rapidmail se pueden utilizar, entre otras cosas, para organizar y analizar el envío de boletines informativos. Los datos que introduzca para suscribirse al boletín informativo se almacenarán en los servidores de Rapidmail en Alemania.",
        sub3newsletter: "Análisis de datos por Rapidmail",
        parrafo4newsletter: "Para fines analíticos, los correos electrónicos enviados a través de Rapidmail se etiquetan con un denominado «píxel de seguimiento», que se conecta a los servidores de Rapidmail una vez abierto el correo electrónico. De este modo, es posible determinar si un correo electrónico con boletín informativo se ha abierto realmente.",
        parrafo5newsletter: "Con la ayuda de Rapidmail también podemos determinar si el destinatario ha hecho clic en los enlaces del mensaje del boletín informativo y en qué enlaces. Todos los enlaces integrados en el correo electrónico son los denominados enlaces de seguimiento que nos permiten contabilizar sus clics. Si no desea permitir un análisis por parte de Rapidmail, deberá darse de baja del boletín informativo. Para ello, le facilitamos un enlace en cada mensaje del boletín informativo. Además, también puede darse de baja del boletín informativo directamente en el sitio web.",
        parrafo6newsletter: "Para obtener más detalles sobre las funciones de análisis de Rapidmail, siga este enlace: https://de.rapidmail.wiki/kategorien/statistiken/ .",
        sub4newsletter: "Base legal",
        parrafo7newsletter: "El tratamiento de los datos se realiza sobre la base de su consentimiento (artículo 6, apartado 1, letra a) del RGPD). Puede revocar en cualquier momento el consentimiento que haya otorgado dándose de baja del boletín informativo. Esto se entiende sin perjuicio de la legalidad de las operaciones de tratamiento de datos que se hayan llevado a cabo antes de su revocación.",
        sub5newsletter: "Periodo de almacenamiento",
        parrafo8newsletter: "Los datos que nos facilite con el fin de suscribirse al boletín informativo se almacenarán en nuestra base de datos hasta que usted cancele su suscripción al boletín informativo o al proveedor del servicio de boletines informativos y se eliminarán de la lista de distribución del boletín informativo una vez que se dé de baja del boletín informativo. Los datos que almacenemos con otros fines no se verán afectados.",
        parrafo9newsletter: "Si cancela su suscripción a la lista de distribución de boletines informativos, su dirección de correo electrónico podrá ser almacenada por nosotros o por el proveedor del servicio de boletines informativos en una lista negra, si dicha acción es necesaria para evitar futuros envíos. Los datos de la lista negra se utilizan únicamente para este fin y no se combinan con otros datos. Esto sirve tanto a su interés como a nuestro interés en cumplir con los requisitos legales al enviar boletines informativos (interés legítimo en el sentido del art. 6(1)(f) del RGPD). El almacenamiento en la lista negra es indefinido. Puede oponerse al almacenamiento si sus intereses prevalecen sobre nuestro interés legítimo.",
        parrafo10newsletter: "Para obtener más detalles, consulte la Política de Protección de Datos de Rapidmail en: https://www.rapidmail.de/datensicherheit .",
        tools: "7. Complementos y herramientas",
        sub1tools: "Vimeo",
        parrafo1tools: "Este sitio web utiliza complementos del portal de vídeos Vimeo. El proveedor es Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.",
        parrafo2tools: "Si visita una de las páginas de nuestro sitio web en las que se ha integrado un vídeo de Vimeo, se establecerá una conexión con los servidores de Vimeo. Como consecuencia, el servidor de Vimeo recibirá información sobre cuáles de nuestras páginas ha visitado. Además, Vimeo recibirá su dirección IP. Esto también ocurrirá si no ha iniciado sesión en Vimeo o no tiene una cuenta en Vimeo. La información registrada por Vimeo se transmitirá al servidor de Vimeo en los Estados Unidos.",
        parrafo3tools: "Si ha iniciado sesión en su cuenta de Vimeo, permite que Vimeo asigne directamente sus patrones de navegación a su perfil personal. Puede evitarlo cerrando la sesión de su cuenta de Vimeo.",
        parrafo4tools: "Vimeo utiliza cookies o tecnologías de reconocimiento comparables (por ejemplo, huellas dactilares del dispositivo) para reconocer a los visitantes del sitio web.",
        parrafo5tools: "El uso de Vimeo se basa en nuestro interés en presentar nuestros contenidos online de forma atractiva. De conformidad con el art. 6(1)(f) del RGPD, se trata de un interés legítimo. Si se ha obtenido el consentimiento correspondiente, el tratamiento se lleva a cabo exclusivamente sobre la base del art. 6(1)(a) del RGPD y del § 25 (1) de la TTDSG, siempre que el consentimiento incluya el almacenamiento de cookies o el acceso a información en el dispositivo del usuario (por ejemplo, huella digital del dispositivo) en el sentido de la TTDSG. Este consentimiento puede revocarse en cualquier momento.",
        parrafo6tools: "La transmisión de datos a EE. UU. se basa en las cláusulas contractuales estándar (SCC) de la Comisión Europea y, según Vimeo, en “intereses comerciales legítimos”. Puede encontrar más información aquí: https://vimeo.com/privacy .",
        parrafo7tools: "Para obtener más información sobre cómo Vimeo maneja los datos de los usuarios, consulte la Política de privacidad de datos de Vimeo en: https://vimeo.com/privacy .",
        sub2tools: "Google Maps",
        parrafo8tools: "Este sitio web utiliza el servicio de mapas Google Maps. El proveedor es Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublín 4, Irlanda.",
        parrafo9tools: "Para poder utilizar las funciones de Google Maps, es necesario almacenar su dirección IP. Por regla general, esta información se transmite a un servidor de Google en Estados Unidos, donde se archiva. El operador de este sitio web no tiene control sobre la transmisión de datos. En caso de que Google Maps esté activado, Google tiene la opción de utilizar Google Fonts para la representación uniforme de las fuentes. Cuando acceda a Google Maps, su navegador cargará las fuentes web necesarias en la memoria caché de su navegador para mostrar correctamente el texto y las fuentes.",
        parrafo10tools: "Utilizamos Google Maps para presentar nuestros contenidos online de forma atractiva y para que las ubicaciones que aparecen en nuestro sitio web sean más fáciles de encontrar. Esto constituye un interés legítimo en el sentido del art. 6(1)(f) del RGPD. Si se ha obtenido el consentimiento correspondiente, el tratamiento se lleva a cabo exclusivamente sobre la base del art. 6(1)(a) del RGPD y del § 25 (1) de la TTDSG, siempre que el consentimiento incluya el almacenamiento de cookies o el acceso a información en el dispositivo del usuario (por ejemplo, la huella digital del dispositivo) en el sentido de la TTDSG. Este consentimiento puede revocarse en cualquier momento.",
        parrafo11tools: "La transmisión de datos a EE. UU. se basa en las cláusulas contractuales estándar (SCC) de la Comisión Europea. Puede encontrar más información aquí: https://privacy.google.com/businesses/gdprcontrollerterms/ y https://privacy.google.com/businesses/gdprcontrollerterms/sccs/ .",
        parrafo12tools: "Para obtener más información sobre el manejo de los datos del usuario, revise la Declaración de privacidad de datos de Google en: https://policies.google.com/privacy?hl=es .",
        custom: "8. Servicios personalizados",
        sub1custom: "Manejo de datos de solicitantes",
        parrafo1custom: "Ofrecemos a los visitantes de nuestro sitio web la posibilidad de enviarnos solicitudes de empleo (por ejemplo, por correo electrónico, por correo postal o enviando el formulario de solicitud de empleo en línea). A continuación, le informaremos sobre el alcance, la finalidad y el uso de los datos personales que recopilamos de usted en relación con el proceso de solicitud. Le aseguramos que la recopilación, el procesamiento y el uso de sus datos se realizarán de conformidad con los derechos de privacidad de datos aplicables y todas las demás disposiciones legales y que sus datos siempre se tratarán de forma estrictamente confidencial.",
        sub2custom: "Alcance y finalidad de la recogida de datos",
        parrafo2custom: "Si nos envía una solicitud de empleo, trataremos los datos personales relacionados (por ejemplo, datos de contacto y comunicación, documentos de solicitud, notas tomadas durante las entrevistas de trabajo, etc.) si son necesarios para tomar una decisión sobre el establecimiento de una relación laboral. Los fundamentos jurídicos para lo anterior son el § 26 BDSG (negociación de una relación laboral) de acuerdo con la legislación alemana, el art. 6(1)(b) del RGPD (negociación de contratos generales) y, siempre que nos haya dado su consentimiento, el art. 6(1)(a) del RGPD. Puede revocar cualquier consentimiento dado en cualquier momento. Dentro de nuestra empresa, sus datos personales solo se compartirán con personas que estén involucradas en el procesamiento de su solicitud de empleo.",
        parrafo3custom: "Si su solicitud de empleo resulta en su contratación, los datos que ha facilitado se archivarán en virtud del § 26 BDSG y del art. 6(1)(b) del RGPD con el fin de implementar la relación laboral en nuestro sistema de procesamiento de datos.",
        sub3custom: "Periodo de archivo de datos",
        parrafo4custom: "Si no podemos ofrecerle un trabajo o si usted rechaza una oferta de trabajo o retira su solicitud, nos reservamos el derecho a conservar los datos que nos ha facilitado sobre la base de nuestros intereses legítimos (art. 6(1)(f) del RGPD) durante un máximo de 6 meses a partir de la finalización del proceso de solicitud (rechazo o retirada de la solicitud). Posteriormente, los datos se eliminarán y los documentos físicos de la solicitud se destruirán. El almacenamiento sirve, en particular, como prueba en caso de litigio. Si resulta evidente que los datos serán necesarios una vez transcurrido el plazo de 6 meses (por ejemplo, debido a un litigio inminente o pendiente), la eliminación solo se llevará a cabo cuando ya no sea aplicable el propósito del almacenamiento posterior.",
        parrafo5custom: "También podrá tener lugar un almacenamiento más prolongado si usted ha dado su consentimiento (Artículo 6(1)(a) del RGPD) o si los requisitos legales de retención de datos impiden la eliminación."
      }
    },
    en: {
      footer: {
        company: "DOSATRONIC GmbH",
        address: "Zuppingerstraße 8",
        city: "88213 Ravensburg",
        country: "Germany",
        Telefono1: "+49-751-29512-0",
        Fax: "+49-751-29512-190",
        mail: "info@dosatronic.de",
        condiciones: "GENERAL TERMS AND CONDITIONS",
        aviso: "LEGAL NOTICE",
        proteccion: "DATA PROTECTION",
        titulocondiciones: "GENERAL TERMS AND CONDITIONS",
        punto1: "I. Scope of application",
        parrafo1punto1: "1. The present terms and conditions of delivery shall apply exclusively; deviating conditions or conditions contrary of the customer shall only apply provided the supplier approved of this in writing.",
        parrafo2punto1: "2. The present General Terms and Conditions of Delivery shall also apply to subsequent orders and to replacement parts deliveries without necessitating repeated pointing out of this fact.",
        parrafo3punto1: "3. Supplements and representations as well as modifications or amendments to a contract concluded in writing or by fax/e-mail must be in writing.",
        punto2: "II. Offer and order confirmation",
        parrafo1punto2: "1. Offers shall only be binding provided a time limit for acceptance is stated in the offer. To be legally binding, offers shall require the written confirmations of the supplier.",
        parrafo2punto2: "2. The supplier reserves any titles to and copyrights in figures, drawings, calculations, and other offer documentation and similar information  of physical and non-physical type – also in electronic form,  these may only be disclosed to third parties on the supplier’s written approval and shall be immediately returned to the supplier on request if no order is awarded to the supplier.",
        punto3: "III: Scope of deliveries and services",
        parrafo1punto3: "1. The deliveries and services are determined based on the mutual written declarations. If no such declarations exist, the written order confirmation of the supplier shall be decisive. For mere sales contracts the agreed upon delivery provisions shall be interpreted according to the  INCOTERMS valid at the conclusion of the contract.",
        parrafo2punto3: "2. Data in brochures catalogues or general technical documentation shall only be binding if reference is made to them in writing.",
        parrafo3punto3: "3. The costs for an agreed mounting and assembly, including all and any required ancillary costs such as travel expenses or costs for the transport of tools or personal luggage shall be remunerated separately by the customer, if not otherwise agreed upon.",
        parrafo4punto3: "4. If software is part of the delivery scope, the customer shall be granted a non-exclusive right of use in the software. The customer may copy or edit the software only in the legally permissible scope.",
        parrafo5punto3: "5. Partial deliveries shall be permissible, provided it is reasonable for the customer, considering the interests of both the supplier and the customer.",
        parrafo6punto3: "6. in case of deliveries abroad, the supplier’s obligation shall ve under the proviso that any necessary export livences are granted.",
        punto4: "IV. Prices and terms of payment.",
        parrafo1punto4: "1. All prices shall be in EURO unless otherwise stated. They shall apply to mere delivery transactions “ex works” (EXW), exclusive of packaging.",
        parrafo2punto4: "2. The prives do not include any turnover tay. This tay is itemized separately in the invoice in the statutory amount applicable at the date of invoicing.",
        parrafo3punto4: "3. the deduction of discounts shall require a special agreement in writing.",
        parrafo4punto4: "4. If not otherwise showin in the order confirmation, the sales price shall be due for payment 30 days from invoice date without any deduction.",
        parrafo5punto4: "5. If the customer does not comply with the date for payment, the customer shall pay default interest in the amount of 8 percentage points above the baye interest rate pursuant to §247 German Civil Code from the due date. Payment of further damages remains reserved.",
        parrafo6punto4: "6. If not otherwise agreed upon, the delivery of goods for deliveries abroad shall be under the proviso that an irrevocable commercial letter of credit is issued by the customer in favour of the supplier, and confirmed by a German banking institution.",
        parrafo7punto4: "7. In case of delayed payment, the supplier may suspend the performance of his own obligations until total payment was received, giving written notice to the customer.",
        parrafo8punto4: "8. the customer may only set off claims or assert a right of retention, provided these are undisputed or have become non-appealable.",
        punto5: "V. Time-limits for deliveres or services",
        parrafo1punto5: "1… With regard to time-limits, the mutual written declarations ro, in the absence of suich declarations, the written order confirmation of the supplier shall be decisive. The time-limit shall be deemed observed, provided all and any documentation to be provided by the customer are received in time, and all and any required permits, relases, in particular plans, are provided, and the agreed upon terms of payment and other obligations are met by the customer. If these prerequisites are not met in time, the time-limit shall be prolonged reasonably; this shall not apply if the supplier is responsible for the delay.",
        parrafo2punto5: "2. If non-observance of the time-limits is the result of force majeur, e.g. mobilization, war, riot or similar events, e. g. strike or lock-out, the agreed upon time-limits shall be prolonged reasonably.",
        parrafo3punto5: "3. If mounting and assembly are not part of the agreed upon services, the time-limit shall be deemed observed if the goods ready for operation were shipped or collected within the time-limit. Should the delivery be delayed for reasons for which the suctomer is responsible, the time-limit shall be deemed observed upon notification of readiness for shipment.",
        parrafo4punto5: "4. If the supplier is responsible for the non-observance of the time-limit, the customer, provided the customer suffered an actual loss, may request compensation for delay for each full week of delay of a macimum of 0.5%, however, not exceeding 5% of the price for the part of the delivery which could not be taken into relecant operation because of the delay. Claims for compensation of the customer exceeding the limits stipulated in item 5.4 shall be excluded in all cases of delayed delivery or service, also after expiry of any grace period set to the supplier, This shall not apply to the extent mandatory liability exists in cases of intent, gross negligence or personal injury; a shift of the burden of proof to the disadvantage of the sucomer is not given in this case.",
        parrafo5punto5: "5. The suctomer’s right to withdraw after ineffectual expiry of a grace period for the supplier shall remain unaffected. The grace period, however, must be reasonable and amount to at least four weeks.",
        parrafo6punto5: "6. If shipment or delivery are delayed for more than one month after notice of readiness for shipment on the customer’s request, warehouse charges in the amount of 0.5% of the price of the delivery goods, however, not exceeding a total of 5%, may be charged to the cusomter for each month started. The parties to the contract shall remain free to furnish proof of higher or lower warehouse charges.",
        punto6: "VI. Passage of utility and rist; insurance; packaging",
        parrafo1punto6: "1. The risk of deliveries and services rendered by the supplier shall pass to the customer as follows, even in case of deliveries freight paid.",
        parrafo1punto6a: "a) for deliveries without mounting or assembly, even in case of partial deliveries, if these have been shipped or collected. Shipments shall be insured by the supplier against the usual transport risks upon wish and at the expense of the customer. If such insurance exists, the supplier shall be immediately notified about any damages to goods in transit.",
        parrafo1punto6b: "b) for deliveries with mounting or assembly on the day of acceptance in the customer’s operations or, if agreed upon, after perdect test operation.",
        parrafo2punto6: "2. If the shipment, delivery, start, performance of mounting or assembly acceptance in the customer’s oerations or test operation is/are delayed for reasons attributable to the customer or if the customer delays acceptance for other reasons, the risk shall pass to the customer.",
        parrafo3punto6: "3. The shipment is in principle made in standard packagings of the supplier. The latter shall be entitled to choose special types of packaging deemed necessary in the supplier’s discretion. The costs of these packagings shall be borne by the customer.",
        punto7: "VII. Mounting and assembly",
        previopunto7: "The mounting, assembly and installation of the equipment and devices of the supplier may only be performed by specialists, observing the supplier’s guidelines and the applicable technical standards. If mounting and/or assembly are performed by the supplier, the following provisions shall apply, if not otherwise agreed upon in writing:",
        parrafo1punto7: "1. The customer shall assume and provide in time at the customer’s expense:",
        parrafo1punto7a: "a) all earthwork, construction work and other different ancillary work, including the required specialists and auxiliary staff, materials and tools.",
        parrafo1punto7b: "b) the commodities and materials such as scaffolds, cranes and elevators and other devices, fuels, lubricants, and chemicals required for assembly and commissioning.",
        parrafo1punto7c: "c) energy and water at the site of use, including connections, heating, and illumination,",
        parrafo1punto7d: "d) sufficiently large, suitable, dry and lockable rooms at the assembly site for storing machine parts, fixings, materials, and tools etc., and suitable working and recreation rooms for the assembly staff, including appropriate sanitary installations. For the protection of the supplier’s property and the assembly staff, the customer shall also take the measures he normally would take to protect his own property.",
        parrafo1punto7e: "e) protective clothing and protective devices which are necessary because of special  circumstances at the assembly site.",
        parrafo2punto7: "2. Prior to the start of the assembly work, the customer shall unsolicitedly provide the required information about the position of subsurface energy, gas, water conduits or similar installations as well as the required data on statics.",
        parrafo3punto7: "3. Prior to the start of mounting or assembly, the additions and objects required to start thw work must be at the mounting or assembly site and all preparations prior to start of the installation must be advanced such that the mounting or assembly can be started as agreed upon and can be performed without any interruptions. Access routes and the mounting or assembly site must be flattened and clear of any objects.",
        parrafo4punto7: "4. Should mounting, assembly or commissioning be delayed for reasons beyond the control of the supplier, the customer shall bear the costs for waiting time and additionally required tracels of the supplier or the assembly staff in an adequate amount.",
        parrafo5punto7: "5. If a plant cannot be installed immediately after delivery, the customer shall be responsible for a proper storage according to the supplier’s guidelines.",
        parrafo6punto7: "6. the customer shall provide the supplier with weekly information on the duration of the working hours of the assembly staff and shall immediately confirm the completion of moountin, assembly or commissioning.",
        parrafo7punto7: "7. The commission may only be performed by technicians achnowleged by the supplier and according to the supplier’s instruction. The technicians shall be entitled to refuse commissioning of the plant if the operating conditions to be procided by the customer do not guarantee a sate operation of the plant. The customer shall bear the costs of any delay in commissioning incurred to the supplier.",
        parrafo8punto7: "8. Should the supplier request acceptance of the delliveries and services after completion, the customer shall be obliged to do so within two weeks. Otherwise, the acceptance shall be deemed made. The acceptance shall be deemed made, too, if the delivery foods and services – also after completion of an agreed test phase, if any – have been taken in use.",
        punto8: "VIII. Warranty",
        parrafo1punto8: "1. Should goods delivered or services rendered by the supplier porve to be defective because they do not possess the agreed quality or because thay are not suitable for the agreed or usual use, the supplier shall in its discretion either remedy the parts or services concerned or deliver or render them again at no cost within the limitation period, provided the cause of the defect already existed at the time of risk passing.",
        parrafo2punto8: "2. Claims for material defects become stautebarred after 12 months. The time-limit shall start with passing of the risk (item6). The above provisions shall not apply to the extent the law mandatorily prescribes longer time-limits according to §§438(1) no. 2 German Civil Code (goods for edifes), §479(1) German Civil Code (right of recourse); and §634a German Civil Code  (Structural defects). The warranty period may be prolonged up to 60 months in suitable cases, provided the customer concludes a maintenance contract for the corresponding period.",
        parrafo3punto8: "3. The customer shall immediately give notice of defects to the supplier.",
        parrafo4punto8: "4. IN the event of notives of defects, payments of the customer may be retained in the volume  which shows a reasonable ratio to the material defects incurred. The customer may retain payments only if a notice of defect is given whose justification is beyond doubt. If the notive of defect is given wrongfully, the supplier shall be entitled to request from the customer compensation for the expenses incurred to the supplier. At first, the supplier shall always be given the opportunity to postperform within a reasonable time-limit. The customer shall grand the supplier the time and opportuinity required to do so. Should the customer refuse this, the supplier shall be exempted from the liability for defects.",
        parrafo5punto8: "5. If the post-performance fils, the customer - notwithstanding possible claims for damages- may withdraw from the contract of reduce the compensation. The customer may not claim  compensation for futile expenses.",
        parrafo6punto8: "6. Claims for defects do not exist in case of mino deviations from the agreed or suumed quality, minor imparment of usability, natural wear or damages incurred after passing of the risk because of incorrect or negligible handling, excessive use, unsuitable operating material, faulty construction work, unsuitable subsoil or because of special external influences which are not established in the contract as well as in case of non-reproducible software errors. If the customer or third parties perform improper modifications or repair work, no claims for defects will exits for these and the resulting consequences.",
        parrafo7punto8: "7. The supplier shall not bear the additional expenditure, in particular transport, tracelling, labour and material costs, which result from the fact that the subject matter for the delivery was later transported to a different location than the customer’s branch or the original place of destination, except the transport corresponds to its proper use.",
        parrafo8punto8: "8. In all cases, the customer shall be obliged to take any possible and reasonable steps to keep the expense for the purpose of portperformance as small as possible. The supplier shall  participate in the costs for a recall campaign only if this is necessary based on the factual and legal suitauon. The customer shall be obliged to either return devfectice profucts or keep them ready for insprecion and rests, in the supplier’s distreion.",
        parrafo9punto8: "9. Claims for recourse of the customer against the supplier shall only exist to the extent the cusomter did not conclude any agreements with the customter’s purchaser which exceed the statutory claims for defrects. In addition, item 8.8 shall apply correspondingly to the scope of the right for recourse of the cusomter against the supplier.",
        parrafo10punto8: "10. Furthermore, item 11 (other claims for damages) also applies to claims for damages. More extensive or other claims than stipulated in the present item 8 of the customer against the supplier and its persons employed in performing the obligations beacuase of a material defect shall be excluded.",
        punto9: "IX. Industrail property rights and copyright; defects of title",
        parrafo1punto9: "1. If not otherwise agreed upon, the supplier shall be obliged to render the delivery free of any industrial property rights and copyrights of third parties (hereinafter called: property rights) solely in the country of the place of delivery. To the extent a third party makes justifies claims against the customer because of infringement of property rights by deliveries rendered by the supplier and used according to contract, the supplier shall be liable to the customer within the  time-limit stipulated in item 8.2 as follows:",
        parrafo1punto9a: "a) the supplier shall at the supplier’s expense and in the supplier’s discretion either obtain a right of use for the deliveries concerned, modify them such that the property right is not infringed or exchange them. Should the supplier not be able to do so under reasonable conditions, the  customer shall be entitled to statutory cancellation of reduction rights. The cusomter may not claim compensation for futile expenses.",
        parrafo1punto9b: "b) the supplier’s obligation to pay damages shall be subject to item11.",
        parrafo1punto9c: "c) the above mentioned obligations of the supplier shall only be given provided the customer immediately informs the supplier in writing about claims asserted by third parties, refuses to acknowledge an infringement, and all and any measures of protection and settlement proceedings remain reserced to the supplier. Should the customer discontinue the use of the delivery goods for the purpose of reducing the damage or for other reasons, the customer shall be obliged to inform the third party about the fact that the discontinuance of use does not represent an acknowledgement of the property rights infringement.",
        parrafo2punto9: "2. Claims of the customer shall be excluded the customer is responsible for the property rights infringement.",
        parrafo3punto9: "3. Claims of the customer shall furthermore be excluded to the extent the property rights infringement was caused by special standards stipulated by the customer, by use not foreseeable by the supplier or by the fact that the delivery goods were modified by the cusomter or used in conjunction with products not delivered by the supplier.",
        parrafo4punto9: "4. In the ecent of property rights infringements, the claims of the customer stipulated in item 9.1 a) shall apply, in addition the provisions in item 8.4, item 8.5, and item 8.10 shall apply correspondingly. In case of other defects of title, the provisions of item 8 shall apply correspondingly. More extensice or other claims than stipulated in the present item 9 of the customer against the supplier and its persons employed in performing the obligations because of a defect of title shall be excluded.",
        punto10: "X. Impossibility; adaptation of contract",
        parrafo1punto10: "1. To the extent the delivery is not possible, the customer shall be entitled to calim damages, except the impossibility is attributable to the supplier. The customerÄs claims for damages,  however, shall be limited to 10% of the part of the delivery which cannot be taken into relecant operation because of the impossibility. This limitation shall not apply to the extent mandatory  liability exists in cases of intent, gross negligence or personal injury; a shift of the burden of  proof to the disadvantage of the customer is not fiven in this case. The customer’s right to withdraw from the contract shall remain unaffected.",
        parrafo2punto10: "2. In case of temporary impossibility, item 5 (time-limits) shall apply.",
        parrafo3punto10: "3. Should unforeseeable events in the sense of item 5.2 signigicantly change the economic  meaning or the content of the delivery or have a significant effect on the suppliers operations,   the contract shall be adapted in good fraith. To the extent this is not economically reasonable, the supplier shall be entiteld to withdraw from the contract. If the supplier intends to assert this right to withdraw, the supplier after having obtained knowledge about the scope of the event, shall immediately informa the customer to this effect. This shall also apply if a prolongation of the delivery period was agreed upon with the customer at first.",
        punto11: "XI. Other claims for damages.",
        parrafo1punto11: "1. Claims of the customer for damages and indemnity of expenses because of infringement of main or ancillary obligations from the contractual obligation, from trot or other legal grounds shall beexcluded.",
        parrafo2punto11: "2. This exclusion shall not apply to the extent mandatory liability exists, e.g. pursuant to the  German Procut Liability Act, in cases of intent, gross negligence, for personal injury, because of the assumption of a guaranty for the presence of a property, or infringement of material contractual obligations. The damages for the infringement of material contractual obligations shall, hovwever, be limited to the contracttypical, foreseeable damage, provided no intent or gross negligence exists or liability exists for personal injury or because of the assumption of a guaranty for the presence of a preotery. A shift of the burden of porrf to the disadvantage of the customer is not related with the above provisions.",
        parrafo3punto11: "3. If mandatory law does not stipulate longer limitation periods, all and any claims for damages shall become statue-barred within the time-limits stated in item8.2.",
        punto12: "XII. Warranty and product description",
        parrafo1punto12: "1. Warranties shall only be effective if made in writing.",
        parrafo2punto12: "2. Data described in catalogues, tender documentation and other printed matter as well as  general advertising statements do not represent an offer for the conclusion of a warranty agreement.",
        punto13: "XIII. Reservation of title",
        parrafo1punto13: "1. The supplier reserves the title in the delivery goods (reserve goods) until the customer has made the complete payment due from the business relationship. The reservation of title shall also include the acknowledged balance, to the extent the supplier enters the claims against the  customer in current account (current account reserve).",
        parrafo2punto13: "2. If the supplier accepts return of the delivery goods, this shall mean a withdrawal from the contract. Upon return of the goods purchased, the supplier shall be entitled to realize these  goods; the realization fees. In the event the delivery goods are attached, the supplier shall be entitled to withdraw from the contract without setting a time-limit. In case of attachment or other interventions by thirs parties, the cusomter shall immediately inform the supplier in writing for the supplier to be able to file action pursuant to §771 German Code of Civil Procedure. To the extent third parties are not able to reimburse the judicial and extrajucicial expenses of an action  pursuant to §771 German Code of Civil Procedure to the supplier, the customer shall be liable for the loss incurred by the supplier.",
        parrafo3punto13: "3. The customer shall be entitled to resell the delivery goods in the proper course of business; however, the customer already now assigns to the supplier. All and any claims in the amount of the final invoice amount, including value added tax, which are due to him from resale against his purchaser or third partes, independent of the fact whether the delivery goods werde resold  without or after processing. The customer shall be entitled to collect this claim also after its assignment. The supplier’s power to c ollect the claim himself remains unaffected; the supplier may request the customer to disclose the assigned claims and their debtors, to provide the  information required for collection, to provide the relecant documentation and to inform the debtor (third party) about the assignment.",
        parrafo4punto13: "4. The processing and transformation of the delivery goods by the customer shall always be  performed for the supplier. If the ddelivery goods are processed together with other objects not belonging to the supplier, the supplier shall obtain co-ownership in the new object in the proportion of the value of the delivery goods to the other processed objects at the time of processing. Otherwise, the same provisions as for reserve goods shall apply to the matter created by processing. The customer shall also assign to the supplier the claims for securing the supplier’s claims which are due to the customer against a third party by joining the delivery godds with a real property.",
        parrafo5punto13: "5. If the delivery godds are mixed inseparately with other objects not belonging to the supplier, the supplier shall obtain co-ownership in the new object in the proportion of the value of the delivery goods to the other mixed objects at the time of mixing. If the mixing is done such that the matter of the customer is to be deemed a main component, the parties agree that the  customer shall assign to the supplier proportional co-ownership. The customer shall keep the sole property or coproperty for the supplier. The customer shall insure it in the usual scope against usual risks such as e.g. fire, theft, water, and similar. The customer shall already now assign to the supplier the customer’s claims for compensation which are due to him from damages of the avoce mentioned type against insurers or other third parties, in the amount of the invoice value of the goods.",
        parrafo6punto13: "6. If the realizable value of the securities due to the supplier exceed the supplier’s total claims by more than 10%, the supplier shall be obliged to release in the supplier’s discretion securities on request of the customer or a third party affected by the excessive security.",
        punto14: "XIV. Place of jurisdiction and applicable law",
        parrafo1punto14: "1. The place of jurisdiction for all and any disputes arising out of the present contract shall be the supplier’s headquarters, provided the customer is a merchant: The supplier, however, shall be entitled to file action at the customer’s headquarters.",
        parrafo2punto14: "2. German laW shall apply to the contractual relationships. The UN Convention on the International Sale of Goods (CISG) shall be excluded.",
        punto15: "XV. Severability",
        parrafo1punto15: "Should any individual provisions od the present contract be legally ineffective, the validity of the remaining provisions shall in no way be affected. This shall not apply if abiding by the contract would constitute an unreasonable hardship for the other party to the contract.",
        legal: "LEGAL NOTICE",
        information: "Information pursuant to Sect. 5 German Telemedia Act (TMG)",
        parrafo1information: "DOSATRONIC GmbH",
        parrafo2information: "Zuppingerstraße 8",
        parrafo3information: "88213 Ravensburg",
        parrafo4information: "Commercial Register: HRB 552723",
        parrafo4information: "Registration court: Ulm",
        parrafo5information: "Represented by:",
        parrafo6information: "GF Verena Holzmann",
        parrafo7information: "GF Selina Wachter",
        contact: "Contact",
        parrafo1contact: "Phone: +49-751-29512-0", //+34 93 517 20 08
        parrafo2contact: "Telefax: +49-751-29512-190",
        parrafo3contact: "E-mail: info@dosatronic.de", //info@dosatroniciberia.es
        vat: "VAT ID",
        parrafo1vat: "Sales tax identification number according to Sect. 27 a of the Sales Tax Law:",
        parrafo2vat: "DE812973283",
        dispute: "EU dispute resolution",
        parrafo1dispute: "The European Commission provides a platform for online dispute resolution (ODR): https://ec.europa.eu/consumers/odr/.",
        parrafo2dispute: "Our e-mail address can be found above in the site notice.",
        dispute2: "Dispute resolution proceedings in front of a consumer arbitration board",
        parrafo1dispute2: "We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.",
        images: "Images:",
        parrafo1images: "84367010, Fotolia, Creator: digieye",
        parrafo2images: "83867300, Fotolia, Creator: somchaij",
        parrafo3images: "124411039, Fotolia, Creator: samafoto",
        parrafo4images: "119530550, Fotolia, Creator: yotrakbutda",
        parrafo5images: "69418987, Fotolia, Creator: bigfoot",
        parrafo6images: "178882165, Fotolia, Creator: psdesign1",
        parrafo7images: "Metallverarbeitung Emotion: © photosoup/stock.adobe.com",
        parrafo8images: "Metallverarbeitung: © leungchopan/stock.adobe.com",
        parrafo9images: "Tierzucht und Landwirtschaft Emotion: © Kadmy/stock.adobe.com",
        parrafo10images: "Tierzucht und Landwirtschaft: © 88studio/stock.adobe.com",
        parrafo11images: "Wasseraufbereitung Emotion: © OrthsMedien/stock.adobe.com",
        parrafo12images: "Fahrzeugwäsche Emotion: © PixPartout/stock.adobe.com",
        parrafo13images: "Fahrzeugwäsche: © industrieblick/stock.adobe.com",
        parrafo14images: "Druckindustrie Emotion: ©  rh2010/stock.adobe.com",
        parrafo15images: "Lebensmittelindustrie Emotion: © agnormark/stock.adobe.com",
        parrafo16images: "Product images, DOSATRONIC GmbH, Creator: Herr Hubert Müller, technical editor, DOSATRONIC GmbH, Zuppingerstraße 8, 88213 Ravensburg",
        parrafo17images: "Google Maps Icon: Freepik von www.flaticon.com, CC 3.0 BY",
        parrafo18images: "Flaggen Icons von Flagpedia. lizenziert unter Public Domain",
        parrafo19images: "Schweine Icon: Icons made by photo3idea_studio from www.flaticon.com",
        parrafo20images: "Medizin Icon: Icons made by SumberRejeki from www.flaticon.com",
        parrafo21images: "Icons made by Freepik from www.flaticon.com",
        parrafo22images: "Icons made by photo3idea_studio from www.flaticon.com",
        parrafo23images: "Icons made by smashingstocks from www.flaticon.com",
        parrafo24images: "Icons made by Those Icons from www.flaticon.com",
        parrafo25images: "Icons made by Kemalmoe from www.flaticon.com",
        privacy: "DATA PRIVACY POLICY",
        tituloprivacy: "Privacy Policy",
        protecciondatos: "1. An overview of data protection",
        sub1protecciondatos: "General information",
        parrafo1protecciondatos: "The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.",
        sub2protecciondatos: "Data recording on this website",
        subsub1protecciondatos: "Who is the responsible party for the recording of data on this website (i.e., the “controller”)?",
        parrafo2protecciondatos: "The data on this website is processed by the operator of the website, whose contact information is available under section “Information about the responsible party (referred to as the “controller” in the GDPR)” in this Privacy Policy.",
        subsub2protecciondatos: "How do we record your data?",
        parrafo3protecciondatos: "We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.",
        parrafo4protecciondatos: "Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information is recorded automatically when you access this website.",
        subsub3protecciondatos: "What are the purposes we use your data for?",
        parrafo5protecciondatos: "A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.",
        subsub4protecciondatos: "What rights do you have as far as your information is concerned?",
        parrafo6protecciondatos: "You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.",
        parrafo7protecciondatos: "Please do not hesitate to contact us at any time if you have questions about this or any other data protection related issues.",
        sub3protecciondatos: "Analysis tools and tools provided by third parties",
        parrafo8protecciondatos: "There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs.",
        parrafo9protecciondatos: "For detailed information about these analysis programs please consult our Data Protection Declaration below.",
        hosting: "2. Hosting",
        parrafo1hosting: "We are hosting the content of our website at the following provider:",
        sub1hosting: "Mittwald",
        parrafo2hosting: "The provider is the Mittwald CM Service GmbH & Co. KG, Königsberger Straße 4-6, 32339 Espelkamp, Germany (hereinafter referred to as Mittwald).",
        parrafo3hosting: "For details, please view the data privacy policy of Mittwald: https://www.mittwald.de/datenschutz.",
        parrafo4hosting: "We use Mittwald on the basis of Art. 6(1)(f) GDPR. We have a legitimate interest in the most reliable depiction of our website possible. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.",
        subsub1hosting: "Data processing",
        parrafo5hosting: "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR.",
        general: "3. General information and mandatory information",
        sub1general: "Data protection",
        parrafo1general: "The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.",
        parrafo2general: "Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.",
        parrafo3general: "We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access.",
        sub2general: "Information about the responsible party (referred to as the “controller” in the GDPR)",
        parrafo4general: "The data processing controller on this website is:",
        parrafo5general: "DOSATRONIC GmbH",
        parrafo6general: "Zuppingerstraße 8",
        parrafo7general: "88213 Ravensburg",
        parrafo8general: "Phone: +49-751-29512-0",
        parrafo9general: "E-mail: info@dosatronic.de",
        parrafo10general: "The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail addresses, etc.).",
        sub3general: "Storage duration",
        parrafo11general: "Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.",
        sub4general: "General information on the legal basis for the data processing on this website",
        parrafo12general: "If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of personal data to third countries, the data processing is also based on Art. 49 (1)(a) GDPR. If you have consented to the storage of cookies or to the access to information in your end device (e.g., via device fingerprinting), the data processing is additionally based on § 25 (1) TTDSG. The consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in the following paragraphs of this privacy policy.",
        sub5general: "Information on data transfer to the USA and other non-EU countries",
        parrafo13general: "Among other things, we use tools of companies domiciled in the United States or other from a data protection perspective non-secure non-EU countries. If these tools are active, your personal data may potentially be transferred to these non-EU countries and may be processed there. We must point out that in these countries, a data protection level that is comparable to that in the EU cannot be guaranteed. For instance, U.S. enterprises are under a mandate to release personal data to the security agencies and you as the data subject do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S. agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for surveillance purposes. We have no control over these processing activities.",
        sub6general: "Revocation of your consent to the processing of data",
        parrafo14general: "A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.",
        sub7general: "Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)",
        parrafo15general: "IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).",
        parrafo16general: "IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).",
        sub8general: "Right to log a complaint with the competent supervisory agency",
        parrafo17general: "In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.",
        sub9general: "Right to data portability",
        parrafo18general: "You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.",
        sub10general: "Information about, rectification and eradication of data",
        parrafo19general: "Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time.",
        sub11general: "Right to demand processing restrictions",
        parrafo12general: "You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in the following cases:",
        ul1general: "In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.",
        ul2general: "If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.",
        ul3general: "If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.",
        ul4general: "If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.",
        parrafo13general: "If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.",
        sub12general: "SSL and/or TLS encryption",
        parrafo14general: "For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.",
        parrafo15general: "If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.",
        sub13general: "Rejection of unsolicited e-mails",
        parrafo16general: "We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in our Site Notice to send us promotional and information material that we have not expressly requested. The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for instance via SPAM messages.",
        recording: "4. Recording of data on this website",
        sub1recording: "Cookies",
        parrafo1recording: "Our websites and pages use what the industry refers to as “cookies.” Cookies are small data packages that do not cause any damage to your device. They are either stored temporarily for the duration of a session (session cookies) or they are permanently archived on your device (permanent cookies). Session cookies are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device until you actively delete them, or they are automatically eradicated by your web browser.",
        parrafo2recording: "Cookies can be issued by us (first-party cookies) or by third-party companies (so-called third-party cookies). Third-party cookies enable the integration of certain services of third-party companies into websites (e.g., cookies for handling payment services).",
        parrafo3recording: "Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the absence of these cookies (e.g., the shopping cart function or the display of videos). Other cookies may be used to analyze user behavior or for promotional purposes.",
        parrafo4recording: "Cookies, which are required for the performance of electronic communication transactions, for the provision of certain functions you want to use (e.g., for the shopping cart function) or those that are necessary for the optimization (required cookies) of the website (e.g., cookies that provide measurable insights into the web audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the storage of required cookies to ensure the technically error-free and optimized provision of the operator’s services. If your consent to the storage of the cookies and similar recognition technologies has been requested, the processing occurs exclusively on the basis of the consent obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be revoked at any time.",
        parrafo5recording: "You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or activate the delete-function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited.",
        parrafo6recording: "Which cookies and services are used on this website can be found in this privacy policy.",
        sub2recording: "Consent with Cookiebot",
        parrafo7recording: "Our website uses consent technology from Cookiebot to obtain your consent to the storage of certain cookies on your end device or for the use of certain technologies and to document this in a data protection-compliant manner. The provider of this technology is Cybot A/S, Havnegade 39, 1058 Copenhagen, Denmark (hereinafter “Cookiebot”).",
        parrafo8recording: "When you enter our website, a connection is established with the Cookiebot servers to obtain your consent and provide you with other explanations regarding the use of cookies. Cookiebot will then store a cookie in your browser to identify the consent you have given or its revocation. The data collected in this way is stored until you request us to delete it, delete the Cookiebot cookie itself or the purpose for which the data is stored no longer applies. Mandatory legal storage obligations remain unaffected.",
        parrafo9recording: "Cookiebot is used to obtain the legally required consent for the use of cookies. The legal basis for this is Art. 6(1)(c) GDPR.",
        sub3recording: "Data processing",
        parrafo10recording: "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR.",
        sub4recording: "Contact form",
        parrafo11recording: "If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent.",
        parrafo12recording: "The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any time.",
        parrafo13recording: "The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions, in particular retention periods.",
        sub5recording: "Request by e-mail, telephone, or fax",
        parrafo14recording: "If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.",
        parrafo15recording: "These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a contract or is required for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be revoked at any time.",
        parrafo16recording: "The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.",
        analisys: "5. Analysis tools and advertising",
        sub1analisys: "Google Tag Manager",
        parrafo1analisys: "We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
        parrafo2analisys: "The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other technologies on our website. The Google Tag Manager itself does not create any user profiles, does not store cookies, and does not carry out any independent analyses. It only manages and runs the tools integrated via it. However, the Google Tag Manager does collect your IP address, which may also be transferred to Google’s parent company in the United States.",
        parrafo3analisys: "The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the quick and uncomplicated integration and administration of various tools on his website. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.",
        sub2analisys: "Google Analytics",
        parrafo4analisys: "This website uses functions of the web analysis service Google Analytics. The provider of this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.",
        parrafo5analisys: "Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized operating system and the user’s origin. This data is assigned to the respective end device of the user. An assignment to a user-ID does not take place.",
        parrafo6analisys: "Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is, as a rule transferred to a Google server in the United States, where it is stored.",
        parrafo7analisys: "The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at any time.",
        parrafo8analisys: "Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: https://privacy.google.com/businesses/controllerterms/mccs/.",
        sub3analisys: "IP anonymization",
        parrafo9analisys: "On this website, we have activated the IP anonymization function. As a result, your IP address will be abbreviated by Google within the member states of the European Union or in other states that have ratified the Convention on the European Economic Area prior to its transmission to the United States. The full IP address will be transmitted to one of Google’s servers in the United States and abbreviated there only in exceptional cases. On behalf of the operator of this website, Google shall use this information to analyze your use of this website to generate reports on website activities and to render other services to the operator of this website that are related to the use of the website and the Internet. The IP address transmitted in conjunction with Google Analytics from your browser shall not be merged with other data in Google’s possession.",
        sub4analisys: "Browser plug-in",
        parrafo10analisys: "You can prevent the recording and processing of your data by Google by downloading and installing the browser plugin available under the following link: https://tools.google.com/dlpage/gaoptout?hl=en.",
        parrafo11analisys: "For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration at: https://support.google.com/analytics/answer/6004245?hl=en.",
        sub5analisys: "Demographic parameters provided by Google Analytics",
        parrafo12analisys: "This website uses the “demographic characteristics” function of Google Analytics, to be able to display to the website visitor compatible ads within the Google advertising network. This allows reports to be created that contain information about the age, gender, and interests of the website visitors. The sources of this information are interest-related advertising by Google as well as visitor data obtained from third-party providers. This data cannot be allocated to a specific individual. You have the option to deactivate this function at any time by making pertinent settings changes for advertising in your Google account or you can generally prohibit the recording of your data by Google Analytics as explained in section “Objection to the recording of data”.",
        sub6analisys: "Contract data processing",
        parrafo13analisys: "We have executed a contract data processing agreement with Google and are implementing the stringent provisions of the German data protection agencies to the fullest when using Google Analytics.",
        newsletter: "6. Newsletter",
        sub1newsletter: "Newsletter data",
        parrafo1newsletter: "If you would like to receive the newsletter offered on the website, we require an e-mail address from you as well as information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive the newsletter. Further data is not collected or only on a voluntary basis. For the handling of the newsletter, we use newsletter service providers, which are described below.",
        sub2newsletter: "Rapidmail",
        parrafo2newsletter: "This website uses Rapidmail for the sending of newsletters. The provider is the rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg i.Br., Germany.",
        parrafo3newsletter: "Rapidmail services can, among other things, be used to organize and analyze the sending of newsletters. The data you enter for the purpose of subscribing to the newsletter are archived on Rapidmail servers in Germany.",
        sub3newsletter: "Data analysis by Rapidmail",
        parrafo4newsletter: "For analytical purposes, e-mails sent via Rapidmail are tagged with a so-called “Tracking Pixel”, which connects to Rapidmail’s servers once the e-mail is opened. As a result, it is possible to determine whether a newsletter e-mail was actually opened.",
        parrafo5newsletter: "With the assistance of Rapidmail we are also able to determine whether and which links in the newsletter message the recipient clicked. All links integrated into the e-mail are so-called Tracking Links that enable us to count your clicks. If you do not want to permit an analysis by Rapidmail, you must unsubscribe from the newsletter. We provide a link for you to do this in every newsletter message. Moreover, you can also unsubscribe from the newsletter right on the website.",
        parrafo6newsletter: "For more details on the Rapidmail analysis functions, please follow this link: https://de.rapidmail.wiki/kategorien/statistiken/.",
        sub4newsletter: "Legal basis",
        parrafo7newsletter: "The data is processed based on your consent (Art. 6(1)(a) GDPR). You may revoke any consent you have given at any time by unsubscribing from the newsletter. This shall be without prejudice to the lawfulness of any data processing transactions that have taken place prior to your revocation.",
        sub5newsletter: "Storage period",
        parrafo8newsletter: "The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you unsubscribe from the newsletter or the newsletter service provider and deleted from the newsletter distribution list after you unsubscribe from the newsletter. Data stored for other purposes with us remain unaffected.",
        parrafo9newsletter: "After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the newsletter service provider in a blacklist, if such action is necessary to prevent future mailings. The data from the blacklist is used only for this purpose and not merged with other data. This serves both your interest and our interest in complying with the legal requirements when sending newsletters (legitimate interest within the meaning of Art. 6(1)(f) GDPR). The storage in the blacklist is indefinite. You may object to the storage if your interests outweigh our legitimate interest.",
        parrafo10newsletter: "For more details, please consult the Data Protection Regulations of Rapidmail at: https://www.rapidmail.de/datensicherheit.",
        tools: "7. Plug-ins and Tools",
        sub1tools: "Vimeo",
        parrafo1tools: "This website uses plug-ins of the video portal Vimeo. The provider is Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.",
        parrafo2tools: "If you visit one of the pages on our website into which a Vimeo video has been integrated, a connection to Vimeo’s servers will be established. As a consequence, the Vimeo server will receive information as to which of our pages you have visited. Moreover, Vimeo will receive your IP address. This will also happen if you are not logged into Vimeo or do not have an account with Vimeo. The information recorded by Vimeo will be transmitted to Vimeo’s server in the United States.",
        parrafo3tools: "If you are logged into your Vimeo account, you enable Vimeo to directly allocate your browsing patterns to your personal profile. You can prevent this by logging out of your Vimeo account.",
        parrafo4tools: "Vimeo uses cookies or comparable recognition technologies (e.g. device fingerprinting) to recognize website visitors.",
        parrafo5tools: "The use of Vimeo is based on our interest in presenting our online content in an appealing manner. Pursuant to Art. 6(1)(f) GDPR, this is a legitimate interest. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.",
        parrafo6tools: "Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission and, according to Vimeo, on “legitimate business interests”. Details can be found here: https://vimeo.com/privacy.",
        parrafo7tools: "For more information on how Vimeo handles user data, please consult the Vimeo Data Privacy Policy under: https://vimeo.com/privacy.",
        sub2tools: "Google Maps",
        parrafo8tools: "This website uses the mapping service Google Maps. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.",
        parrafo9tools: "To enable the use of the Google Maps features, your IP address must be stored. As a rule, this information is transferred to one of Google’s servers in the United States, where it is archived. The operator of this website has no control over the data transfer. In case Google Maps has been activated, Google has the option to use Google Fonts for the purpose of the uniform depiction of fonts. When you access Google Maps, your browser will load the required web fonts into your browser cache, to correctly display text and fonts.",
        parrafo10tools: "We use Google Maps to present our online content in an appealing manner and to make the locations disclosed on our website easy to find. This constitutes a legitimate interest as defined in Art. 6(1)(f) GDPR. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.",
        parrafo11tools: "Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: https://privacy.google.com/businesses/gdprcontrollerterms/ and https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.",
        parrafo12tools: "For more information on the handling of user data, please review Google’s Data Privacy Declaration under: https://policies.google.com/privacy?hl=en.",
        custom: "8. Custom Services",
        sub1custom: "Handling applicant data",
        parrafo1custom: "We offer website visitors the opportunity to submit job applications to us (e.g., via e-mail, via postal services on by submitting the online job application form). Below, we will brief you on the scope, purpose and use of the personal data collected from you in conjunction with the application process. We assure you that the collection, processing, and use of your data will occur in compliance with the applicable data privacy rights and all other statutory provisions and that your data will always be treated as strictly confidential.",
        sub2custom: "Scope and purpose of the collection of data",
        parrafo2custom: "If you submit a job application to us, we will process any affiliated personal data (e.g., contact and communications data, application documents, notes taken during job interviews, etc.), if they are required to make a decision concerning the establishment or an employment relationship. The legal grounds for the aforementioned are § 26 BDSG according to German Law (Negotiation of an Employment Relationship), Art. 6(1)(b) GDPR (General Contract Negotiations) and – provided you have given us your consent – Art. 6(1)(a) GDPR. You may revoke any consent given at any time. Within our company, your personal data will only be shared with individuals who are involved in the processing of your job application.",
        parrafo3custom: "If your job application should result in your recruitment, the data you have submitted will be archived on the grounds of § 26 BDSG and Art. 6(1)(b) GDPR for the purpose of implementing the employment relationship in our data processing system.",
        sub3custom: "Data Archiving Period",
        parrafo4custom: "If we are unable to make you a job offer or you reject a job offer or withdraw your application, we reserve the right to retain the data you have submitted on the basis of our legitimate interests (Art. 6(1)(f) GDPR) for up to 6 months from the end of the application procedure (rejection or withdrawal of the application). Afterwards the data will be deleted, and the physical application documents will be destroyed. The storage serves in particular as evidence in the event of a legal dispute. If it is evident that the data will be required after the expiry of the 6-month period (e.g., due to an impending or pending legal dispute), deletion will only take place when the purpose for further storage no longer applies.",
        parrafo5custom: "Longer storage may also take place if you have given your agreement (Article 6(1)(a) GDPR) or if statutory data retention requirements preclude the deletion."
      }
    },
    de: {
      footer: {
        company: "DOSATRONIC GmbH",
        address: "Zuppingerstraße 8",
        city: "88213 Ravensburg",
        country: "Deutschland",
        Telefono1: "+49-751-29512-0",
        Fax: "+49-751-29512-190",
        condiciones: "AGB",
        aviso: "IMPRESSUM",
        proteccion: "DATENSCHUTZ",
        titulocondiciones: "AGB",
        punto1: "I. Geltungsbereich",
        parrafo1punto1: "1. Diese Lieferbedingungen gelten ausschließlich; abweichende Bedingungen oder entgegenstehende Bedingungen des Bestellers gelten nur, wenn der Lieferer diesen schriftlich zugestimmt hat.",
        parrafo2punto1: "2. Die vorliegenden Allgemeinen Lieferbedingungen gelten auch für Nachbestellungen und Ersatzteillieferungen, ohne dass es eines erneuten Hinweises darauf bedarf.",
        parrafo3punto1: "3. Ergänzungen und Zusicherungen sowie Änderungen oder Ergänzungen eines schriftlich oder per Fax/E-Mail geschlossenen Vertrages bedürfen der Schriftform.",
        punto2: "II. Angebot und Auftragsbestätigung",
        parrafo1punto2: "1. Angebote sind nur verbindlich, wenn im Angebot eine Frist zur Annahme angegeben ist. Angebote bedürfen zu ihrer Rechtsverbindlichkeit der schriftlichen Bestätigung durch den Anbieter.",
        parrafo2punto2: "2. An Abbildungen, Zeichnungen, Berechnungen und sonstigen Angebotsunterlagen und ähnlichen Informationen körperlicher und unkörperlicher Art - auch in elektronischer Form - behält sich der Lieferer Eigentums- und Urheberrechte vor, sie dürfen nur mit schriftlicher Zustimmung des Lieferers Dritten zugänglich gemacht werden und sind, wenn der Auftrag dem Lieferer nicht erteilt wird, diesem auf Verlangen unverzüglich zurückzugeben.",
        punto3: "III: Umfang der Lieferungen und Leistungen",
        parrafo1punto3: "1. Für den Umfang der Lieferungen und Leistungen sind die beiderseitigen schriftlichen Erklärungen maßgebend. Liegen solche Erklärungen nicht vor, so ist die schriftliche Auftragsbestätigung des Lieferers maßgebend. Bei reinen Kaufverträgen sind die vereinbarten Lieferbedingungen nach den bei Vertragsabschluss gültigen INCOTERMS auszulegen.",
        parrafo2punto3: "2. Angaben in Prospekten, Katalogen oder allgemeinen technischen Unterlagen sind nur verbindlich, wenn auf sie schriftlich Bezug genommen wird.",
        parrafo3punto3: "3. Die Kosten für eine vereinbarte Aufstellung und Montage einschließlich aller erforderlichen Nebenkosten wie Reisekosten oder Kosten für den Transport des Handwerkszeugs oder des persönlichen Gepäcks sind vom Besteller gesondert zu vergüten, soweit nichts anderes vereinbart ist.",
        parrafo4punto3: "4. Soweit im Lieferumfang Software enthalten ist, wird dem Kunden ein nicht ausschließliches Nutzungsrecht an der Software eingeräumt. Der Kunde darf die Software nur in dem gesetzlich zulässigen Umfang vervielfältigen oder bearbeiten.",
        parrafo5punto3: "5. Teillieferungen sind zulässig, soweit sie dem Besteller unter Berücksichtigung der Interessen des Lieferanten und des Bestellers zumutbar sind.",
        parrafo6punto3: "6. bei Lieferungen ins Ausland steht die Verpflichtung des Lieferanten unter dem Vorbehalt, dass die erforderlichen Ausfuhrgenehmigungen erteilt werden.",
        punto4: "IV. Preise und Zahlungsbedingungen.",
        parrafo1punto4: '1. Alle Preise verstehen sich, soweit nicht anders angegeben, in EURO. Sie gelten für reine Liefergeschäfte "ab Werk" (EXW), ausschließlich Verpackung.',
        parrafo2punto4: "2. In den Preisen ist keine Umsatzabgabe enthalten. Diese wird in der Rechnung in der zum Zeitpunkt der Rechnungsstellung geltenden gesetzlichen Höhe gesondert ausgewiesen.",
        parrafo3punto4: "3. Der Abzug von Skonto bedarf einer besonderen schriftlichen Vereinbarung.",
        parrafo4punto4: "4. Sofern sich aus der Auftragsbestätigung nichts anderes ergibt, ist der Kaufpreis 30 Tage ab Rechnungsdatum ohne jeden Abzug zur Zahlung fällig.",
        parrafo5punto4: "5. Hält der Kunde den Zahlungstermin nicht ein, hat er ab Fälligkeit Verzugszinsen in Höhe von 8 Prozentpunkten über dem Basiszinssatz nach § 247 BGB zu zahlen. Die Zahlung eines weitergehenden Schadens bleibt vorbehalten.",
        parrafo6punto4: "6. Sofern nichts anderes vereinbart ist, erfolgt die Lieferung von Waren bei Lieferungen ins Ausland unter dem Vorbehalt, dass ein unwiderrufliches kommerzielles Akkreditiv vom Kunden zugunsten des Lieferanten ausgestellt und von einem deutschen Bankinstitut bestätigt wird.",
        parrafo7punto4: "7. Im Falle eines Zahlungsverzugs kann der Lieferant die Erfüllung seiner eigenen Verpflichtungen bis zum Eingang der vollständigen Zahlung aussetzen, indem er den Kunden schriftlich davon in Kenntnis setzt.",
        parrafo8punto4: "8. kann der Kunde nur aufrechnen oder ein Zurückbehaltungsrecht geltend machen, soweit diese unbestritten oder rechtskräftig festgestellt sind.",
        punto5: "V. Fristen für Lieferungen oder Dienstleistungen",
        parrafo1punto5: "1… Für die Einhaltung von Fristen sind die beiderseitigen schriftlichen Erklärungen und bei Fehlen solcher Erklärungen die schriftliche Auftragsbestätigung des Lieferers maßgebend. Die Einhaltung der Frist setzt den rechtzeitigen Eingang sämtlicher vom Besteller zu liefernden Unterlagen, die Beibringung der erforderlichen Genehmigungen, Freigaben, insbesondere von Plänen, sowie die Einhaltung der vereinbarten Zahlungsbedingungen und sonstigen Verpflichtungen durch den Besteller voraus. Werden diese Voraussetzungen nicht rechtzeitig erfüllt, so verlängern sich die Fristen angemessen; dies gilt nicht, wenn der Lieferer die Verzögerung zu vertreten hat.",
        parrafo2punto5: "2. Ist die Nichteinhaltung der Fristen auf höhere Gewalt, z. B. Mobilmachung, Krieg, Aufruhr, oder auf ähnliche Ereignisse, z. B. Streik, Aussperrung, zurückzuführen, verlängern sich die vereinbarten Fristen angemessen.",
        parrafo3punto5: "3. Gehören Aufstellung und Montage nicht zu den vereinbarten Leistungen, so ist die Frist gewahrt, wenn die betriebsbereite Ware innerhalb der Frist zum Versand gebracht oder abgeholt worden ist. Verzögert sich die Lieferung aus Gründen, die der Auftraggeber zu vertreten hat, so gilt die Frist mit der Meldung der Versandbereitschaft als eingehalten.",
        parrafo4punto5: "4. Sofern der Lieferer die Nichteinhaltung der Frist zu vertreten hat, kann der Besteller, sofern ihm ein tatsächlicher Schaden entstanden ist, eine Verzugsentschädigung für jede vollendete Woche des Verzuges von je 0,5%, insgesamt jedoch höchstens 5% des Preises für den Teil der Lieferung verlangen, der wegen des Verzuges nicht in zweckdienlichen Betrieb genommen werden konnte. Entschädigungsansprüche des Bestellers, die über die in Ziffer 5.4 genannten Grenzen hinausgehen, sind in allen Fällen verspäteter Lieferung oder Leistung, auch nach Ablauf einer dem Lieferer etwa gesetzten Nachfrist, ausgeschlossen. Dies gilt nicht, soweit in Fällen des Vorsatzes, der groben Fahrlässigkeit oder für Körperschäden zwingend gehaftet wird; eine Änderung der Beweislast zum Nachteil des Bestellers ist hiermit nicht verbunden.",
        parrafo5punto5: "5. Das Recht des Bestellers zum Rücktritt nach fruchtlosem Ablauf einer dem Lieferer gesetzten Nachfrist bleibt unberührt. Die Nachfrist muss jedoch angemessen sein und mindestens vier Wochen betragen.",
        parrafo6punto5: "6. Werden Versand oder Zustellung auf Wunsch des Bestellers um mehr als einen Monat nach Anzeige der Versandbereitschaft verzögert, kann dem Besteller für jeden angefangenen Monat Lagergeld in Höhe von 0,5% des Preises der Gegenstände der Lieferungen, höchstens jedoch insgesamt 5%, berechnet werden. Der Nachweis höherer oder niedrigerer Lagerkosten bleibt den Vertragsparteien unbenommen.",
        punto6: "VI. Passage von Nutzen und Rist; Versicherung; Verpackung",
        parrafo1punto6: "1. Die Gefahr für Lieferungen und Leistungen des Lieferers geht auch bei frachtfreier Lieferung wie folgt auf den Besteller über.",
        parrafo1punto6a: "a) bei Lieferungen ohne Aufstellung oder Montage, auch bei Teillieferungen, wenn diese zum Versand gebracht oder abgeholt worden sind. Sendungen werden auf Wunsch und Kosten des Bestellers vom Lieferer gegen die üblichen Transportrisiken versichert. Besteht eine solche Versicherung, so ist der Lieferant unverzüglich über Transportschäden zu unterrichten.",
        parrafo1punto6b: "b) bei Lieferungen mit Aufstellung oder Montage am Tage der Übernahme in eigenen Betrieb oder, soweit vereinbart, nach erfolgtem Probebetrieb.",
        parrafo2punto6: "2. Wenn der Versand, die Zustellung, der Beginn, die Durchführung der Aufstellung oder Montage, die Übernahme in eigenen Betrieb oder der Probebetrieb aus vom Besteller zu vertretenden Gründen verzögert wird oder wenn der Besteller aus sonstigen Gründen in Annahmeverzug kommt, so geht die Gefahr auf den Besteller über.",
        parrafo3punto6: "3. Der Versand erfolgt grundsätzlich in Standardverpackungen des Lieferanten. Dieser ist berechtigt, nach seinem Ermessen notwendig erscheinende Sonderverpackungen zu wählen. Die Kosten für diese Verpackungen gehen zu Lasten des Bestellers.",
        punto7: "VII. Montage und Zusammenbau",
        previopunto7: "Die Aufstellung, Montage und Installation der Anlagen und Geräte des Lieferanten darf nur von Fachleuten unter Beachtung der Richtlinien des Lieferanten und der geltenden technischen Normen durchgeführt werden. Erfolgt die Montage und/oder Aufstellung durch den Lieferanten, so gelten die nachfolgenden Bestimmungen, soweit nicht schriftlich etwas anderes vereinbart ist:",
        parrafo1punto7: "1. Der Kunde hat dies zu übernehmen und rechtzeitig auf seine Kosten zu besorgen:",
        parrafo1punto7a: "a) alle Erd-, Bau- und sonstigen Nebenarbeiten, einschließlich der dazu benötigten Fach- und Hilfskräfte, Materialien und Werkzeuge.",
        parrafo1punto7b: "b) die für die Montage und Inbetriebnahme erforderlichen Bedarfsgegenstände und -stoffe wie Gerüste, Kräne und Aufzüge sowie sonstige Vorrichtungen, Brennstoffe, Schmiermittel und Chemikalien.",
        parrafo1punto7c: "c) Energie und Wasser am Ort der Nutzung, einschließlich Anschlüsse, Heizung und Beleuchtung,",
        parrafo1punto7d: "d) bei der Montagestelle für die Aufbewahrung der Maschinenteile, Befestigungsmittel, Materialien, Werkzeuge usw. genügend große, geeignete, trockene und verschließbare Räume und für das Montagepersonal angemessene Arbeits- und Aufenthaltsräume einschließlich angemessener sanitärer Anlagen. Zum Schutz des Besitzes des Lieferers und des Montagepersonals hat der Besteller auch die Maßnahmen zu treffen, die er zum Schutz des eigenen Besitzes ergreifen würde.",
        parrafo1punto7e: "e) Schutzkleidung und Schutzvorrichtungen, die infolge besonderer Umstände der Montagestelle erforderlich sind.",
        parrafo2punto7: "2. Vor Beginn der Montagearbeiten hat der Besteller die nötigen Angaben über die Lage verdeckt geführter Strom-, Gas-, Wasserleitungen oder ähnlicher Anlagen sowie die erforderlichen statischen Angaben unaufgefordert zur Verfügung zu stellen.",
        parrafo3punto7: "3. Vor Beginn der Aufstellung oder Montage müssen sich die für die Aufnahme der Arbeiten erforderlichen Beistellungen und Gegenstände an der Aufstellungs- oder Montagestelle befinden und alle Vorarbeiten vor Beginn des Aufbaues so weit fortgeschritten sein, dass die Aufstellung oder Montage vereinbarungsgemäß begonnen und ohne Unterbrechung durchgeführt werden kann. Anfuhrwege und der Aufstellungs- oder Montageplatz müssen geebnet und von Gegenständen geräumt sein.",
        parrafo4punto7: "4. Verzögern sich die Aufstellung, Montage oder Inbetriebnahme durch nicht vom Lieferer zu vertretende Umstände, so hat der Besteller in angemessenem Umfang die Kosten für Wartezeit und zusätzlich erforderliche Reisen des Lieferers oder des Montagepersonals zu tragen.",
        parrafo5punto7: "5. Kann eine Anlage nicht sofort nach der Lieferung installiert werden, so ist der Kunde für eine ordnungsgemäße Lagerung nach den Richtlinien des Lieferanten verantwortlich.",
        parrafo6punto7: "6. hat der Besteller dem Lieferer wöchentlich die Dauer der Arbeitszeit des Montagepersonals mitzuteilen und die Beendigung der Aufstellung, Montage oder Inbetriebnahme unverzüglich zu bestätigen.",
        parrafo7punto7: "7. Die Inbetriebnahme darf nur durch vom Lieferer beauftragte Techniker und nach dessen Anweisungen erfolgen. Die Techniker sind berechtigt, die Inbetriebnahme der Anlage zu verweigern, wenn die vom Kunden zu beschaffenden Betriebsbedingungen einen ordnungsgemäßen Betrieb der Anlage nicht gewährleisten. Die dem Lieferer entstehenden Kosten einer verspäteten Inbetriebnahme trägt der Besteller.",
        parrafo8punto7: "8. Verlangt der Lieferer nach Fertigstellung die Abnahme der Lieferungen und Leistungen, so ist der Besteller verpflichtet, sie innerhalb von zwei Wochen vorzunehmen. Andernfalls gilt die Abnahme als erfolgt. Die Abnahme gilt gleichfalls als erfolgt, wenn die Lieferungen und Leistungen - auch nach Abschluss einer ggf. vereinbarten Testphase - in Gebrauch genommen worden sind.",
        punto8: "VIII. Garantie",
        parrafo1punto8: "1. Erweisen sich Lieferungen oder Leistungen des Lieferanten als mangelhaft, weil sie nicht die vereinbarte Beschaffenheit haben oder sich nicht für die vereinbarte oder gewöhnliche Verwendung eignen, so wird der Lieferant innerhalb der Verjährungsfrist nach seiner Wahl entweder die betreffenden Teile oder Leistungen nachbessern oder unentgeltlich neu liefern oder leisten, sofern die Ursache des Mangels bereits im Zeitpunkt des Gefahrübergangs vorlag.",
        parrafo2punto8: "2. Ansprüche wegen Sachmängeln verjähren in 12 Monaten. Die Frist beginnt mit dem Gefahrübergang (Ziffer6). Die vorstehenden Bestimmungen gelten nicht, soweit das Gesetz gemäß §§ 438 Abs. 1 Nr. 2 BGB (Verbrauchsgüterkauf), 479 Abs. 1 BGB (Rückgriffsanspruch) und § 634a BGB (Baumängel) längere Fristen zwingend vorschreibt. Die Gewährleistungsfrist kann in geeigneten Fällen auf bis zu 60 Monate verlängert werden, wenn der Kunde einen Wartungsvertrag für den entsprechenden Zeitraum abschließt.",
        parrafo3punto8: "3. Der Kunde hat Mängel unverzüglich gegenüber dem Lieferanten zu rügen.",
        parrafo4punto8: "4. Bei Mängelrügen dürfen Zahlungen des Bestellers in dem Umfang zurückgehalten werden, die in einem angemessenen Verhältnis zu den aufgetretenen Sachmängeln stehen. Der Besteller kann Zahlungen nur zurückbehalten, wenn eine Mängelrüge geltend gemacht wird, über deren Berechtigung kein Zweifel bestehen kann. Erfolgte die Mängelrüge zu Unrecht, ist der Lieferer berechtigt, die ihm entstandenen Aufwendungen vom Besteller ersetzt zu verlangen. Zunächst ist dem Lieferer stets Gelegenheit zur Nacherfüllung innerhalb angemessener Frist zu gewähren. Hierzu hat der Besteller dem Lieferer die erforderliche Zeit und Gelegenheit zu gewähren. Verweigert der Besteller dies, so ist der Lieferer von der Mängelhaftung befreit.",
        parrafo5punto8: "5. Schlägt die Nacherfüllung fehl, kann der Kunde - unbeschadet etwaiger Schadensersatzansprüche - vom Vertrag zurücktreten oder die Vergütung mindern. Ersatz für vergebliche Aufwendungen kann der Kunde nicht verlangen.",
        parrafo6punto8: "6. Mängelansprüche bestehen nicht bei nur unerheblicher Abweichung von der vereinbarten oder vorausgesetzten Beschaffenheit, bei nur unerheblicher Beeinträchtigung der Brauchbarkeit, bei natürlicher Abnutzung oder Schäden, die nach dem Gefahrübergang infolge fehlerhafter oder nachlässiger Behandlung, übermäßiger Beanspruchung, ungeeigneter Betriebsmittel, mangelhafter Bauarbeiten, ungeeigneten Baugrundes oder die aufgrund besonderer äußerer Einflüsse entstehen, die nach dem Vertrag nicht vorausgesetzt sind, sowie bei nicht reproduzierbaren Softwarefehlern. Werden vom Kunden oder von Dritten unsachgemäß Änderungen oder Instandsetzungsarbeiten vorgenommen, so bestehen für diese und die daraus entstehenden Folgen ebenfalls keine Mängelansprüche.",
        parrafo7punto8: "7. Der Lieferant trägt nicht die Mehraufwendungen, insbesondere Transport-, Wege-, Arbeits- und Materialkosten, die dadurch entstehen, dass der Gegenstand der Lieferung nachträglich an einen anderen Ort als die Niederlassung des Bestellers oder den ursprünglichen Bestimmungsort verbracht worden ist, es sei denn, die Verbringung entspricht seinem bestimmungsgemäßen Gebrauch.",
        parrafo8punto8: "8. In jedem Fall ist der Kunde verpflichtet, alles ihm Mögliche und Zumutbare zu tun, um den Aufwand zum Zwecke der Portabilität so gering wie möglich zu halten. An den Kosten einer Rückrufaktion wird sich der Lieferant nur beteiligen, wenn dies aufgrund der Sach- und Rechtslage erforderlich ist. Der Kunde ist verpflichtet, mangelhafte Produkte entweder zurückzusenden oder zur Besichtigung und Rückgabe bereitzuhalten, wobei der Lieferant in seinem Ermessen steht.",
        parrafo9punto8: "9. Rückgriffsansprüche des Bestellers gegen den Lieferer bestehen nur insoweit, als der Besteller mit seinem Abnehmer keine über die gesetzlichen Mängelansprüche hinausgehenden Vereinbarungen getroffen hat. Für den Umfang des Rückgriffsanspruchs des Bestellers gegen den Lieferer gilt ferner Ziffer 8.8 entsprechend.",
        parrafo10punto8: "10. Für Schadensersatzansprüche gilt ferner Ziffer 11 (Sonstige Schadensersatzansprüche). Weitergehende oder andere als die in dieser Ziffer 8 geregelten Ansprüche des Bestellers gegen den Lieferer und dessen Erfüllungsgehilfen wegen eines Sachmangels sind ausgeschlossen.",
        punto9: "IX. Gewerbliche Schutzrechte und Urheberrecht; Rechtsmängel",
        parrafo1punto9: "1. Sofern nichts anderes vereinbart ist, ist der Lieferer verpflichtet, die Lieferung lediglich im Land des Lieferorts frei von gewerblichen Schutzrechten und Urheberrechten Dritter (im Folgenden: Schutzrechte) zu erbringen. Sofern ein Dritter wegen der Verletzung von Schutzrechten durch vom Lieferer erbrachte, vertragsgemäß genutzte Lieferungen gegen den Besteller berechtigte Ansprüche erhebt, haftet der Lieferer gegenüber dem Besteller innerhalb der in Ziffer 8.2 bestimmten Frist wie folgt:",
        parrafo1punto9a: "a) wird der Lieferer auf seine Kosten und nach seiner Wahl entweder ein Nutzungsrecht für die betreffenden Lieferungen erwirken, sie so ändern, dass das Schutzrecht nicht verletzt wird, oder austauschen. Ist dies dem Lieferer nicht zu angemessenen Bedingungen möglich, stehen dem Besteller die gesetzlichen Minderungsrechte zu. Ersatz für vergebliche Aufwendungen kann der Besteller nicht verlangen.",
        parrafo1punto9b: "b) Die Verpflichtung des Lieferanten zur Leistung von Schadensersatz richtet sich nach Ziffer 11.",
        parrafo1punto9c: "c) Die vorstehend genannten Verpflichtungen des Lieferers bestehen nur, soweit der Besteller den Lieferer über die vom Dritten geltend gemachten Ansprüche unverzüglich schriftlich verständigt, eine Verletzung nicht anerkennt und dem Lieferer alle Abwehrmaßnahmen und Vergleichsverhandlungen vorbehalten bleiben. Stellt der Besteller die Nutzung des Liefergegenstandes zum Zwecke der Schadensminderung oder aus sonstigen Gründen ein, ist er verpflichtet, den Dritten darauf hinzuweisen, dass mit der Nutzungseinstellung kein Anerkenntnis einer Schutzrechtsverletzung verbunden ist.",
        parrafo2punto9: "2. Ansprüche des Kunden sind ausgeschlossen, soweit der Kunde die Schutzrechtsverletzung zu vertreten hat.",
        parrafo3punto9: "3. Ansprüche des Bestellers sind ferner ausgeschlossen, soweit die Schutzrechtsverletzung durch spezielle Vorgaben des Bestellers, durch eine vom Lieferer nicht voraussehbare Anwendung oder dadurch verursacht wird, dass der Liefergegenstand vom Besteller verändert oder zusammen mit nicht vom Lieferer gelieferten Produkten eingesetzt wird.",
        parrafo4punto9: "4. Im Falle von Schutzrechtsverletzungen gelten die in Ziffer 9.1 a) geregelten Ansprüche des Kunden, daneben gelten die Bestimmungen in Ziffer 8.4, Ziffer 8.5 und Ziffer 8.10 entsprechend. Bei Vorliegen sonstiger Rechtsmängel gelten die Bestimmungen der Ziffer 8 entsprechend. Weitergehende oder andere als die in dieser Ziffer 9 geregelten Ansprüche des Bestellers gegen den Lieferer und dessen Erfüllungsgehilfen wegen eines Rechtsmangels sind ausgeschlossen.",
        punto10: "X. Unmöglichkeit; Anpassung des Vertrags",
        parrafo1punto10: "1. Soweit die Lieferung unmöglich ist, ist der Besteller berechtigt, Schadensersatz zu verlangen, es sei denn, dass der Lieferer die Unmöglichkeit nicht zu vertreten hat. Jedoch beschränkt sich der Schadensersatzanspruch des Bestellers auf 10% desjenigen Teils der Lieferung, der wegen der Unmöglichkeit nicht in zweckdienlichen Betrieb genommen werden kann. Diese Beschränkung gilt nicht, soweit in Fällen des Vorsatzes, der groben Fahrlässigkeit oder für Personenschäden zwingend gehaftet wird; eine Änderung der Beweislast zum Nachteil des Bestellers ist hiermit nicht verbunden. Das Recht des Kunden zum Rücktritt vom Vertrag bleibt unberührt.",
        parrafo2punto10: "2. Im Falle einer vorübergehenden Unmöglichkeit gilt Punkt 5 (Fristen).",
        parrafo3punto10: "3. Sofern unvorhersehbare Ereignisse im Sinne von Ziffer 5.2 die wirtschaftliche Bedeutung oder den Inhalt der Lieferung erheblich verändern oder auf den Betrieb des Lieferers erheblich einwirken, wird der Vertrag unter Beachtung angemessener Fristen angepasst. Soweit dies wirtschaftlich nicht vertretbar ist, steht dem Lieferer das Recht zu, vom Vertrag zurückzutreten. Will der Lieferer von diesem Rücktrittsrecht Gebrauch machen, so hat er dies nach Erkenntnis der Tragweite des Ereignisses unverzüglich dem Besteller mitzuteilen. Dies gilt auch dann, wenn zunächst mit dem Besteller eine Verlängerung der Lieferfrist vereinbart war.",
        punto11: "XI. Sonstige Schadensersatzansprüche.",
        parrafo1punto11: "1. Ansprüche des Kunden auf Schadens- und Aufwendungsersatz wegen Verletzung von Haupt- oder Nebenpflichten aus dem Schuldverhältnis, aus unerlaubter Handlung oder aus sonstigen Rechtsgründen sind ausgeschlossen.",
        parrafo2punto11: "2. Dieser Ausschluss gilt nicht, soweit zwingend gehaftet wird, z.B. nach dem Produkthaftungsgesetz, in Fällen des Vorsatzes, der groben Fahrlässigkeit, für Personenschäden, wegen der Übernahme einer Garantie für das Vorhandensein einer Eigenschaft oder wegen der Verletzung wesentlicher Vertragspflichten. Der Schadensersatz für die Verletzung wesentlicher Vertragspflichten ist jedoch auf den vertragstypischen, vorhersehbaren Schaden begrenzt, soweit nicht Vorsatz oder grobe Fahrlässigkeit vorliegt oder wegen der Verletzung des Lebens, des Körpers oder der Gesundheit oder der Übernahme einer Beschaffenheitsgarantie gehaftet wird. Eine Änderung der Beweislast zum Nachteil des Kunden ist mit den vorstehenden Regelungen nicht verbunden.",
        parrafo3punto11: "3. Soweit das Gesetz nicht zwingend längere Verjährungsfristen vorsieht, verjähren alle Schadensersatzansprüche innerhalb der in Ziffer 8.2 genannten Fristen.",
        punto12: "XII. Garantie und Produktbeschreibung",
        parrafo1punto12: "1. Zusicherungen sind nur wirksam, wenn sie schriftlich erfolgen.",
        parrafo2punto12: "2. Die in Katalogen, Ausschreibungsunterlagen und sonstigen Drucksachen beschriebenen Daten sowie allgemeine Werbeaussagen stellen kein Angebot zum Abschluss eines Garantievertrages dar.",
        punto13: "XIII. Eigentumsvorbehalt",
        parrafo1punto13: "1. Der Lieferer behält sich das Eigentum an den Liefergegenständen vor (Vorbehaltsware), bis der Besteller die gesamte Forderung aus der Geschäftsverbindung beglichen hat. Der Eigentumsvorbehalt erstreckt sich auch auf den anerkannten Saldo, soweit der Lieferer Forderungen gegen den Besteller in laufende Rechnung bucht (Kontokorrentvorbehalt).",
        parrafo2punto13: "2. Nimmt der Lieferant den Liefergegenstand zurück, so liegt darin ein Rücktritt vom Vertrag. Der Lieferer ist nach Rücknahme der Kaufsache zu deren Verwertung befugt, die Verwertungskosten trägt er. Bei einer Pfändung des Liefergegenstandes ist der Lieferer berechtigt, ohne Fristsetzung vom Vertrag zurückzutreten. Bei Pfändungen oder sonstigen Eingriffen Dritter hat der Abnehmer den Lieferer unverzüglich schriftlich zu benachrichtigen, damit der Lieferer Klage gemäß § 771 ZPO erheben kann. Soweit der Dritte nicht in der Lage ist, dem Lieferer die gerichtlichen und außergerichtlichen Kosten einer Klage gemäß § 771 ZPO zu erstatten, haftet der Besteller für den dem Lieferer entstandenen Ausfall.",
        parrafo3punto13: "3. Der Besteller ist berechtigt, den Liefergegenstand im ordentlichen Geschäftsgang weiter zu veräußern; er tritt jedoch bereits jetzt alle Forderungen in Höhe des Faktura-Endbetrages an den Lieferer ab. Der Besteller ist berechtigt, den Liefergegenstand im ordentlichen Geschäftsgang weiterzuverkaufen; er tritt dem Lieferer jedoch bereits jetzt alle Forderungen in Höhe des Faktura-Endbetrages einschließlich Mehrwertsteuer ab, die ihm aus der Weiterveräußerung gegen seine Abnehmer oder Dritte erwachsen, und zwar unabhängig davon, ob der Liefergegenstand ohne oder nach Verarbeitung weiterverkauft worden ist. Zur Einziehung dieser Forderung ist der Besteller auch nach deren Abtretung ermächtigt. Die Befugnis des Lieferers, die Forderung selbst einzuziehen, bleibt hiervon unberührt; der Lieferer kann verlangen, dass der Besteller ihm die abgetretenen Forderungen und deren Schuldner bekannt gibt, alle zum Einzug erforderlichen Angaben macht, die dazugehörigen Unterlagen aushändigt und den Schuldnern (Dritten) die Abtretung mitteilt.",
        parrafo4punto13: "4. Die Verarbeitung oder Umbildung des Liefergegenstandes durch den Besteller wird stets für den Lieferer vorgenommen. Wird der Liefergegenstand mit anderen, dem Lieferer nicht gehörenden Gegenständen verarbeitet, so erwirbt der Lieferer das Miteigentum an der neuen Sache im Verhältnis des Wertes des Liefergegenstandes zu den anderen verarbeiteten Gegenständen zur Zeit der Verarbeitung. Für die durch Verarbeitung entstehende Sache gilt im übrigen das gleiche wie für die Vorbehaltsware. Der Besteller tritt dem Lieferer auch die Forderungen zur Sicherung der Forderungen des Lieferers ab, die dem Besteller durch die Verbindung des Liefergegenstandes mit einem Grundstück gegen einen Dritten erwachsen.",
        parrafo5punto13: "5. Wird der Liefergegenstand mit anderen, dem Lieferer nicht gehörenden Gegenständen untrennbar vermischt, so erwirbt der Lieferer das Miteigentum an der neuen Sache im Verhältnis des Wertes des Liefergegenstandes zu den anderen vermischten Gegenständen zum Zeitpunkt der Vermischung. Erfolgt die Vermischung in der Weise, dass die Sache des Bestellers als Hauptsache anzusehen ist, so gilt als vereinbart, dass der Besteller dem Lieferer anteilmäßig Miteigentum überträgt. Der Besteller verwahrt das Alleineigentum oder das Miteigentum für den Lieferer. Er wird es im üblichen Umfang gegen übliche Risiken wie z.B. Feuer, Diebstahl, Wasser u.ä. versichern. Der Besteller tritt dem Lieferer bereits jetzt seine Entschädigungsansprüche, die ihm aus Schäden der vorbezeichneten Art gegen Versicherungsgesellschaften oder sonstige Dritte zustehen, in Höhe des Rechnungswertes der Ware ab.",
        parrafo6punto13: "6. Übersteigt der realisierbare Wert der dem Lieferer zustehenden Sicherheiten dessen Gesamtforderungen um mehr als 10 %, so ist der Lieferer auf Verlangen des Bestellers oder eines durch die Übersicherung beeinträchtigten Dritten insoweit zur Freigabe von Sicherheiten nach Wahl des Lieferers verpflichtet.",
        punto14: "XIV. Gerichtsstand und anwendbares Recht",
        parrafo1punto14: "1. Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist der Sitz des Lieferers, sofern der Besteller Kaufmann ist; der Lieferer ist jedoch auch berechtigt, am Hauptsitz des Bestellers zu klagen.",
        parrafo2punto14: "2. Für die vertraglichen Beziehungen gilt deutsches Recht. Die Geltung des UN-Kaufrechts (CISG) ist ausgeschlossen.",
        punto15: "XV. Salvatorische Klausel",
        parrafo1punto15: "Sollten einzelne Bestimmungen dieses Vertrages rechtsunwirksam sein, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Das gilt nicht, wenn das Festhalten an dem Vertrag eine unzumutbare Härte für den anderen Vertragsteil darstellen würde.",
        legal: "IMPRESSUM",
        information: "Angaben gemäß § 5 TMG",
        parrafo1information: "DOSATRONIC GmbH",
        parrafo2information: "Zuppingerstraße 8",
        parrafo3information: "88213 Ravensburg",
        parrafo4information: "Handelsregister: HRB 552723",
        parrafo4information: "Registergericht: Ulm",
        parrafo5information: "Vertreten durch:",
        parrafo6information: "GF Verena Holzmann",
        parrafo7information: "GF Selina Wachter",
        contact: "Kontakt",
        parrafo1contact: "Phone: +49-751-29512-0", //+34 93 517 20 08
        parrafo2contact: "Telefax: +49-751-29512-190",
        parrafo3contact: "E-mail: info@dosatronic.de", //info@dosatroniciberia.es
        vat: "Umsatzsteuer-ID",
        parrafo1vat: "Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:",
        parrafo2vat: "DE812973283",
        dispute: "EU-Streitschlichtung",
        parrafo1dispute: "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.",
        parrafo2dispute: "Unsere E-Mail-Adresse finden Sie oben im Impressum.",
        dispute2: "Verbraucher­streit­beilegung/Universal­schlichtungs­stelle",
        parrafo1dispute2: "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
        images: "Bildquellen:",
        parrafo1images: "84367010, Fotolia, Creator: digieye",
        parrafo2images: "83867300, Fotolia, Creator: somchaij",
        parrafo3images: "124411039, Fotolia, Creator: samafoto",
        parrafo4images: "119530550, Fotolia, Creator: yotrakbutda",
        parrafo5images: "69418987, Fotolia, Creator: bigfoot",
        parrafo6images: "178882165, Fotolia, Creator: psdesign1",
        parrafo7images: "Metallverarbeitung Emotion: © photosoup/stock.adobe.com",
        parrafo8images: "Metallverarbeitung: © leungchopan/stock.adobe.com",
        parrafo9images: "Tierzucht und Landwirtschaft Emotion: © Kadmy/stock.adobe.com",
        parrafo10images: "Tierzucht und Landwirtschaft: © 88studio/stock.adobe.com",
        parrafo11images: "Wasseraufbereitung Emotion: © OrthsMedien/stock.adobe.com",
        parrafo12images: "Fahrzeugwäsche Emotion: © PixPartout/stock.adobe.com",
        parrafo13images: "Fahrzeugwäsche: © industrieblick/stock.adobe.com",
        parrafo14images: "Druckindustrie Emotion: ©  rh2010/stock.adobe.com",
        parrafo15images: "Lebensmittelindustrie Emotion: © agnormark/stock.adobe.com",
        parrafo16images: "Produktbilder, DOSATRONIC GmbH, Urheber: DOSATRONIC GmbH, Zuppingerstraße 8, 88213 Ravensburg",
        parrafo17images: "Google Maps Icon: Freepik von www.flaticon.com, CC 3.0 BY",
        parrafo18images: "Flaggen Icons von Flagpedia. lizenziert unter Public Domain",
        parrafo19images: "Schweine Icon: Icons made by photo3idea_studio from www.flaticon.com",
        parrafo20images: "Medizin Icon: Icons made by SumberRejeki from www.flaticon.com",
        parrafo21images: "Icons made by Freepik from www.flaticon.com",
        parrafo22images: "Icons made by photo3idea_studio from www.flaticon.com",
        parrafo23images: "Icons made by smashingstocks from www.flaticon.com",
        parrafo24images: "Icons made by Those Icons from www.flaticon.com",
        parrafo25images: "Icons made by Kemalmoe from www.flaticon.com",
        privacy: "DATENSCHUTZ",
        tituloprivacy: "Datenschutz­erklärung",
        protecciondatos: "1. Datenschutz auf einen Blick",
        sub1protecciondatos: "Allgemeine Hinweise",
        parrafo1protecciondatos: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.",
        sub2protecciondatos: "Datenerfassung auf dieser Website",
        subsub1protecciondatos: "Wer ist verantwortlich für die Datenerfassung auf dieser Website?",
        parrafo2protecciondatos: "Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.",
        subsub2protecciondatos: "Wie erfassen wir Ihre Daten?",
        parrafo3protecciondatos: "Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.",
        parrafo4protecciondatos: "Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.",
        subsub3protecciondatos: "Wofür nutzen wir Ihre Daten?",
        parrafo5protecciondatos: "Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.",
        subsub4protecciondatos: "Welche Rechte haben Sie bezüglich Ihrer Daten?",
        parrafo6protecciondatos: "Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.",
        parrafo7protecciondatos: "Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.",
        sub3protecciondatos: "Analyse-Tools und Tools von Dritt­anbietern",
        parrafo8protecciondatos: "Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.",
        parrafo9protecciondatos: "Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.",
        hosting: "2. Hosting",
        parrafo1hosting: "Wir hosten die Inhalte unserer Website bei folgendem Anbieter:",
        sub1hosting: "Mittwald",
        parrafo2hosting: "Anbieter ist die Mittwald CM Service GmbH & Co. KG, Königsberger Straße 4-6, 32339 Espelkamp (nachfolgend Mittwald).",
        parrafo3hosting: "Details entnehmen Sie der Datenschutzerklärung von Mittwald: https://www.mittwald.de/datenschutz.",
        parrafo4hosting: "Die Verwendung von Mittwald erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
        subsub1hosting: "Auftragsverarbeitung",
        parrafo5hosting: "Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.",
        general: "3. Allgemeine Hinweise und Pflicht­informationen",
        sub1general: "Datenschutz",
        parrafo1general: "Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.",
        parrafo2general: "Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.",
        parrafo3general: "Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.",
        sub2general: "Hinweis zur verantwortlichen Stelle",
        parrafo4general: "Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:",
        parrafo5general: "DOSATRONIC GmbH",
        parrafo6general: "Zuppingerstraße 8",
        parrafo7general: "88213 Ravensburg",
        parrafo8general: "Phone: +49-751-29512-0",
        parrafo9general: "E-mail: info@dosatronic.de",
        parrafo10general: "Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.",
        sub3general: "Speicherdauer",
        parrafo11general: "Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.",
        sub4general: "Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website",
        parrafo12general: "Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.",
        sub5general: "Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten",
        parrafo13general: "Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.",
        sub6general: "Widerruf Ihrer Einwilligung zur Datenverarbeitung",
        parrafo14general: "Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.",
        sub7general: "Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)",
        parrafo15general: "WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).",
        parrafo16general: "WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).",
        sub8general: "Beschwerde­recht bei der zuständigen Aufsichts­behörde",
        parrafo17general: "Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.",
        sub9general: "Recht auf Daten­übertrag­barkeit",
        parrafo18general: "Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.",
        sub10general: "Auskunft, Löschung und Berichtigung",
        parrafo19general: "Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.",
        sub11general: "Recht auf Einschränkung der Verarbeitung",
        parrafo12general: "Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:",
        ul1general: "Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
        ul2general: "Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.",
        ul3general: "Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
        ul4general: "Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
        parrafo13general: "Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.",
        sub12general: "SSL- bzw. TLS-Verschlüsselung",
        parrafo14general: "Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.",
        parrafo15general: "Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.",
        sub13general: "Widerspruch gegen Werbe-E-Mails",
        parrafo16general: "Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.",
        recording: "4. Datenerfassung auf dieser Website",
        sub1recording: "Cookies",
        parrafo1recording: "Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.",
        parrafo2recording: "Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).",
        parrafo3recording: "Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden.",
        parrafo4recording: "Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.",
        parrafo5recording: "Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.",
        parrafo6recording: "Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.",
        sub2recording: "Einwilligung mit Cookiebot",
        parrafo7recording: "Unsere Website nutzt die Consent-Technologie von Cookiebot, um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist Cybot A/S, Havnegade 39, 1058 Kopenhagen, Dänemark (im Folgenden „Cookiebot“).",
        parrafo8recording: "Wenn Sie unsere Website betreten, wird eine Verbindung zu den Servern von Cookiebot hergestellt, um Ihre Einwilligungen und sonstigen Erklärungen zur Cookie-Nutzung einzuholen. Anschließend speichert Cookiebot einen Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, den Cookiebot-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.",
        parrafo9recording: "Der Einsatz von Cookiebot erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.",
        sub3recording: "Auftragsverarbeitung",
        parrafo10recording: "Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.",
        sub4recording: "Kontaktformular",
        parrafo11recording: "Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.",
        parrafo12recording: "Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.",
        parrafo13recording: "Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.",
        sub5recording: "Anfrage per E-Mail, Telefon oder Telefax",
        parrafo14recording: "Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.",
        parrafo15recording: "Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.",
        parrafo16recording: "Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.",
        analisys: "5. Analyse-Tools und Werbung",
        sub1analisys: "Google Tag Manager",
        parrafo1analisys: "Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.",
        parrafo2analisys: "Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer Website einbinden können. Der Google Tag Manager selbst erstellt keine Nutzerprofile, speichert keine Cookies und nimmt keine eigenständigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die Vereinigten Staaten übertragen werden kann.",
        parrafo3analisys: "Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer schnellen und unkomplizierten Einbindung und Verwaltung verschiedener Tools auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
        sub2analisys: "Google Analytics",
        parrafo4analisys: "Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.",
        parrafo5analisys: "Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden dem jeweiligen Endgerät des Users zugeordnet. Eine Zuordnung zu einer User-ID erfolgt nicht.",
        parrafo6analisys: "Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B. Cookies oder Device-Fingerprinting). Die von Google erfassten Informationen über die Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",
        parrafo7analisys: "Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.",
        parrafo8analisys: "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://privacy.google.com/businesses/controllerterms/mccs/.",
        sub3analisys: "IP Anonymisierung",
        parrafo9analisys: "Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",
        sub4analisys: "Browser Plugin",
        parrafo10analisys: "Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de.",
        parrafo11analisys: "Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de.",
        sub5analisys: "Demografische Merkmale bei Google Analytics",
        parrafo12analisys: "Diese Website nutzt die Funktion „demografische Merkmale“ von Google Analytics, um den Websitebesuchern passende Werbeanzeigen innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.",
        sub6analisys: "Auftragsverarbeitung",
        parrafo13analisys: "Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.",
        newsletter: "6. Newsletter",
        sub1newsletter: "Newsletter­daten",
        parrafo1newsletter: "Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Für die Abwicklung der Newsletter nutzen wir Newsletterdiensteanbieter, die nachfolgend beschrieben werden.",
        sub2newsletter: "Rapidmail",
        parrafo2newsletter: "Diese Website nutzt Rapidmail für den Versand von Newslettern. Anbieter ist die rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg i.Br., Deutschland.",
        parrafo3newsletter: "Rapidmail ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den Servern von Rapidmail in Deutschland gespeichert.",
        sub3newsletter: "Datenanalyse durch Rapidmail",
        parrafo4newsletter: "Zum Zwecke der Analyse enthalten die mit Rapidmail versandten E-Mails ein sog. „Tracking-Pixel“, das sich beim Öffnen der E-Mail mit den Servern von Rapidmail verbindet. Auf diese Weise kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet wurde.",
        parrafo5newsletter: "Des Weiteren können wir mit Hilfe von Rapidmail ermitteln, ob und welche Links in der Newsletternachricht angeklickt werden. Bei allen Links in der E-Mail handelt es sich um sogenannte Tracking-Links, mit denen Ihre Klicks gezählt werden können. Wenn Sie keine Analyse durch Rapidmail wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung.",
        parrafo6newsletter: "Näheres zu den Analyse-Funktionen von Rapidmail entnehmen Sie folgendem Link: https://de.rapidmail.wiki/kategorien/statistiken/.",
        sub4newsletter: "Rechtsgrundlage",
        parrafo7newsletter: "Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.",
        sub5newsletter: "Speicherdauer",
        parrafo8newsletter: "Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.",
        parrafo9newsletter: "Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.",
        parrafo10newsletter: "Näheres entnehmen Sie den Datensicherheitshinweisen von Rapidmail unter: https://www.rapidmail.de/datensicherheit.",
        tools: "7. Plugins und Tools",
        sub1tools: "Vimeo",
        parrafo1tools: "Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.",
        parrafo2tools: "Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den Vimeo-Server in den USA übermittelt.",
        parrafo3tools: "Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.",
        parrafo4tools: "Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw. vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting).",
        parrafo5tools: "Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
        parrafo6tools: "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier: https://vimeo.com/privacy.",
        parrafo7tools: "Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: https://vimeo.com/privacy.",
        sub2tools: "Google Maps",
        parrafo8tools: "Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.",
        parrafo9tools: "Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten Google Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.",
        parrafo10tools: "Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
        parrafo11tools: "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://privacy.google.com/businesses/gdprcontrollerterms/ und https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.",
        parrafo12tools: "Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de.",
        custom: "8. Eigene Dienste",
        sub1custom: "Umgang mit Bewerberdaten",
        parrafo1custom: "Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt werden.",
        sub2custom: "Umfang und Zweck der Datenerhebung",
        parrafo2custom: "Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen personenbezogenen Daten (z. B. Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich an Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.",
        parrafo3custom: "Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen gespeichert.",
        sub3custom: "Aufbewahrungsdauer der Daten",
        parrafo4custom: "Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei uns aufzubewahren. Anschließend werden die Daten gelöscht und die physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden (z. B. aufgrund eines drohenden oder anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für die weitergehende Aufbewahrung entfällt.",
        parrafo5custom: "Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung entgegenstehen."
      }
    },
    pt: {
      footer: {
        company: "DOSATRONIC IBERIA S.L,",
        address: "Rua do Vallès, 7",
        city: "08397 Pineda de Mar",
        country: "Espanha",
        Telefono1: "+34 93 517 20 08",
        mail: "  info@dosatroniciberia.es",
        Fax: "",
        condiciones: "TERMOS E CONDIÇÕES GERAIS",
        aviso: "AVISO LEGAL",
        proteccion: "PROTECÇÃO DE DADOS",
        titulocondiciones: "TERMOS E CONDIÇÕES GERAIS",
        punto1: "I. Âmbito de aplicação",
        parrafo1punto1: "1. Os presentes termos e condições de entrega aplicam-se exclusivamente; condições divergentes ou contrárias ao cliente só serão aplicáveis ​​​​desde que o fornecedor as aprove por escrito.",
        parrafo2punto1: "2. The present General Terms and Conditions of Delivery shall also apply to subsequent orders and to replacement parts deliveries without necessitating repeated pointing out of this fact.",
        parrafo3punto1: "3. Os suplementos e representações, bem como as modificações ou alterações a um contrato celebrado por escrito ou por fax/e-mail devem ser feitos por escrito.",
        punto2: "II. Oferta e confirmação da encomenda",
        parrafo1punto2: "1. As ofertas só serão vinculativas desde que um prazo para aceitação seja indicado na oferta. Para serem juridicamente vinculativas, as ofertas exigirão confirmações por escrito do fornecedor.",
        parrafo2punto2: "2. O fornecedor reserva-se quaisquer títulos e direitos de autor sobre figuras, desenhos, cálculos e outra documentação de oferta e informações semelhantes de tipo físico e não físico - também em formato eletrónico, estes só podem ser divulgados a terceiros mediante aprovação por escrito do fornecedor e devem ser imediatamente devolvidos ao fornecedor, mediante pedido, se não for concedido qualquer pedido ao fornecedor.",
        punto3: "III: Âmbito das entregas e serviços",
        parrafo1punto3: "1. As entregas e os serviços são determinados com base nas declarações escritas mútuas. Se não existirem tais declarações, a confirmação escrita do pedido do fornecedor será decisiva. Para os meros contratos de venda, as disposições de entrega acordadas serão interpretadas de acordo com os INCOTERMS válidos na celebração do contrato.",
        parrafo2punto3: "2. Os dados contidos em brochuras, catálogos ou documentação técnica geral só serão vinculativos se lhes for feita referência por escrito.",
        parrafo3punto3: "3. Os custos de montagem e montagem acordados, incluindo todos e quaisquer custos acessórios necessários, tais como despesas de viagem ou custos de transporte de ferramentas ou bagagem pessoal, serão remunerados separadamente pelo cliente, salvo acordo em contrário.",
        parrafo4punto3: "4. Se o software fizer parte do âmbito de entrega, será concedido ao cliente um direito não exclusivo de utilização do software. O cliente poderá copiar ou editar o software apenas no âmbito legalmente permitido.",
        parrafo5punto3: "5. Serão permitidas entregas parciais, desde que razoáveis ​​para o cliente, considerando os interesses do fornecedor e do cliente.",
        parrafo6punto3: "6. no caso de entregas no estrangeiro, a obrigação do fornecedor será garantida desde que sejam concedidas as licenças de exportação necessárias.",
        punto4: "IV. Preços e condições de pagamento.",
        parrafo1punto4: "1. Todos os preços serão em EURO, salvo indicação em contrário. Aplicam-se a meras operações de entrega “exworks” (EXW), excluindo embalagens.",
        parrafo2punto4: "2. Os privilégios não incluem qualquer taxa de rotatividade. Esta taxa é discriminada separadamente na fatura no valor legal aplicável à data da fatura.",
        parrafo3punto4: "3. a dedução dos descontos exigirá um acordo especial por escrito.",
        parrafo4punto4: "4. Salvo indicação em contrário na confirmação da encomenda, o preço de venda deverá ser pago 30 dias a contar da data da fatura, sem qualquer dedução.",
        parrafo5punto4: "5. Se o cliente não cumprir a data de pagamento, o cliente deverá pagar juros de mora no valor de 8 pontos percentuais acima da taxa de juro baye de acordo com o §247 do Código Civil Alemão a partir da data de vencimento. O pagamento de danos adicionais continua reservado.",
        parrafo6punto4: "6. Salvo acordo em contrário, a entrega de mercadorias para entregas no estrangeiro estará sujeita à condição de que uma carta de crédito comercial irrevogável seja emitida pelo cliente a favor do fornecedor e confirmada por uma instituição bancária alemã.",
        parrafo7punto4: "7. Em caso de atraso no pagamento, o fornecedor poderá suspender o cumprimento das suas próprias obrigações até que o pagamento total seja recebido, mediante notificação por escrito ao cliente.",
        parrafo8punto4: "8. o cliente apenas poderá reclamar reclamações ou fazer valer um direito de retenção, desde que estas sejam indiscutíveis ou se tornem inapeláveis.",
        punto5: "V. Prazos para entregas ou serviços",
        parrafo1punto5: "1… No que diz respeito aos prazos, as declarações escritas mútuas ou, na falta de tais declarações, a confirmação escrita da encomenda do fornecedor serão determinantes. O prazo será considerado respeitado, desde que toda e qualquer documentação a fornecer pelo cliente seja recebida atempadamente, e sejam fornecidas todas e quaisquer autorizações, autorizações, nomeadamente planos necessários, e sejam cumpridas as condições de pagamento e demais obrigações são cumpridas pelo cliente . Se estes pré-requisitos não forem cumpridos atempadamente, o prazo será prorrogado razoavelmente; isto não se aplicará se o fornecedor for responsável pelo atraso.",
        parrafo2punto5: "2. Se a não observância dos prazos for resultado de força maior, p. mobilização, guerra, motim ou eventos semelhantes, p. g. greve ou lock-out, os prazos acordados serão prorrogados de forma razoável.",
        parrafo3punto5: "3. Caso a montagem e a montagem não façam parte dos serviços acordados, o prazo será considerado respeitado se a mercadoria pronta a operar tiver sido expedida ou recolhida dentro do prazo. Caso a entrega seja atrasada por motivos de responsabilidade do cliente, o prazo será considerado observado após a notificação de disponibilidade para expedição.",
        parrafo4punto5: "4. Se o fornecedor for responsável pelo incumprimento do prazo, o cliente, desde que tenha sofrido um prejuízo efectivo, poderá solicitar uma indemnização por atraso por cada semana completa de atraso num máximo de 0,5%, não excedendo, no entanto, 5 % do preço da parte da entrega que não pôde ser colocada em funcionamento devido ao atraso. Os pedidos de indemnização do cliente que excedam os limites estipulados no item 5.4 serão excluídos em todos os casos de atraso na entrega ou no serviço, incluindo após o termo de qualquer período de carência estabelecido ao fornecedor. exista responsabilidade obrigatória nos casos de intenção, negligência grave ou ofensas corporais; uma transferência do ónus da prova em detrimento do sucomer não é dada neste caso.",
        parrafo5punto5: "5. O direito de rescisão do cliente após o termo ineficaz de um período de carência para o fornecedor permanecerá inalterado. O período de carência, no entanto, deve ser razoável e ser de, pelo menos, quatro semanas.",
        parrafo6punto5: "6. Se o envio ou entrega atrasar mais de um mês após o aviso de disponibilidade para envio a pedido do cliente, despesas de armazém no valor de 0,5% do preço da mercadoria entregue, porém, não excedendo um total de 5%, poderão ser cobradas. As partes no contrato terão a liberdade de fornecer provas de encargos de armazém mais elevados ou mais baixos.",
        punto6: "VI. Passagem de utilidade e rist; seguro; embalagem",
        parrafo1punto6: "1. O risco das entregas e serviços prestados pelo fornecedor passará para o cliente da seguinte forma, mesmo no caso de entregas com portes pagos.",
        parrafo1punto6a: "a) para entregas sem montagem ou montagem, mesmo em caso de entregas parciais, se estas tiverem sido expedidas ou recolhidas. As remessas serão seguradas pelo fornecedor contra os riscos habituais de transporte, mediante solicitação e por conta do cliente. Caso exista tal seguro, o fornecedor será imediatamente notificado sobre quaisquer danos causados ​​às mercadorias em trânsito.",
        parrafo1punto6b: "b) para entregas com montagem ou montagem no dia da aceitação nas operações do cliente ou, se acordado, após operação de teste de perda.",
        parrafo2punto6: "2. Se o envio, entrega, início, execução da montagem ou aceitação da montagem nas operações do cliente ou operação de teste for atrasado por motivos atribuíveis ao cliente ou se o cliente atrasar a aceitação por outros motivos, o risco passará para o cliente.",
        parrafo3punto6: "3. O envio é feito em princípio em embalagens standard do fornecedor. Este último terá o direito de escolher tipos especiais de embalagens que considere necessários, de acordo com o critério do fornecedor. Os custos destas embalagens serão suportados pelo cliente.",
        punto7: "VII. Montagem e montagem",
        previopunto7: "A montagem, montagem e instalação dos equipamentos e dispositivos do fornecedor só podem ser efetuadas por especialistas, tendo em conta as orientações do fornecedor e as normas técnicas aplicáveis. Se a montagem e/ou a montagem forem realizadas pelo fornecedor, serão aplicadas as seguintes disposições, salvo acordo escrito em contrário:",
        parrafo1punto7: "1. O cliente deverá assumir e fornecer atempadamente e a expensas do cliente:",
        parrafo1punto7a: "a) todos os trabalhos de terraplanagem, construção e outros trabalhos auxiliares diversos, incluindo os especialistas e o pessoal auxiliar necessários, materiais e ferramentas.",
        parrafo1punto7b: "b) as mercadorias e materiais como andaimes, gruas e elevadores e outros dispositivos, combustíveis, lubrificantes e produtos químicos necessários para a montagem e colocação em serviço.",
        parrafo1punto7c: "c) energia e água no local de utilização, incluindo ligações, aquecimento e iluminação,",
        parrafo1punto7d: "d) Salas suficientemente grandes, adequadas, secas e com fechadura no local de montagem para armazenamento de peças de máquinas, fixações, materiais e ferramentas, etc., e salas de trabalho e de recreio adequadas para o pessoal de montagem, incluindo instalações sanitárias adequadas. Para a proteção da propriedade do fornecedor e do pessoal de montagem, o cliente também deverá tomar as medidas que normalmente tomaria para proteger a sua própria propriedade.",
        parrafo1punto7e: "e) vestuário de proteção e dispositivos de proteção necessários devido a circunstâncias especiais no local de montagem.",
        parrafo2punto7: "2. Antes do início dos trabalhos de montagem, o cliente deverá fornecer espontaneamente as informações necessárias sobre a posição das condutas subterrâneas de energia, gás, água ou instalações similares, bem como os dados estáticos necessários.",
        parrafo3punto7: "3. Antes do início da montagem ou montagem, os acrescentos e objetos necessários para iniciar o trabalho devem estar no local de montagem ou montagem e todos os preparativos antes do início da instalação devem ser avançados de modo a que a montagem ou montagem possa ser iniciada conforme acordado e pode ser realizado sem interrupções. As vias de acesso e o local de montagem ou de montagem devem estar nivelados e livres de quaisquer objetos.",
        parrafo4punto7: "4. Caso a montagem, montagem ou comissionamento sejam atrasados ​​por motivos alheios ao controlo do fornecedor, o cliente deverá suportar os custos do tempo de espera e dos rastreios adicionais exigidos ao fornecedor ou à equipa de montagem em valor adequado.",
        parrafo5punto7: "5. Caso uma fábrica não possa ser instalada imediatamente após a entrega, o cliente será responsável pelo armazenamento adequado de acordo com as orientações do fornecedor.",
        parrafo6punto7: "6. o cliente deverá fornecer ao fornecedor informações semanais sobre a duração do horário de trabalho do pessoal de montagem e deverá confirmar imediatamente a conclusão da montagem, montagem ou colocação em serviço.",
        parrafo7punto7: "7. A comissão só poderá ser realizada por técnicos reconhecidos pelo fornecedor e de acordo com as instruções do fornecedor. Os técnicos terão o direito de recusar o comissionamento da central se as condições de funcionamento a proporcionar pelo cliente não garantirem o bom funcionamento da central. O cliente suportará os custos de qualquer atraso no comissionamento incorrido ao fornecedor.",
        parrafo8punto7: "8. Caso o fornecedor solicite a aceitação das entregas e serviços após a conclusão, o cliente será obrigado a fazê-lo no prazo de duas semanas. Caso contrário, a aceitação considerar-se-á efetuada. A aceitação também será considerada como feita se os alimentos e os serviços de entrega - também após a conclusão de uma fase de teste acordada, se houver - tiverem sido colocados em utilização.",
        punto8: "VIII. Garantia",
        parrafo1punto8: "1. Caso os bens entregues ou os serviços prestados pelo fornecedor apresentem defeitos por não possuírem a qualidade acordada ou por não serem adequados para o uso acordado ou habitual, o fornecedor deverá, a seu critério, reparar as peças ou serviços em causa ou entregar ou retomá- los gratuitamente dentro do prazo de prescrição, desde que a causa do defeito já existisse no momento da transferência do risco.",
        parrafo2punto8: "2. As reclamações por defeitos materiais prescrevem ao fim de 12 meses. O prazo começará a contar com a transferência do risco (item 6). As disposições acima referidas não se aplicam na medida em que a lei prescreve obrigatoriamente prazos mais longos de acordo com o §§438(1) no. 2 Código Civil Alemão (bens para edifícios), §479(1) Código Civil Alemão (direito de regresso); e §634a Código Civil Alemão (defeitos estruturais). O período de garantia poderá ser prolongado até 60 meses em casos adequados, desde que o cliente celebre um contrato de manutenção pelo período correspondente.",
        parrafo3punto8: "3. O cliente deverá notificar imediatamente os defeitos ao fornecedor.",
        parrafo4punto8: "4. NO caso de notificações de defeitos, os pagamentos do cliente poderão ser retidos no montante que apresente uma proporção razoável em relação aos defeitos materiais incorridos. O cliente só poderá reter os pagamentos se for apresentada uma notificação de defeito cuja justificação seja incontestável. Se a notificação de defeito for dada indevidamente, o fornecedor terá o direito de exigir do cliente a compensação pelas despesas incorridas com o fornecedor. Num primeiro momento, o fornecedor terá sempre a oportunidade de pós-execução dentro de um prazo razoável. O cliente deverá conceder ao fornecedor o tempo e a oportunidade necessários para o fazer. Caso o cliente recuse, o fornecedor ficará isento de responsabilidade por defeitos.",
        parrafo5punto8: "5. Se o pedido de pós-execução, o cliente - não obstante eventuais reclamações por danos - poderá rescindir o contrato ou reduzir a compensação. O cliente não pode reclamar uma indemnização por despesas inúteis.",
        parrafo6punto8: "6. Não existem reclamações por defeitos em caso de pequenos desvios da qualidade acordada ou assumida, pequenos prejuízos de usabilidade, desgaste natural ou danos ocorridos após a passagem do risco devido a manuseamento incorreto ou insignificante, uso excessivo, material operacional inadequado, trabalhos de construção defeituosos, subsolo inadequado ou devido a influências externas especiais não estabelecidas no contrato, bem como em caso de erros de software não reproduzíveis. Se o cliente ou terceiros procederem a modificações ou reparações inadequadas, não existirá qualquer reclamação por defeitos por estas e pelas consequências daí resultantes.",
        parrafo7punto8: "7. O fornecedor não suportará as despesas adicionais, nomeadamente custos de transporte, rastreio, mão-de-obra e materiais, que resultem do facto de o objecto da entrega ter sido posteriormente transportado para um local diferente da sucursal do cliente ou do local de destino original , só que o transporte corresponde ao seu uso adequado.",
        parrafo8punto8: "8. Em todos os casos, o cliente será obrigado a tomar todas as medidas possíveis e razoáveis ​​para manter as despesas com a finalidade de desempenho do porto tão pequenas quanto possível. O fornecedor participará nos custos de uma campanha de recolha apenas se tal for necessário com base na adequação factual e legal. O cliente obriga-se a devolver os produtos defeituosos ou a mantê-los prontos para inspeção e descanso, por conta do fornecedor.",
        parrafo9punto8: "9. As reclamações de recurso do cliente contra o fornecedor só existirão na medida em que o cliente não tenha celebrado quaisquer acordos com o comprador do cliente que excedam as reclamações legais por defeitos. Além disso, o item 8.8 aplicar-se-á correspondentemente ao âmbito do direito de regresso do cliente contra o fornecedor.",
        parrafo10punto8: "10. Além disso, o item 11 (outras reclamações por danos) também se aplica às reclamações por danos. Serão excluídas as reclamações mais extensas ou outras do que as estipuladas no presente item 8 do cliente contra o fornecedor e as suas pessoas empregadas no cumprimento das obrigações devido a um defeito material.",
        punto9: "IX. Direitos de propriedade industrial e direitos de autor; defeitos de título",
        parrafo1punto9: "1. Salvo acordo em contrário, o fornecedor será obrigado a entregar a entrega isenta de quaisquer direitos de propriedade industrial e direitos de autor de terceiros (doravante designados: direitos de propriedade) exclusivamente no país do local de entrega. Na medida em que um terceiro apresente reclamações justificadas contra o cliente devido à violação dos direitos de propriedade por entregas efetuadas pelo fornecedor e utilizadas de acordo com o contrato, o fornecedor será responsável perante o cliente dentro do prazo estipulado no item 8.2 da seguinte forma:",
        parrafo1punto9a: "a) o fornecedor deverá, a expensas do fornecedor e a seu critério, obter um direito de utilização das entregas em causa, modificá-las de modo a que o direito de propriedade não seja infringido ou trocá-las. Caso o fornecedor não o consiga fazer em condições razoáveis, o cliente terá direito ao cancelamento legal dos direitos de redução. O cliente não pode reclamar indemnizações por despesas inúteis.",
        parrafo1punto9b: "b) a obrigação do fornecedor de pagar a indemnização estará sujeita ao item 11.",
        parrafo1punto9c: "c) as obrigações do fornecedor acima referidas só serão cumpridas desde que o cliente informe imediatamente o fornecedor por escrito sobre as reclamações apresentadas por terceiros, se recuse a reconhecer uma infração, e todas e quaisquer medidas de proteção e procedimentos de liquidação permaneçam reservadas ao fornecedor. Caso o cliente interrompa a utilização dos bens entregues com o objetivo de reduzir os danos ou por outros motivos, o cliente será obrigado a informar o terceiro sobre o facto de a interrupção da utilização não representar um reconhecimento da violação dos direitos de propriedade.",
        parrafo2punto9: "2. As reclamações do cliente serão excluídas, o cliente é responsável pela violação dos direitos de propriedade.",
        parrafo3punto9: "3. Além disso, as reclamações do cliente serão excluídas na medida em que a violação dos direitos de propriedade tenha sido causada por normas especiais estipuladas pelo cliente, por utilização não previsível pelo fornecedor ou pelo facto de as mercadorias entregues terem sido modificadas pelo cliente ou utilizadas em conjunto com produtos não entregues pelo fornecedor.",
        parrafo4punto9: "4. No caso de violações de direitos de propriedade, as reivindicações do cliente estipuladas no item 9.1 a) serão aplicadas, além disso, as disposições do item 8.4, item 8.5 e item 8.10 serão aplicadas correspondentemente. No caso de outros vícios de titularidade, aplicar-se-á correspondentemente o disposto no n.º 8. Serão excluídas reclamações mais extensas ou outras reclamações do que as estipuladas no presente item 9 do cliente contra o fornecedor e as suas pessoas empregadas no cumprimento das obrigações devido a um defeito de título.",
        punto10: "X. Impossibilidade; adaptação do contrato",
        parrafo1punto10: "1. Na medida em que a entrega não seja possível, o cliente terá direito a uma indemnização por danos, salvo se a impossibilidade for imputável ao fornecedor. As reclamações do cliente por danos, no entanto, serão limitadas a 10% da parte da entrega que não pode ser colocada em operação relevante devido à impossibilidade. Esta limitação não se aplicará na medida em que exista responsabilidade obrigatória em casos de dolo, negligência grave ou danos pessoais; uma inversão do ónus da prova em detrimento do cliente não é válida neste caso. O direito do cliente de rescindir o contrato permanecerá inalterado.",
        parrafo2punto10: "2. Em caso de impossibilidade temporária, aplica-se o item 5 (prazos).",
        parrafo3punto10: "3. Caso os acontecimentos imprevisíveis no sentido do ponto 5.2 alterem significativamente o significado económico ou o conteúdo da entrega ou tenham um efeito significativo nas operações dos fornecedores, o contrato deverá ser adaptado de boa fé. Na medida em que tal não seja economicamente razoável, o fornecedor terá o direito de rescindir o contrato. Caso o fornecedor pretenda fazer valer este direito de rescisão, o fornecedor, após ter conhecimento da extensão do evento, deverá informar imediatamente o cliente desse facto. Isto também se aplica se tiver sido acordada inicialmente com o cliente uma prorrogação do prazo de entrega.",
        punto11: "XI. Outras reclamações por danos.",
        parrafo1punto11: "1. Serão excluídas as reclamações do cliente por danos e indemnização de despesas por violação de obrigações principais ou acessórias da obrigação contratual, de trote ou outros fundamentos legais.",
        parrafo2punto11: "2. Esta exclusão não se aplica na medida em que exista responsabilidade obrigatória, p. de acordo com a Lei Alemã de Responsabilidade Civil, em casos de dolo, negligência grave, danos pessoais, devido à assunção de uma garantia pela presença de uma propriedade ou violação de obrigações contratuais materiais. Os danos pela violação de obrigações contratuais materiais serão, no entanto, limitados aos danos típicos e previsíveis do contrato, desde que não exista dolo ou negligência grave ou exista responsabilidade por danos pessoais ou pela assunção de garantia pela presença de preotério. Uma transferência do ónus da prestação em desvantagem do cliente não está relacionada com as disposições acima referidas.",
        parrafo3punto11: "3. Caso a lei imperativa não estabeleça prazos de prescrição mais alargados, todo e qualquer pedido de indemnização por danos prescreverá nos prazos indicados no item 8.2.",
        punto12: "XII. Garantia e descrição do produto",
        parrafo1punto12: "1. As garantias só terão efeito se forem feitas por escrito.",
        parrafo2punto12: "2. Os dados descritos em catálogos, documentação de concurso e outros materiais impressos, bem como declarações publicitárias gerais, não representam uma oferta para a celebração de um contrato de garantia.",
        punto13: "XIII. Reserva de título",
        parrafo1punto13: "1. O fornecedor reserva-se a propriedade das mercadorias entregues (mercadorias de reserva) até que o cliente tenha efetuado o pagamento integral devido pela relação comercial. A reserva de propriedade incluirá também o saldo reconhecido, na medida em que o fornecedor registe os créditos contra o cliente numa conta à ordem (reserva de conta à ordem).",
        parrafo2punto13: "2. Se o fornecedor aceitar a devolução da mercadoria entregue, tal significará uma rescisão do contrato. Após a devolução dos bens adquiridos, o fornecedor terá o direito de realizar esses bens; as taxas de realização. No caso de penhora da mercadoria entregue, o fornecedor terá o direito de rescindir o contrato sem fixação de prazo. Em caso de penhora ou outras intervenções de terceiros, o cliente deverá informar imediatamente o fornecedor por escrito para que o fornecedor possa iniciar uma ação nos termos do §771 do Código de Processo Civil Alemão. Na medida em que terceiros não sejam capazes de reembolsar ao fornecedor as despesas judiciais e extrajudiciais de uma ação nos termos do §771 do Código de Processo Civil Alemão, o cliente será responsável pela perda incorrida pelo fornecedor.",
        parrafo3punto13: "3. O cliente terá o direito de revender os produtos entregues no decurso adequado do negócio; entretanto, o cliente já atribui ao fornecedor. Toda e qualquer reclamação no valor do valor final da fatura, incluindo o imposto sobre o valor acrescentado, que lhe seja devida pela revenda contra o seu comprador ou terceiros, independentemente do facto de a mercadoria entregue ter sido revendida sem ou após processamento. O cliente terá o direito de cobrar esta reclamação também após a sua cedência. O poder do fornecedor de cobrar ele próprio a reclamação permanece inalterado; o fornecedor poderá solicitar ao cliente a divulgação dos créditos cedidos e dos seus devedores, a prestação das informações necessárias à cobrança, a apresentação da documentação pertinente e a comunicação ao devedor (terceiro) da cessão.",
        parrafo4punto13: "4. O processamento e transformação da mercadoria entregue pelo cliente será sempre efetuado por conta do fornecedor. Se as mercadorias entregues forem processadas juntamente com outros objetos não pertencentes ao fornecedor, o fornecedor obterá a copropriedade do novo objeto na proporção do valor das mercadorias entregues aos restantes objetos processados ​​no momento do processamento. Caso contrário, as mesmas disposições que para os bens de reserva serão aplicáveis ​​à matéria criada pelo processamento. O cliente deverá também ceder ao fornecedor as reclamações para garantir as reclamações do fornecedor que são devidas ao cliente contra terceiros, unindo os bens de entrega a um imóvel.",
        parrafo5punto13: "5. No caso de os bens entregues serem misturados indissoluvelmente com outros objetos não pertencentes ao fornecedor, o fornecedor obterá a copropriedade do novo objeto na proporção do valor dos bens entregues com os restantes objetos misturados no momento da mistura. Se a mistura for feita de forma a que a questão do cliente seja considerada um componente principal, as partes acordam que o cliente deverá atribuir ao fornecedor a copropriedade proporcional. O cliente deverá manter a propriedade exclusiva ou co-propriedade do fornecedor. O cliente deverá segurá-lo no âmbito habitual contra riscos habituais, como por ex. incêndio, roubo, água e similares. O cliente deverá desde já ceder ao fornecedor os pedidos de indemnização do cliente que lhe sejam devidos por danos do tipo mencionado contra seguradoras ou outros terceiros, no valor do valor facturado da mercadoria.",
        parrafo6punto13: "6. Se o valor realizável dos títulos devidos ao fornecedor exceder os créditos totais do fornecedor em mais de 10%, o fornecedor será obrigado a libertar, a critério do fornecedor, títulos a pedido do cliente ou de um terceiro afetado pela garantia excessiva.",
        punto14: "XIV. Local de jurisdição e lei aplicável",
        parrafo1punto14: "1. O foro para todo e qualquer litígio emergente do presente contrato será a sede do fornecedor, desde que o cliente seja comerciante: O fornecedor, no entanto, terá o direito de intentar uma ação na sede do cliente.",
        parrafo2punto14: "2. A lei alemã será aplicável às relações contratuais. A Convenção das Nações Unidas sobre a Venda Internacional de Mercadorias (CISG) será excluída.",
        punto15: "XV. Divisibilidade",
        parrafo1punto15: "Caso alguma disposição individual do presente contrato seja juridicamente ineficaz, a validade das restantes disposições não será de forma alguma afetada. Isto não se aplicará se o cumprimento do contrato constituir uma dificuldade injustificada para a outra parte do contrato.",
        legal: "AVISO LEGAL",
        information: "Informação de acordo com o artigo 5º do TMG",
        parrafo1information: "DOSATRONIC IBERIA S.L,",
        parrafo2information: "Rua del Vallés, 7",
        parrafo3information: "08397 Pineda de mar",
        parrafo4information: "Registo comercial: HRB 552723",
        parrafo4information: "Tribunal de registo: Ulm",
        parrafo5information: "Representado por:",
        parrafo6information: "Diretor Geral Ángel Villaret",
        parrafo7information: "",
        contact: "Contato",
        parrafo1contact: "Móvel: +34 93 517 20 08", //+34 93 517 20 08
        parrafo2contact: "",
        parrafo3contact: "E-mail: info@dosatroniciberia.es", //info@dosatroniciberia.es
        vat: "Número de IVA",
        parrafo1vat: "Número de identificação fiscal de acordo com o artigo 27 a da Lei do Imposto sobre as Vendas:",
        parrafo2vat: "DE812973283",
        dispute: "Resolução de litígios na UE",
        parrafo1dispute: "A Comissão Europeia oferece uma plataforma para a resolução de litígios online (ODR): https://ec.europa.eu/consumers/odr/ .",
        parrafo2dispute: "O nosso endereço de e-mail pode ser encontrado no aviso legal acima.",
        dispute2: "Procedimento de resolução de conflitos perante um conselho de arbitragem de consumo",
        parrafo1dispute2: "Não estamos dispostos nem somos obrigados a participar em processos de resolução de litígios perante um conselho de arbitragem de consumo.",
        images: "Imagens:",
        parrafo1images: "84367010, Fotolia, Creator: digieye",
        parrafo2images: "83867300, Fotolia, Creator: somchaij",
        parrafo3images: "124411039, Fotolia, Creator: samafoto",
        parrafo4images: "119530550, Fotolia, Creator: yotrakbutda",
        parrafo5images: "69418987, Fotolia, Creator: bigfoot",
        parrafo6images: "178882165, Fotolia, Creator: psdesign1",
        parrafo7images: "Metallverarbeitung Emotion: © photosoup/stock.adobe.com",
        parrafo8images: "Metallverarbeitung: © leungchopan/stock.adobe.com",
        parrafo9images: "Tierzucht und Landwirtschaft Emotion: © Kadmy/stock.adobe.com",
        parrafo10images: "Tierzucht und Landwirtschaft: © 88studio/stock.adobe.com",
        parrafo11images: "Wasseraufbereitung Emotion: © OrthsMedien/stock.adobe.com",
        parrafo12images: "Fahrzeugwäsche Emotion: © PixPartout/stock.adobe.com",
        parrafo13images: "Fahrzeugwäsche: © industrieblick/stock.adobe.com",
        parrafo14images: "Druckindustrie Emotion: ©  rh2010/stock.adobe.com",
        parrafo15images: "Lebensmittelindustrie Emotion: © agnormark/stock.adobe.com",
        parrafo16images: "Imagens de produtos, DOSATRONIC GmbH, Criador: Sr. Hubert Müller, Editor Técnico, DOSATRONIC GmbH, Zuppingerstraße 8, 88213 Ravensburg",
        parrafo17images: "Ícone do Google Maps: Freepik de www.flaticon.com, CC 3.0 BY",
        parrafo18images: "Flaggen Icons von Flagpedia. lizenziert unter Public Domain",
        parrafo19images: "Schweine Icon: Icons made by photo3idea_studio from www.flaticon.com",
        parrafo20images: "Medizin Icon: Icons made by SumberRejeki from www.flaticon.com",
        parrafo21images: "Icons made by Freepik from www.flaticon.com",
        parrafo22images: "Icons made by photo3idea_studio from www.flaticon.com",
        parrafo23images: "Icons made by smashingstocks from www.flaticon.com",
        parrafo24images: "Icons made by Those Icons from www.flaticon.com",
        parrafo25images: "Icons made by Kemalmoe from www.flaticon.com",
        privacy: "POLÍTICA DE PRIVACIDADE DE DADOS",
        tituloprivacy: "política de Privacidade",
        protecciondatos: "1. Visão geral da proteção de dados",
        sub1protecciondatos: "informações gerais",
        parrafo1protecciondatos: "As informações seguintes fornecerão uma visão geral fácil de ler sobre o que acontecerá aos seus dados pessoais quando visitar este website. O termo “dados pessoais” inclui todos os dados que podem ser utilizados para o identificar pessoalmente. Para obter informações detalhadas sobre o tema da proteção de dados, consulte a nossa Declaração de Proteção de Dados, que incluímos abaixo deste exemplar.",
        sub2protecciondatos: "Registo de dados neste site",
        subsub1protecciondatos: "Quem é o responsável pelo registo dos dados neste site (ou seja, o “responsável pelo tratamento dos dados”)?",
        parrafo2protecciondatos: "Os dados deste site são tratados pelo operador do site, cujos dados de contacto estão disponíveis na secção “Informações sobre o responsável (referido como “controlador” no RGPD)” desta Política de Privacidade.",
        subsub2protecciondatos: "Como registamos os seus dados?",
        parrafo3protecciondatos: "Recolhemos os seus dados como resultado de partilhar os seus dados connosco. Por exemplo, podem ser informações que introduz no nosso formulário de contacto.",
        parrafo4protecciondatos: "Outros dados serão registados pelos nossos sistemas informáticos de forma automática ou após o seu consentimento durante a sua visita ao site. Estes dados incluem principalmente informações técnicas (por exemplo, navegador web, sistema operativo ou tempo de acesso ao site). Estas informações são registadas automaticamente quando acede a este site.",
        subsub3protecciondatos: "Para que finalidades utilizamos os seus dados?",
        parrafo5protecciondatos: "São geradas algumas informações para garantir que o site funciona sem erros. Outros dados podem ser utilizados para analisar os seus padrões de utilização.",
        subsub4protecciondatos: "Que direitos tem em relação às suas informações?",
        parrafo6protecciondatos: "Tem o direito de receber informação a qualquer momento sobre a origem, destinatários e finalidades dos dados pessoais que lhe digam respeito, sem ter de pagar qualquer taxa por isso. Tem também o direito de exigir que os seus dados sejam retificados ou eliminados. Se tiver dado o seu consentimento para o tratamento de dados, tem a possibilidade de revogar o seu consentimento a qualquer momento, o que afetará todos os futuros tratamentos de dados. Além disso, tem o direito de exigir que o tratamento dos seus dados seja restringido em determinadas circunstâncias. Além disso, tem o direito de apresentar uma queixa junto da autoridade de supervisão competente.",
        parrafo7protecciondatos: "Por favor, não hesite em contactar-nos a qualquer momento se tiver dúvidas sobre este ou qualquer outro assunto de proteção de dados.",
        sub3protecciondatos: "Ferramentas de análise e ferramentas fornecidas por terceiros",
        parrafo8protecciondatos: "Existe a possibilidade de os seus padrões de navegação serem analisados ​​​​estatisticamente quando visita este website. Estas análises são realizadas principalmente com aquilo a que chamamos programas de análise.",
        parrafo9protecciondatos: "Para obter informações detalhadas sobre estes programas de análise, consulte a nossa Declaração de Proteção de Dados abaixo.",
        hosting: "2. Hosting",
        parrafo1hosting: "Alojamos o conteúdo do nosso site no seguinte fornecedor:",
        sub1hosting: "Mittwald",
        parrafo2hosting: "O fornecedor é a Mittwald CM Service GmbH & Co. KG, Königsberger Straße 4-6, 32339 Espelkamp, ​​​​​​Alemanha (doravante designada por Mittwald).",
        parrafo3hosting: "Para mais detalhes, consulte a política de privacidade de dados da Mittwald: https://www.mittwald.de/datenschutz.",
        parrafo4hosting: "Usamos Mittwald com base no art. 6º(1)(f) do RGPD. Temos um interesse legítimo na representação mais fiável possível do nosso website. Se tiver sido obtido o consentimento correspondente, o tratamento é realizado exclusivamente com base no art. 6(1)(a) RGPD e § 25(1) TTDSG, desde que o consentimento inclua o armazenamento de cookies ou o acesso a informações no dispositivo terminal do utilizador (por exemplo, impressão digital do dispositivo) no sentido do TTDSG. Este consentimento pode ser revogado a qualquer momento.",
        subsub1hosting: "Processamento de dados",
        parrafo5hosting: "Celebrámos um acordo de tratamento de dados (DPA) para a utilização do serviço acima referido. Este é um contrato exigido pelas leis de privacidade de dados que garante que processamos os dados pessoais dos visitantes do nosso website apenas de acordo com as nossas instruções e de acordo com o RGPD.",
        general: "3. Informações gerais e informações obrigatórias",
        sub1general: "Proteção de dados",
        parrafo1general: "Os operadores deste site e das suas páginas levam muito a sério a proteção dos seus dados pessoais. Por conseguinte, tratamos os seus dados pessoais de forma confidencial e de acordo com os regulamentos legais de proteção de dados e com a presente declaração de proteção de dados.",
        parrafo2general: "Cada vez que utilizar este site, uma variedade de informações pessoais serão recolhidas. Os dados pessoais incluem dados que podem ser utilizados para o identificar pessoalmente. Esta Declaração de Proteção de Dados explica que dados recolhemos, bem como para que os utilizamos. Explica também como e com que finalidade as informações são recolhidas.",
        parrafo3general: "Informamos que a transmissão de dados através da Internet (ou seja, através de comunicações por correio electrónico) poderá apresentar falhas de segurança. Não é possível proteger completamente os dados contra o acesso de terceiros.",
        sub2general: "Informação sobre o responsável pelo tratamento de dados (referido como “controlador de dados” no RGPD)",
        parrafo4general: "O responsável pelo tratamento de dados neste site é:",
        parrafo5general: "DOSATRONIC GmbH",
        parrafo6general: "Zuppingerstraße 8",
        parrafo7general: "88213 Ravensburg",
        parrafo8general: "Telefone: +49-751-29512-0",
        parrafo9general: "E-mail: info@dosatronic.de",
        parrafo10general: "O responsável pelo tratamento é a pessoa singular ou coletiva que, isoladamente ou em conjunto com outras, decide sobre as finalidades e os meios de tratamento dos dados pessoais (por exemplo, nomes, endereços de correio eletrónico, etc.).",
        sub3general: "Duração do armazenamento",
        parrafo11general: "A menos que um período de armazenamento mais específico seja especificado nesta política de privacidade, os seus dados pessoais permanecerão connosco até que a finalidade para a qual foram recolhidos deixe de se aplicar. Se submeter um pedido justificado de eliminação ou revogar o seu consentimento para o tratamento de dados, os seus dados serão eliminados, a menos que tenhamos outros motivos legalmente permitidos para armazenar os seus dados pessoais (por exemplo, períodos de retenção fiscais ou comerciais); neste último caso, a eliminação ocorrerá depois de estes motivos deixarem de ser aplicáveis.",
        sub4general: "Informações gerais sobre a base jurídica para o tratamento de dados neste site",
        parrafo12general: "Se tiver dado o seu consentimento para o tratamento de dados, trataremos os seus dados pessoais com base no Art. 6º(1)(a) RGPD ou Art. 9 (2) (a) RGPD, se forem processadas categorias especiais de dados de acordo com o Art. 9º(1) do RGPD. Em caso de consentimento explícito para a transferência de dados pessoais para países terceiros, o tratamento dos dados baseia-se também no Art. 49 (1) (a) RGPD. Se tiver dado o seu consentimento para o armazenamento de cookies ou para aceder a informações no seu dispositivo final (por exemplo, através da impressão digital do dispositivo), o tratamento de dados baseia-se ainda no § 25(1) TTDSG. O consentimento pode ser revogado a qualquer momento. Se os seus dados forem necessários para o cumprimento de um contrato ou para a implementação de medidas pré-contratuais, tratamos os seus dados com base no Art. 6º(1)(b) do RGPD. Além disso, se os seus dados forem necessários para o cumprimento de uma obrigação legal, processámo-los com base no Art. 6º(1)(c) do RGPD. Além disso, o tratamento de dados pode ocorrer com base no nosso interesse legítimo, de acordo com o art. 6º(1)(f) do RGPD. As secções seguintes desta política de privacidade fornecem informações sobre a base jurídica relevante em cada caso específico.",
        sub5general: "Informação sobre a transferência de dados para os EUA e outros países fora da UE",
        parrafo13general: "Entre outras coisas, utilizamos ferramentas de empresas sediadas nos Estados Unidos ou noutros países fora da UE que não são seguras do ponto de vista da protecção de dados. Se estas ferramentas estiverem ativas, os seus dados pessoais poderão ser transferidos para esses países fora da UE e aí processados. Devemos salientar que um nível de protecção de dados comparável ao da UE não pode ser garantido nestes países. Por exemplo, as empresas dos EUA são obrigadas a fornecer dados pessoais às autoridades responsáveis ​​pela aplicação da lei e você, enquanto titular dos dados, não tem opção de litigar para se defender em tribunal. Por conseguinte, não se pode excluir que as agências dos EUA (por exemplo, o Serviço Secreto) processem, analisem e arquivem permanentemente os seus dados pessoais para fins de vigilância. Não temos controlo sobre estas atividades de processamento.",
        sub6general: "Revogação do seu consentimento para o tratamento de dados",
        parrafo14general: "Uma grande variedade de operações de tratamento de dados só são possíveis com o seu consentimento expresso. Também pode revogar qualquer consentimento que já nos tenha dado a qualquer momento. Isto sem prejuízo da legalidade de qualquer recolha de dados efectuada antes da sua revogação.",
        sub7general: "Direito de oposição à recolha de dados em casos especiais; direito de oposição à publicidade direta (art. 21.º do RGPD)",
        parrafo15general: "CASO OS DADOS SEJAM TRATADOS COM BASE NO ART. 6º(1)(E) OU (F) DO RGPD, TEM O DIREITO DE SE OPOSTAR A QUALQUER MOMENTO AO PROCESSAMENTO DOS SEUS DADOS PESSOAIS COM BASE EM MOTIVOS DECORRENTES DA SUA SITUAÇÃO ESPECÍFICA. ISTO TAMBÉM SE APLICA A QUALQUER PERFIL BASEADO NESTAS DISPOSIÇÕES. PARA DETERMINAR A BASE JURÍDICA EM QUE SE BASEIA QUALQUER TRATAMENTO DE DADOS, CONSULTE ESTA DECLARAÇÃO DE PROTEÇÃO DE DADOS. SE ENVIAR UMA OBJEÇÃO, NÃO PROCESSAREMOS MAIS OS SEUS DADOS PESSOAIS AFETADOS, A MENOS QUE ESTEJAMOS EM POSIÇÃO DE APRESENTAR MOTIVOS ABRANGENTES DE PROTEÇÃO PARA O PROCESSAMENTO DOS SEUS DADOS, QUE SUPERIOREM OS SEUS INTERESSES, DIREITOS E LIBERDADES OU SE A FINALIDADE DO PROCESSAMENTO É A RECLAMAÇÃO , EXERCÍCIO OU DEFESA DE DIREITOS LEGAIS (OBJEÇÃO DE ACORDO COM O ARTIGO 21º(1) DO RGPD).",
        parrafo16general: "SE OS SEUS DADOS PESSOAIS FOREM PROCESSADOS PARA PUBLICIDADE DIRETA, TEM O DIREITO DE SE OPOSTAR A QUALQUER MOMENTO AO PROCESSAMENTO DOS SEUS DADOS PESSOAIS AFETADOS PARA FINS DE TAL PUBLICIDADE. ISTO TAMBÉM SE APLICA AO PROFINING NA MEDIDA EM QUE ESTÁ RELACIONADO COM TAIS PUBLICIDADE DIRETA. CASO SE OPOSTE, OS SEUS DADOS PESSOAIS DEIXAM DE SER UTILIZADOS PARA FINS DE PUBLICIDADE DIRETA (OBJEÇÃO DE ACORDO COM O ARTIGO 21º(2) DO RGPD).",
        sub8general: "Direito de apresentar uma queixa junto da autoridade de supervisão competente",
        parrafo17general: "Em caso de violação do RGPD, o titular dos dados tem o direito de apresentar uma reclamação a uma autoridade de controlo, nomeadamente no Estado-Membro onde tem a sua residência habitual, o seu local de trabalho ou o local onde a alegada infração ocorreu. O direito de apresentar uma reclamação aplica-se independentemente de qualquer outro recurso administrativo ou judicial disponível.",
        sub9general: "Direito à portabilidade dos dados",
        parrafo18general: "Tem o direito de exigir que lhe entreguemos a si ou a terceiros os dados que processamos automaticamente com base no seu consentimento ou para a execução de um contrato num formato normalmente utilizado e legível por máquina. Se solicitar a transmissão direta dos dados a outro responsável pelo tratamento, tal só será feito se for tecnicamente possível.",
        sub10general: "Informação, rectificação e eliminação de dados",
        parrafo19general: "No âmbito das disposições legais aplicáveis, tem o direito de solicitar, a qualquer momento, informações sobre os seus dados pessoais armazenados, a sua origem e destinatário, bem como a finalidade do tratamento dos seus dados. Poderá ainda ter o direito de solicitar a rectificação ou eliminação dos seus dados. Se tiver alguma dúvida sobre este tema ou qualquer outra questão relacionada com dados pessoais, não hesite em contactar-nos a qualquer momento.",
        sub11general: "Direito de exigir limitações de tratamento",
        parrafo12general: "Tem o direito de solicitar a limitação do tratamento dos seus dados pessoais. Para tal, pode entrar em contacto connosco a qualquer momento. O direito de solicitar a limitação do tratamento aplica-se nos seguintes casos:",
        ul1general: "Se contestar a exatidão dos dados que armazenamos sobre si, geralmente precisaremos de tempo para verificar esta afirmação. Durante a duração desta investigação, tem o direito de solicitar que restrinjamos o tratamento dos seus dados pessoais.",
        ul2general: "Se o tratamento dos seus dados pessoais foi/é realizado de forma ilícita, tem a opção de exigir a restrição do tratamento dos seus dados em vez de exigir a erradicação desses dados.",
        ul3general: "Se já não necessitarmos dos seus dados pessoais e necessitar deles para exercer, defender ou reivindicar direitos legais, tem o direito de exigir a restrição do tratamento dos seus dados pessoais em vez da sua erradicação.",
        ul4general: "Se tiver apresentado uma objeção nos termos do artigo 21.º, n.º 1, do RGPD, os seus direitos e os nossos direitos devem ser avaliados. Até que seja determinado quais os interesses que prevalecem, tem o direito de exigir a limitação do tratamento dos seus dados pessoais.",
        parrafo13general: "Se tiver restringido o tratamento dos seus dados pessoais, estes dados, com exceção do ficheiro, só podem ser tratados com o seu consentimento ou para reivindicar, exercer ou defender direitos legais ou para proteger os direitos de outras pessoas singulares ou coletivas ou por razões importantes de interesse público citadas pela União Europeia ou por um estado membro da UE.",
        sub12general: "Encriptação SSL e/ou TLS",
        parrafo14general: "Por razões de segurança e para proteger a transmissão de conteúdos confidenciais, tais como ordens de compra ou consultas que nos envia como operador do website, este website utiliza software de encriptação SSL ou TLS. Pode reconhecer uma ligação encriptada verificando se a linha de endereço do browser muda de “http://” para “https://” e também pela aparência do ícone do cadeado na linha do browser.",
        parrafo15general: "Se a encriptação SSL ou TLS estiver ativada, os dados que nos transmite não podem ser lidos por terceiros.",
        sub13general: "Rejeição de e-mails não solicitados",
        parrafo16general: "Opomo-nos a que os dados de contacto publicados juntamente com as informações obrigatórias que devem ser fornecidas no nosso Aviso Legal sejam utilizados para nos enviar material promocional e informativo que não tenhamos solicitado expressamente. Os operadores deste site e das suas páginas reservam-se o direito expresso de tomar medidas legais em caso de envio não solicitado de informações promocionais, por exemplo, através de mensagens SPAM.",
        recording: "4. Registo de dados neste site",
        sub1recording: "Cookies",
        parrafo1recording: "Os nossos sites e páginas utilizam aquilo a que a indústria chama de “cookies”. Os cookies são pequenos pacotes de dados que não causam qualquer dano ao seu dispositivo. São armazenados temporariamente durante uma sessão (cookies de sessão) ou arquivados permanentemente no seu dispositivo (cookies permanentes). Os cookies de sessão são automaticamente eliminados assim que a sua visita termina. Os cookies permanentes permanecem armazenados no seu dispositivo até que os elimine ativamente ou até que o seu navegador os elimine automaticamente.",
        parrafo2recording: "Os cookies podem ser emitidos por nós (cookies primários) ou por empresas terceiras (os chamados cookies de terceiros). Os cookies de terceiros permitem a integração de determinados serviços de empresas terceiras em websites (por exemplo, cookies para gerir serviços de pagamento).",
        parrafo3recording: "Os cookies têm funções diferentes. Muitos cookies são tecnicamente essenciais, uma vez que certas funções do website não funcionariam sem eles (por exemplo, a função de carrinho de compras ou a exibição de vídeos). Outros cookies podem ser utilizados para analisar o comportamento do utilizador ou para fins promocionais.",
        parrafo4recording: "Cookies necessários para realizar transações de comunicação eletrónica, para fornecer determinadas funções que deseja utilizar (por exemplo, para a função de carrinho de compras) ou para a otimização (cookies necessários) do site (por exemplo, cookies que fornecem informações mensuráveis ​​​​sobre o audiência web) serão armazenados com base no art. 6º(1)(f) do RGPD, a menos que seja indicada uma base jurídica diferente. O operador do website tem um interesse legítimo no armazenamento de cookies necessários para garantir a prestação tecnicamente isenta de erros e otimizada dos serviços do operador. Se tiver sido solicitado o seu consentimento para o armazenamento de cookies e tecnologias de reconhecimento semelhantes, o tratamento é realizado exclusivamente com base no consentimento obtido (Art. 6(1)(a) RGPD e § 25(1) TTDSG); Este consentimento pode ser revogado a qualquer momento.",
        parrafo5recording: "Tem a opção de configurar o seu navegador para o notificar sempre que os cookies são instalados e permitir a aceitação de cookies apenas em casos específicos. Também pode excluir a aceitação de cookies em determinados casos ou em geral ou ativar a função de exclusão para exclusão automática de cookies ao fechar o navegador. Se desativar os cookies, as funções deste website poderão ser limitadas.",
        parrafo6recording: "Quais os cookies e serviços utilizados neste site podem ser consultados nesta política de privacidade.",
        sub2recording: "Consentimento com Cookiebot",
        parrafo7recording: 'O nosso website utiliza a tecnologia de consentimento Cookiebot para obter o seu consentimento para o armazenamento de determinados cookies no seu dispositivo final ou para a utilização de determinadas tecnologias e para documentar isso de uma forma que cumpra os regulamentos de proteção de dados. O fornecedor desta tecnologia é a Cybot A/S, Havnegade 39, 1058 Copenhaga, Dinamarca (doravante "Cookiebot").',
        parrafo8recording: "Ao aceder ao nosso website, é estabelecida uma ligação aos servidores do Cookiebot para obter o seu consentimento e fornecer-lhe mais explicações sobre a utilização de cookies. O Cookiebot armazenará então um cookie no seu navegador para identificar o consentimento que deu ou a sua revogação. Os dados recolhidos desta forma são armazenados até que nos solicite a sua eliminação, a eliminação do próprio cookie Cookiebot ou a finalidade para a qual os dados são armazenados deixe de se aplicar. As obrigações legais obrigatórias de armazenamento mantêm-se inalteradas.",
        parrafo9recording: "O Cookiebot é utilizado para obter o consentimento legalmente exigido para a utilização de cookies. A base legal para tal é o art. 6º(1)(c) do RGPD.",
        sub3recording: "Processamento de dados",
        parrafo10recording: "Celebrámos um acordo de tratamento de dados (DPA) para a utilização do serviço acima referido. Este é um contrato exigido pelas leis de privacidade de dados que garante que processamos os dados pessoais dos visitantes do nosso website apenas de acordo com as nossas instruções e de acordo com o RGPD.",
        sub4recording: "Formulário de contacto",
        parrafo11recording: "Se nos enviar perguntas através do nosso formulário de contacto, armazenaremos as informações fornecidas no formulário de contacto, bem como quaisquer informações de contacto nele fornecidas, para gerir a sua consulta e caso tenhamos mais perguntas. Não partilharemos estas informações sem o seu consentimento.",
        parrafo12recording: "O tratamento destes dados baseia-se no art. 6º(1)(b) do RGPD, se o seu pedido estiver relacionado com a execução de um contrato ou for necessário para a execução de medidas pré-contratuais. Em todos os outros casos, o tratamento baseia-se no nosso interesse legítimo no tratamento eficaz dos pedidos que nos são dirigidos (art. 6º(1)(f) do RGPD) ou no seu consentimento (art. 6º(1)(a ) do RGPD) caso tal tenha sido solicitado; O consentimento pode ser revogado a qualquer momento.",
        parrafo13recording: "Os dados que introduziu no formulário de contacto permanecerão na nossa posse até que nos solicite a sua eliminação, revogue o seu consentimento para o armazenamento dos dados ou se a finalidade para a qual os dados são armazenados já não existir (por exemplo, depois disso tivermos terminou a resposta à sua consulta). Isto sem prejuízo das disposições legais obrigatórias, nomeadamente dos períodos de conservação.",
        sub5recording: "Pedido por e-mail, telefone ou fax",
        parrafo14recording: "Se nos contactar por email, telefone ou fax, o seu pedido, incluindo todos os dados pessoais dele decorrentes (nome, pedido), serão armazenados e processados ​​​​para efeitos de processamento do seu pedido. Não transmitiremos estes dados sem o seu consentimento.",
        parrafo15recording: "O tratamento destes dados baseia-se no art. 6º(1)(b) do RGPD, se a sua consulta estiver relacionada com a execução de um contrato ou for necessária para a execução de medidas pré-contratuais. Em todos os outros casos, o tratamento de dados baseia-se no nosso interesse legítimo em processar eficazmente as consultas que nos enviou (art. 6º(1)(f) do RGPD) ou no seu consentimento (art. 6º(1)( f) do o RGPD). 1)(a) do RGPD), se o tiver obtido; O consentimento pode ser revogado a qualquer momento.",
        parrafo16recording: "Os dados que nos envia através de pedidos de contacto permanecerão na nossa posse até que nos solicite a sua eliminação, revogue o seu consentimento para armazenamento ou a finalidade do armazenamento dos dados deixe de existir (por exemplo, após o seu pedido ter sido concluído) . As disposições legais obrigatórias, em particular os períodos de conservação legais, mantêm-se inalteradas.",
        analisys: "5. Ferramentas analíticas e de publicidade",
        sub1analisys: "Gestor de tags do Google",
        parrafo1analisys: "Usamos o Google Tag Manager. O fornecedor é a Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlanda.",
        parrafo2analisys: "O Google Tag Manager é uma ferramenta que nos permite integrar ferramentas de rastreio ou estatísticas e outras tecnologias no nosso website. O próprio Google Tag Manager não cria perfis de utilizador, armazena cookies ou realiza análises independentes, mas apenas gere e executa as ferramentas integradas através do mesmo. No entanto, o Gestor de etiquetas do Google recolhe o seu endereço IP, que também pode ser transferido para a empresa-mãe do Google nos Estados Unidos.",
        parrafo3analisys: "A utilização do Google Tag Manager tem por base o art. 6º(1)(f) do RGPD. O operador do website tem um interesse legítimo na integração e administração rápida e fácil de diversas ferramentas no seu website. Se tiver sido obtido o consentimento correspondente, o tratamento é realizado exclusivamente com base no art. 6(1)(a) RGPD e § 25(1) TTDSG, desde que o consentimento inclua o armazenamento de cookies ou o acesso a informações no dispositivo terminal do utilizador (por exemplo, impressão digital do dispositivo) na aceção do TTDSG. Este consentimento pode ser revogado a qualquer momento.",
        sub2analisys: "Análise Google",
        parrafo4analisys: "Este site utiliza funções do serviço de análise web Google Analytics. O fornecedor deste serviço é a Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Irlanda.",
        parrafo5analisys: "O Google Analytics permite ao operador do website analisar os padrões comportamentais dos visitantes do website. Para tal, o operador do website recebe diversos dados dos utilizadores, como as páginas visitadas, o tempo de permanência na página, o sistema operativo utilizado e a origem do utilizador. Estes dados são mapeados para o dispositivo final do utilizador. Nenhuma atribuição é feita a um ID de utilizador.",
        parrafo6analisys: "O Google Analytics utiliza tecnologias que permitem o reconhecimento do utilizador para analisar os seus padrões de comportamento (por exemplo, cookies ou impressões digitais do dispositivo). As informações sobre a utilização do site recolhidas pelo Google são geralmente transmitidas para um servidor do Google nos Estados Unidos e aí armazenadas.",
        parrafo7analisys: "A utilização destes serviços baseia-se no seu consentimento de acordo com o art. 6º(1)(a) RGPD e § 25º(1) TTDSG. Pode revogar o seu consentimento a qualquer momento.",
        parrafo8analisys: "A transmissão de dados para os EUA baseia-se nas cláusulas contratuais-tipo (CEC) da Comissão Europeia. Mais informações podem ser encontradas aqui: https://privacy.google.com/businesses/controllerterms/mccs/.",
        sub3analisys: "Anonimização de IP",
        parrafo9analisys: "Neste site ativamos a função de anonimato de IP. Desta forma, o seu endereço IP será encurtado pela Google nos estados membros da União Europeia ou noutros estados que ratificaram a Convenção sobre o Espaço Económico Europeu antes da sua transmissão aos Estados Unidos. Só em casos excepcionais o endereço IP completo será transmitido para um servidor da Google nos Estados Unidos e aí encurtado. Em nome do operador deste site, o Google utilizará estas informações para avaliar a sua utilização deste site, para gerar relatórios sobre as atividades do site e para fornecer outros serviços ao operador deste site relacionados com a utilização do site e da Internet. O endereço IP transmitido pelo seu navegador em ligação com o Google Analytics não será combinado com outros dados detidos pela Google.",
        sub4analisys: "Plug-in do navegador",
        parrafo10analisys: "Pode impedir o registo e o processamento dos seus dados pelo Google, descarregando e instalando o plug-in do browser disponível no seguinte link: https://tools.google.com/dlpage/gaoptout?hl=en.",
        parrafo11analisys: "Para mais informações sobre o processamento de dados de utilizadores pelo Google Analytics, consulte a declaração de privacidade da Google em: https://support.google.com/analytics/answer/6004245?hl=en.",
        sub5analisys: "Parâmetros demográficos fornecidos pelo Google Analytics",
        parrafo12analisys: "Este site utiliza a função “demográfica” do Google Analytics para poder mostrar aos visitantes do site anúncios publicitários compatíveis com a rede de publicidade da Google. Isto permite criar relatórios contendo informações sobre a idade, sexo e interesses dos visitantes do site. As fontes destas informações são publicidade baseada em interesses do Google, bem como dados de visitantes obtidos de fornecedores terceiros. Estes dados não podem ser atribuídos a uma pessoa específica. Tem a opção de desativar esta função a qualquer momento, fazendo as alterações relevantes nas definições de publicidade na sua conta Google ou pode geralmente proibir o registo dos seus dados pelo Google Analytics, conforme explicado na secção “Oposição ao registo de dados”. .",
        sub6analisys: "Processamento de dados contratuais",
        parrafo13analisys: "Concluímos um contrato de tratamento de dados com a Google e implementámos integralmente as disposições rigorosas das autoridades alemãs de proteção de dados ao utilizar o Google Analytics.",
        newsletter: "6. Boletim informativo",
        sub1newsletter: "Detalhes do boletim informativo",
        parrafo1newsletter: "Se desejar receber a newsletter oferecida no site, necessitamos que nos forneça um endereço de e-mail e dados que nos permitam verificar se é o proprietário do endereço de e-mail fornecido e que aceita receber a newsletter informativa. Nenhum outro dado é recolhido ou é recolhido apenas voluntariamente. Para gerir a newsletter, recorremos a prestadores de serviços de newsletter, descritos abaixo.",
        sub2newsletter: "Rapidmail",
        parrafo2newsletter: "Este site utiliza o Rapidmail para enviar newsletters. O fornecedor é rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg im Breisgau, Alemanha.",
        parrafo3newsletter: "Os serviços Rapidmail podem ser utilizados, entre outras coisas, para organizar e analisar o envio de newsletters. Os dados que introduzir para subscrever a newsletter serão armazenados nos servidores Rapidmail na Alemanha.",
        sub3newsletter: "Análise de dados por Rapidmail",
        parrafo4newsletter: 'Para fins analíticos, os e-mails enviados através do Rapidmail são marcados com um chamado "pixel de rastreio", que se liga aos servidores do Rapidmail assim que o e-mail é aberto. Isto permite determinar se um e-mail de newsletter foi realmente aberto.',
        parrafo5newsletter: "Com a ajuda do Rapidmail podemos também determinar se o destinatário clicou nos links da mensagem da newsletter e em que links. Todos os links incorporados no e-mail são chamados de links de rastreio que nos permitem contar os seus cliques. Caso não pretenda permitir a análise por Rapidmail deverá cancelar a subscrição da newsletter. Para tal, disponibilizamos um link em cada mensagem da newsletter. Além disso, também pode cancelar a subscrição da newsletter diretamente no site.",
        parrafo6newsletter: "Para mais detalhes sobre as funcionalidades analíticas do Rapidmail, siga este link: https://de.rapidmail.wiki/kategorien/statistiken/.",
        sub4newsletter: "Base jurídica",
        parrafo7newsletter: "O tratamento dos dados é realizado com base no seu consentimento (artigo 6.º, n.º 1, alínea a), do RGPD). Pode revogar o consentimento que deu a qualquer momento, cancelando a subscrição da newsletter. Isto sem prejuízo da legalidade das operações de tratamento de dados realizadas antes da sua revogação.",
        sub5newsletter: "Período de armazenamento",
        parrafo8newsletter: "Os dados que nos fornece para efeitos de subscrição da newsletter serão armazenados na nossa base de dados até cancelar a subscrição da newsletter ou do fornecedor de serviços de newsletter e serão removidos da lista de distribuição da newsletter uma vez para cancelar a subscrição da newsletter. Os dados que armazenamos para outros fins não serão afetados.",
        parrafo9newsletter: "Se cancelar a subscrição da lista de distribuição da newsletter, o seu endereço de e-mail poderá ser armazenado por nós ou pelo fornecedor de serviços de newsletter numa lista negra, se tal ação for necessária para evitar futuras correspondências. Os dados da lista negra são utilizados exclusivamente para este fim e não são combinados com outros dados. Isto serve tanto o seu interesse como o nosso interesse em cumprir os requisitos legais ao enviar newsletters (interesse legítimo na acepção do Art. 6(1)(f) do RGPD). O armazenamento na lista negra é indefinido. Pode opor-se ao armazenamento se os seus interesses prevalecerem sobre os nossos interesses legítimos.",
        parrafo10newsletter: "Para mais detalhes, consulte a Política de Proteção de Dados do Rapidmail em: https://www.rapidmail.de/datensicherheit.",
        tools: "7. Plug-ins e ferramentas",
        sub1tools: "Vimeo",
        parrafo1tools: "Este site utiliza plug-ins do portal de vídeos Vimeo. O fornecedor é a Vimeo Inc., 555 West 18th Street, New York, New York 10011, EUA.",
        parrafo2tools: "Se visitar uma das páginas do nosso website na qual foi incorporado um vídeo do Vimeo, será estabelecida uma ligação aos servidores do Vimeo. Como resultado, o servidor Vimeo receberá informações sobre quais das nossas páginas visitou. Além disso, o Vimeo receberá o seu endereço IP. Isto também ocorrerá se não estiver ligado ao Vimeo ou não tiver uma conta no Vimeo. As informações registadas pelo Vimeo serão transmitidas para o servidor do Vimeo nos Estados Unidos.",
        parrafo3tools: "Se estiver ligado à sua conta Vimeo, permite que o Vimeo mapeie diretamente os seus padrões de navegação para o seu perfil pessoal. Pode evitar isso saindo da sua conta do Vimeo.",
        parrafo4tools: "O Vimeo utiliza cookies ou tecnologias de reconhecimento comparáveis ​​(por exemplo, impressão digital do dispositivo) para reconhecer os visitantes do website.",
        parrafo5tools: "A utilização do Vimeo baseia-se no nosso interesse em apresentar o nosso conteúdo online de forma atrativa. De acordo com o art. 6º(1)(f) RGPD, este é um interesse legítimo. Se tiver sido obtido o consentimento correspondente, o tratamento é realizado exclusivamente com base no art. 6(1)(a) RGPD e § 25(1) TTDSG, desde que o consentimento inclua o armazenamento de cookies ou o acesso a informações no dispositivo do utilizador (por exemplo, impressão digital do dispositivo) na aceção do TTDSG. Este consentimento pode ser revogado a qualquer momento.",
        parrafo6tools: "A transmissão de dados para os EUA baseia-se nas cláusulas contratuais-tipo (SCC) da Comissão Europeia e, segundo o Vimeo, em “interesses comerciais legítimos”. Pode encontrar mais informações aqui: https://vimeo.com/privacy.",
        parrafo7tools: "Para mais informações sobre a forma como o Vimeo lida com os dados dos utilizadores, consulte a Política de Privacidade de Dados do Vimeo em: https://vimeo.com/privacy.",
        sub2tools: "Google Maps",
        parrafo8tools: "Este site utiliza o serviço de mapeamento Google Maps. O fornecedor é a Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Irlanda.",
        parrafo9tools: "Para utilizar as funções do Google Maps, é necessário armazenar o seu endereço IP. Por norma, estas informações são transmitidas para um servidor da Google nos Estados Unidos, onde são armazenadas. O operador deste site não tem qualquer controlo sobre a transmissão de dados. Caso o Google Maps esteja ativado, a Google tem a opção de utilizar o Google Fonts para uma representação uniforme das fontes. Quando acede ao Google Maps, o seu navegador carrega as fontes web necessárias para a cache do navegador para exibir texto e fontes corretamente.",
        parrafo10tools: "Utilizamos o Google Maps para apresentar o nosso conteúdo online de uma forma atrativa e para facilitar a localização dos locais apresentados no nosso site. Isto constitui um interesse legítimo na acepção do art. 6º(1)(f) do RGPD. Se tiver sido obtido o consentimento correspondente, o tratamento é realizado exclusivamente com base no art. 6(1)(a) RGPD e § 25(1) TTDSG, desde que o consentimento inclua o armazenamento de cookies ou o acesso a informações no dispositivo do utilizador (por exemplo, impressão digital do dispositivo) na aceção do TTDSG. Este consentimento pode ser revogado a qualquer momento.",
        parrafo11tools: "A transmissão de dados para os EUA baseia-se nas cláusulas contratuais-tipo (CEC) da Comissão Europeia. Mais informações podem ser encontradas aqui: https://privacy.google.com/businesses/gdprcontrollerterms/ e https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.",
        parrafo12tools: "Para mais informações sobre o tratamento de dados do utilizador, consulte a Declaração de Privacidade de Dados da Google em: https://policies.google.com/privacy?hl=en.",
        custom: "8. Serviços personalizados",
        sub1custom: "Gestão de dados de candidatos",
        parrafo1custom: "Oferecemos aos visitantes do nosso website a oportunidade de nos enviarem candidaturas de emprego (por exemplo, por e-mail, por correio ou submetendo o formulário de candidatura de emprego online). Iremos então informá-lo sobre o âmbito, a finalidade e a utilização dos dados pessoais que recolhemos junto de si no âmbito do processo de candidatura. Garantimos-lhe que a recolha, tratamento e utilização dos seus dados será realizada de acordo com os direitos de privacidade de dados aplicáveis ​​​​e todas as demais disposições legais e que os seus dados serão sempre tratados de forma estritamente confidencial.",
        sub2custom: "Âmbito e finalidade da recolha de dados",
        parrafo2custom: "Se nos enviar uma candidatura de emprego, trataremos os dados pessoais relacionados (por exemplo, dados de contacto e comunicação, documentos de candidatura, notas tomadas durante entrevistas de emprego, etc.) se forem necessários para tomar uma decisão sobre o estabelecimento de uma relação de trabalho. As bases jurídicas para o acima exposto são o § 26 BDSG (negociação de uma relação de trabalho) de acordo com a lei alemã, art. 6º(1)(b) RGPD (negociação contratual geral) e, desde que nos dê o seu consentimento, o Art. 6º(1)(a) do RGPD. Pode revogar qualquer consentimento dado a qualquer momento. Dentro da nossa empresa, os seus dados pessoais serão partilhados apenas com pessoas que estejam envolvidas no processamento da sua candidatura de emprego.",
        parrafo3custom: "Se a sua candidatura de emprego resultar no seu emprego, os dados que forneceu serão apresentados de acordo com o § 26 BDSG e o Art. 6º(1)(b) do RGPD para implementar a relação de trabalho no nosso sistema de tratamento de dados.",
        sub3custom: "Período de arquivo de dados",
        parrafo4custom: "Se não lhe conseguirmos oferecer um emprego ou se rejeitar uma oferta de emprego ou retirar a sua candidatura, reservamo-nos o direito de conservar os dados que nos forneceu com base nos nossos interesses legítimos (art. 6º(1)(f) . ) RGPD ) por um período máximo de 6 meses a contar da conclusão do processo de candidatura (rejeição ou desistência da candidatura). Os dados serão depois eliminados e os documentos físicos da candidatura destruídos. O armazenamento serve, nomeadamente, de prova em caso de litígio. Se se tornar evidente que os dados serão necessários após o período de 6 meses (por exemplo, devido a um litígio iminente ou pendente), a eliminação só ocorrerá quando a finalidade de armazenamento adicional já não for aplicável.",
        parrafo5custom: "Um armazenamento mais prolongado também pode ocorrer se tiver dado o seu consentimento (artigo 6.º, n.º 1, alínea a), do RGPD) ou se os requisitos legais de conservação de dados impedirem a eliminação."
      }
    }
};

function Footer() {
    const { language } = useContext(LanguageContext);
    const { footer } = translations[language];
    const [modalCondiciones, setModalCondiciones] = useState(false);
    const [modalAviso, setModalAviso] = useState(false);
    const [modalPolitica, setModalPolitica] = useState(false)

    const handleModalOpen = () => {
      setModalCondiciones(true);
    };

    const handleModalClose = () => {
      setModalCondiciones(false);
    };

    const handleModalOpenAviso = () => {
      setModalAviso(true);
    };

    const handleModalCloseAviso = () => {
      setModalAviso(false);
    };

    const handleModalOpenPolitica = () => {
      setModalPolitica(true);
      console.log(modalPolitica); 
    };

    const handleModalClosePolitica = () => {
      setModalPolitica(false);
    };

    return (
      <div>
        <footer className='footeraboutus'>
          <div className='divglobalfooter'>
            <div className='divfooter'>
              <p className='bolderfooter'>{footer.company}</p>
              <p>{footer.address}</p>
              <p>{footer.city}</p>
              <p>{footer.country}</p>
            </div>
            <div className='divfooter'>
              <div className='divtelfooter'>
                <p>Tel: </p>
                <p className='colorletterfooter'>{footer.Telefono1}</p>
              </div>
              <div className='divtelfooter'>
                <p>Fax: </p>
                <p className='colorletterfooter'>{footer.Fax}</p>
              </div>
              <div className='divtelfooter'>
                <p>Email: </p>
                <p className='colorletterfooter'>{footer.mail}</p>
              </div>
            </div>
            <div className='divfooter'>
              <a className='bolderfooter' onClick={handleModalOpen}>{footer.condiciones}</a>
              <p className='bolderfooter' onClick={handleModalOpenAviso}>{footer.aviso}</p>
              <p className='bolderfooter' onClick={handleModalOpenPolitica}>{footer.proteccion}</p>
            </div>
          </div>
        </footer>
        
        {/* Modal */}
        <Modal show={modalCondiciones} onHide={handleModalClose}>
          <Modal.Header>
            <Modal.Title className='headerfooter'>{footer.titulocondiciones}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br/>
            <p className='bolderfooterModal'>{footer.punto1}</p>
            <p className='fuentefooter'>{footer.parrafo1punto1}</p>
            <p className='fuentefooter'>{footer.parrafo2punto1}</p>
            <p className='fuentefooter'>{footer.parrafo3punto1}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto2}</p>
            <p className='fuentefooter'>{footer.parrafo1punto2}</p>
            <p className='fuentefooter'>{footer.parrafo2punto2}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto3}</p>
            <p className='fuentefooter'>{footer.parrafo1punto3}</p>
            <p className='fuentefooter'>{footer.parrafo2punto3}</p>
            <p className='fuentefooter'>{footer.parrafo3punto3}</p>
            <p className='fuentefooter'>{footer.parrafo4punto3}</p>
            <p className='fuentefooter'>{footer.parrafo5punto3}</p>
            <p className='fuentefooter'>{footer.parrafo6punto3}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto4}</p>
            <p className='fuentefooter'>{footer.parrafo1punto4}</p>
            <p className='fuentefooter'>{footer.parrafo2punto4}</p>
            <p className='fuentefooter'>{footer.parrafo3punto4}</p>
            <p className='fuentefooter'>{footer.parrafo4punto4}</p>
            <p className='fuentefooter'>{footer.parrafo5punto4}</p>
            <p className='fuentefooter'>{footer.parrafo6punto4}</p>
            <p className='fuentefooter'>{footer.parrafo7punto4}</p>
            <p className='fuentefooter'>{footer.parrafo8punto4}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto5}</p>
            <p className='fuentefooter'>{footer.parrafo1punto5}</p>
            <p className='fuentefooter'>{footer.parrafo2punto5}</p>
            <p className='fuentefooter'>{footer.parrafo3punto5}</p>
            <p className='fuentefooter'>{footer.parrafo4punto5}</p>
            <p className='fuentefooter'>{footer.parrafo5punto5}</p>
            <p className='fuentefooter'>{footer.parrafo6punto5}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto6}</p>
            <p className='fuentefooter'>{footer.parrafo1punto6}</p>
            <p className='fuentefooter'>{footer.parrafo1punto6a}</p>
            <p className='fuentefooter'>{footer.parrafo1punto6b}</p>
            <p className='fuentefooter'>{footer.parrafo2punto6}</p>
            <p className='fuentefooter'>{footer.parrafo3punto6}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto7}</p>
            <p className='fuentefooter'>{footer.previopunto7}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7a}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7b}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7c}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7d}</p>
            <p className='fuentefooter'>{footer.parrafo1punto7e}</p>
            <p className='fuentefooter'>{footer.parrafo2punto7}</p>
            <p className='fuentefooter'>{footer.parrafo3punto7}</p>
            <p className='fuentefooter'>{footer.parrafo4punto7}</p>
            <p className='fuentefooter'>{footer.parrafo5punto7}</p>
            <p className='fuentefooter'>{footer.parrafo6punto7}</p>
            <p className='fuentefooter'>{footer.parrafo7punto7}</p>
            <p className='fuentefooter'>{footer.parrafo8punto7}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto8}</p>
            <p className='fuentefooter'>{footer.parrafo1punto8}</p>
            <p className='fuentefooter'>{footer.parrafo2punto8}</p>
            <p className='fuentefooter'>{footer.parrafo3punto8}</p>
            <p className='fuentefooter'>{footer.parrafo4punto8}</p>
            <p className='fuentefooter'>{footer.parrafo5punto8}</p>
            <p className='fuentefooter'>{footer.parrafo6punto8}</p>
            <p className='fuentefooter'>{footer.parrafo7punto8}</p>
            <p className='fuentefooter'>{footer.parrafo8punto8}</p>
            <p className='fuentefooter'>{footer.parrafo9punto8}</p>
            <p className='fuentefooter'>{footer.parrafo10punto8}</p>
            <p className='fuentefooter'>{footer.parrafo11punto8}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto9}</p>
            <p className='fuentefooter'>{footer.parrafo1punto9}</p>
            <p className='fuentefooter'>{footer.parrafo1punto9a}</p>
            <p className='fuentefooter'>{footer.parrafo1punto9b}</p>
            <p className='fuentefooter'>{footer.parrafo1punto9c}</p>
            <p className='fuentefooter'>{footer.parrafo2punto9}</p>
            <p className='fuentefooter'>{footer.parrafo3punto9}</p>
            <p className='fuentefooter'>{footer.parrafo4punto9}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto10}</p>
            <p className='fuentefooter'>{footer.parrafo1punto10}</p>
            <p className='fuentefooter'>{footer.parrafo2punto10}</p>
            <p className='fuentefooter'>{footer.parrafo3punto10}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto11}</p>
            <p className='fuentefooter'>{footer.parrafo1punto11}</p>
            <p className='fuentefooter'>{footer.parrafo2punto11}</p>
            <p className='fuentefooter'>{footer.parrafo3punto11}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto12}</p>
            <p className='fuentefooter'>{footer.parrafo1punto12}</p>
            <p className='fuentefooter'>{footer.parrafo2punto12}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto13}</p>
            <p className='fuentefooter'>{footer.parrafo1punto13}</p>
            <p className='fuentefooter'>{footer.parrafo2punto13}</p>
            <p className='fuentefooter'>{footer.parrafo3punto13}</p>
            <p className='fuentefooter'>{footer.parrafo4punto13}</p>
            <p className='fuentefooter'>{footer.parrafo5punto13}</p>
            <p className='fuentefooter'>{footer.parrafo6punto13}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto14}</p>
            <p className='fuentefooter'>{footer.parrafo1punto14}</p>
            <p className='fuentefooter'>{footer.parrafo2punto14}</p>
            <br/>

            <p className='bolderfooterModal'>{footer.punto15}</p>
            <p className='fuentefooter'>{footer.parrafo1punto15}</p>
            <br/>
            

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="botoncerrarfooter" onClick={handleModalClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalAviso} onHide={handleModalCloseAviso}>
          <Modal.Header>
            <Modal.Title className='headerfooter'>{footer.legal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br/>
          <p className='bolderaviso'> {footer.information} </p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1information}</p>
          <p className='fuentefooter'>{footer.parrafo2information}</p>
          <p className='fuentefooter'>{footer.parrafo3information}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo3information}</p>
          <p className='fuentefooter'>{footer.parrafo4information}</p>
          <br/>

          <p className='bolderfooterModal'>{footer.parrafo5information}</p>
          <p className='fuentefooter'>{footer.parrafo6information}</p>
          <p className='fuentefooter'>{footer.parrafo7information}</p>
          <br/>

          <p className='bolderaviso'>{footer.contact}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1contact}</p>
          <p className='fuentefooter'>{footer.parrafo2contact}</p>
          <p className='fuentefooter'>{footer.parrafo3contact}</p>
          <br/>

          <p className='bolderaviso'>{footer.vat}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1vat}</p>
          <p className='fuentefooter'>{footer.parrafo2vat}</p>
          <br/>

          <p className='bolderaviso'>{footer.dispute}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1dispute}</p>
          <p className='fuentefooter'>{footer.parrafo2dispute}</p>
          <br/>

          <p className='bolderaviso'>{footer.dispute2}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1dispute2}</p>
          <br/>

          <p className='bolderaviso'>{footer.images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo1images}</p>
          <p className='fuentefooter'>{footer.parrafo2images}</p>
          <p className='fuentefooter'>{footer.parrafo3images}</p>
          <p className='fuentefooter'>{footer.parrafo4images}</p>
          <p className='fuentefooter'>{footer.parrafo5images}</p>
          <p className='fuentefooter'>{footer.parrafo6images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo7images}</p>
          <p className='fuentefooter'>{footer.parrafo8images}</p>
          <p className='fuentefooter'>{footer.parrafo9images}</p>
          <p className='fuentefooter'>{footer.parrafo10images}</p>
          <p className='fuentefooter'>{footer.parrafo11images}</p>
          <p className='fuentefooter'>{footer.parrafo12images}</p>
          <p className='fuentefooter'>{footer.parrafo13images}</p>
          <p className='fuentefooter'>{footer.parrafo14images}</p>
          <p className='fuentefooter'>{footer.parrafo15images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo16images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo17images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo18images}</p>
          <br/>

          <p className='fuentefooter'>{footer.parrafo19images}</p>
          <p className='fuentefooter'>{footer.parrafo20images}</p>
          <p className='fuentefooter'>{footer.parrafo21images}</p>
          <p className='fuentefooter'>{footer.parrafo22images}</p>
          <p className='fuentefooter'>{footer.parrafo23images}</p>
          <p className='fuentefooter'>{footer.parrafo24images}</p>
          <p className='fuentefooter'>{footer.parrafo25images}</p>      
          <br/>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="botoncerrarfooter" onClick={handleModalCloseAviso}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalPolitica} onHide={handleModalClosePolitica}>
          <Modal.Header>
            <Modal.Title className='headerfooter'>{footer.privacy}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <br/>
            <p className='tituloprivacy'>{footer.tituloprivacy}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.protecciondatos}</p>
            <br/>

            <p className='subfooter'>{footer.sub1protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1protecciondatos}</p>
            <br/>

            <p className='subfooter'>{footer.sub2protecciondatos}</p>
            <br/>

            <p className='subsubfooter'>{footer.subsub1protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2protecciondatos}</p>
            <br/>

            <p className='subsubfooter'>{footer.subsub2protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4protecciondatos}</p>
            <br/>

            <p className='subsubfooter'>{footer.subsub3protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5protecciondatos}</p>
            <br/>

            <p className='subsubfooter'>{footer.subsub4protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo6protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo7protecciondatos}</p>
            <br/>

            <p className='subfooter'>{footer.sub3protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8protecciondatos}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo9protecciondatos}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.hosting}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1hosting}</p>
            <br/>

            <p className='subfooter'>{footer.sub1hosting}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2hosting}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3hosting}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4hosting}</p>
            <br/>

            <p className='subsubfooter'>{footer.subsub1hosting}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5hosting}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.general}</p>
            <br/>

            <p className='subfooter'>{footer.sub1general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3general}</p>
            <br/>

            <p className='subfooter'>{footer.sub2general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5general}</p>
            <p className='fuentefooterprivacy'>{footer.parrafo6general}</p>
            <p className='fuentefooterprivacy'>{footer.parrafo7general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8general}</p>
            <p className='fuentefooterprivacy'>{footer.parrafo9general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo10general}</p>
            <br/>

            <p className='subfooter'>{footer.sub3general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo11general}</p>
            <br/>

            <p className='subfooter'>{footer.sub4general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo12general}</p>
            <br/>

            <p className='subfooter'>{footer.sub5general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo13general}</p>
            <br/>

            <p className='subfooter'>{footer.sub6general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo14general}</p>
            <br/>

            <p className='subfooter'>{footer.sub7general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo15general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo16general}</p>
            <br/>

            <p className='subfooter'>{footer.sub8general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo17general}</p>
            <br/>

            <p className='subfooter'>{footer.sub9general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo18general}</p>
            <br/>

            <p className='subfooter'>{footer.sub10general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo11general}</p>
            <br/>

            <p className='subfooter'>{footer.sub11general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo12general}</p>
            <br/>

            <ul>
              <div className='divfooterul'>
              <div className='divbicheckfooter'><BiCheck className='bicheckfooter'/></div>
              <il className='ulfontfooter'>{footer.ul1general}</il>
              </div>
              <hr className='hrfooter'/>

              <div className='divfooterul'>
              <div className='divbicheckfooter'><BiCheck className='bicheckfooter'/></div>
              <il className='ulfontfooter'>{footer.ul2general}</il>
              </div>
              <hr className='hrfooter'/>

              <div className='divfooterul'>
                <div className='divbicheckfooter'><BiCheck className='bicheckfooter'/></div>
              <il className='ulfontfooter'>{footer.ul3general}</il>
              </div>
              <hr className='hrfooter'/>

              <div className='divfooterul'>
                <div className='divbicheckfooter'><BiCheck className='bicheckfooter'/></div>
              <il className='ulfontfooter'>{footer.ul4general}</il>
              </div>
              <hr className='hrfooter'/>

            </ul>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo13general}</p>
            <br/>

            <p className='subfooter'>{footer.sub12general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo13general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo14general}</p>
            <br/>

            <p className='subfooter'>{footer.sub13general}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo15general}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.recording}</p>
            <br/>

            <p className='subfooter'>{footer.sub1recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo6recording}</p>
            <br/>

            <p className='subfooter'>{footer.sub2recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo7recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo9recording}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub3recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo10recording}</p>
            <br/>

            <p className='subfooter'>{footer.sub4recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo11recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo12recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo13recording}</p>
            <br/>

            <p className='subfooter'>{footer.sub5recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo14recording}</p>
            <br/>
 
            <p className='fuentefooterprivacy'>{footer.parrafo15recording}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo16recording}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.analisys}</p>
            <br/>

            <p className='subfooter'>{footer.sub1analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3analisys}</p>
            <br/>

            <p className='subfooter'>{footer.sub2analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo6analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo7analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8analisys}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub3analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo9analisys}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub4analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo10analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo11analisys}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub5analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo12analisys}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub6analisys}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo13analisys}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.newsletter}</p>
            <br/>
            
            <p className='subfooter'>{footer.sub1newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1newsletter}</p>
            <br/>

            <p className='subfooter'>{footer.sub2newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3newsletter}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub3newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo6newsletter}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub4newsletter}</p>
            <br/>
            
            <p className='fuentefooterprivacy'>{footer.parrafo7newsletter}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub5newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo9newsletter}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo10newsletter}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.tools}</p>
            <br/>

            <p className='subfooter'>{footer.sub1tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo6tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo7tools}</p>
            <br/>

            <p className='subfooter'>{footer.sub2tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo8tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo9tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo10tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo11tools}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo12tools}</p>
            <br/>

            <p className='titulo2privacidad'>{footer.custom}</p>
            <br/>

            <p className='subfooter'>{footer.sub1custom}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo1custom}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub2custom}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo2custom}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo3custom}</p>
            <br/>

            <p className='subsubfooter'>{footer.sub3custom}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo4custom}</p>
            <br/>

            <p className='fuentefooterprivacy'>{footer.parrafo5custom}</p>
            <br/>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className='botoncerrarfooter' onClick={handleModalClosePolitica}>Cerrar</Button>
          </Modal.Footer>
          </Modal>
      </div>
    );
}

export default Footer;
