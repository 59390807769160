// Home.js
import React, { useState } from 'react';
import logo from '../assets/LOGODOSALLARG_NOU.png';
import '../styles/Home.css';
import Hamburguer from './Hamburguer';
import NavbarExample from './Navbar';
import Dashboard from './Dashboard';
import Contact from './Contact';
import Footer from './Footer';

function Notificacion() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <NavbarExample toggleMenu={toggleMenu} />
      <div className='googlemaps'>
        <Dashboard/>
      </div>
      <Footer/>
    </div>
  );
}

export default Notificacion;
