import React, {useContext} from 'react';
import NavbarExample from './Navbar';
import '../styles/Contact.css';
import { LanguageContext } from './LanguajeContext.js';
import Footer from './Footer';

function Contact() {
  const { language } = useContext(LanguageContext);

  const texts = {
    en: {
      title: 'Contact us',
      nameLabel: 'Name',
      lastNameLabel: 'Last Name',
      phoneLabel: 'Phone',
      emailLabel: 'Email',
      messageLabel: 'Write your message here...',
      visitWebsite: 'You can visit our website <a href="https://www.example.com">here</a>.',
      callUs: 'Or call us at: <a href="tel:+1234567890">+1234567890</a>',
    },
    es: {
      title: 'Contacta con nosotros',
      nameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      phoneLabel: 'Teléfono',
      emailLabel: 'Mail',
      messageLabel: 'Escribe tu mensaje aquí...',
      visitWebsite: 'Puedes visitar nuestra página web <a href="https://www.ejemplo.com">aquí</a>.',
      callUs: 'O llámanos al: <a href="tel:+1234567890">+1234567890</a>',
    },
    de: {
      title: 'Kontaktiere uns',
      nameLabel: 'Name',
      lastNameLabel: 'Nachname',
      phoneLabel: 'Telefon',
      emailLabel: 'E-Mail',
      messageLabel: 'Schreibe hier deine Nachricht...',
      visitWebsite: 'Du kannst unsere Webseite <a href="https://www.beispiel.de">hier</a> besuchen.',
      callUs: 'Oder rufe uns an unter: <a href="tel:+1234567890">+1234567890</a>',
    },
    pt: {
      title: 'Entre em contato conosco',
      nameLabel: 'Nome',
      lastNameLabel: 'Sobrenome',
      phoneLabel: 'Telefone',
      emailLabel: 'E-mail',
      messageLabel: 'Escreva sua mensagem aqui...',
      visitWebsite: 'Você pode visitar nosso site <a href="https://www.exemplo.com">aqui</a>.',
      callUs: 'Ou nos ligue em: <a href="tel:+1234567890">+1234567890</a>',
    },
  };

  const currentTexts = texts[language] || texts['es']; // Por defecto, español si no se encuentra el idioma

  return (
    <div>
      <NavbarExample />
      <div className='div-formulario'>
        <div className='div-tittleContact'>
          <div className='titleContact'>
            <h2>{currentTexts.title}</h2>
          </div>

          <div className='formulario'>
            <div className='personales'>
              <div className='nombre'>
                <input className='input-name' placeholder={currentTexts.nameLabel} />
              </div>
              <div className='nombre'>
                <input className='input-name' placeholder={currentTexts.lastNameLabel} />
              </div>
            </div>
            <div className='personales'>
              <div className='nombre'>
                <input className='input-name' placeholder={currentTexts.phoneLabel} />
              </div>
              <div className='nombre'>
                <input className='input-name' placeholder={currentTexts.emailLabel} />
              </div>
            </div>
            <div className='mensaje-div'>
              <textarea className='Mensaje' placeholder={currentTexts.messageLabel} />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Contact;
